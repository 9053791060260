import { Injectable, NgZone } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, combineLatest, from, Observable, of, Subject, Subscription } from 'rxjs';
import { Appointment, Feedback, InternshipProposal, Interview } from "./interview";
import { delay, filter, first, map, mergeMap, skip, startWith, switchMap, take, takeUntil, tap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { UserService } from "../user/user.service";
import { CandidateType, User, UserType } from "../user/user";
import { AngularFireDatabase } from "@angular/fire/database";
import { AngularFireAuth } from "@angular/fire/auth";
import { FirebaseService } from "../shared/firebase.service";
import * as fileSaver from "file-saver";
import { HttpHeaders } from "../../../node_modules/@angular/common/http";
import { Platform } from "@ionic/angular";
import { FileOpener } from "@ionic-native/file-opener/ngx";
import { File, IWriteOptions } from "@ionic-native/file/ngx";

export class ChatMessage {
  userId: number;
  isSchool: boolean;
  date: number;
  message: string;
}

export class ChatContext {
  chatId: string;
  lastVisit: number;
  users?: any;
  messages: ChatMessage[];
}

@Injectable()
export class InterviewService {
  private currentInterview$ = new BehaviorSubject<Interview>(null);
  private discussion$ = new BehaviorSubject<ChatMessage[]>(undefined);
  private appointmentUpdated$ = new Subject<string>();
  private chatContexts$ = new BehaviorSubject<ChatContext[]>(null);
  private chatContextsById = {};

  isNative = !!environment.domain;

  uid: string;
  allContextsSub: Subscription;

  constructor(private http: HttpClient,
              private afDatabase: AngularFireDatabase,
              private angularFireLiteAuth: AngularFireAuth,
              private userService: UserService,
              private firebaseService: FirebaseService,
              private ngZone: NgZone,
              private platform: Platform,
              private fileOpener: FileOpener,
              private file: File) { }

  initGlobalChatNotif() {
    this.chatContexts$.subscribe((contexts)=>{
      if(Array.isArray(contexts)){
        contexts.forEach((chatContext)=>{
          this.chatContextsById[chatContext.chatId] = chatContext;
        });
      }
    });

    this.userService
      .getUser$()
      .pipe(
        filter(user => !!user),
        first(),
        /* Si le user connecté est un troisième on s'arrête là, pas de chat */
        switchMap((user: User) => (user && !(user.profile == UserType.CANDIDATE && user.candidate.type == CandidateType.TROISIEME) ? this.angularFireLiteAuth.user : of(null))),
        tap((user: any) => {
          if(user){
            this.uid = user.uid;
            this.updateAllChatContext();
          }
        })
      )
      .subscribe();
  }

  private updateAllChatContext(){
    if(this.allContextsSub){
      this.allContextsSub.unsubscribe();
    }
    this.allContextsSub = this.getAllChatContext$(this.uid).pipe(
      tap((chatContexts: ChatContext[]) => {
        this.chatContexts$.next(chatContexts);
      })
    ).subscribe();
  }

  private getAllChatContext$(uid: string): Observable<ChatContext[]> {
    return this.getInterviews$().pipe(
      map(list => list.filter(inter => !inter.closed && inter.openChat)),
      map(list => list.map(inter => inter.chatId)),
      mergeMap(chatIds => this.getAllChatContextFromChatIds$(chatIds, uid))
    );
  }

  private getAllChatContextFromChatIds$(chatIds: string[], uid: string): Observable<ChatContext[]> {
    const chatsContext$ = chatIds.map(chatId => this.getAllChatContextFromChatId$(chatId, uid));
    return combineLatest(...chatsContext$);
  }

  private getAllChatContextFromChatId$(chatId: string, uid: string): Observable<ChatContext> {
    return combineLatest(this.getChatMessagesFromChatId$(chatId), this.getLastVisitOfUser$(chatId, uid), this.getUsersFromChatId$(chatId)).pipe(
      map(([messages, date, users]) => ({ messages, lastVisit: date, chatId, users }))
    );
  }

  reloadChatContextIfNeeded(chatId: string){
    if(this.uid && !this.chatContextsById[chatId]){
      this.updateAllChatContext();
    }
  }

  private getChatMessagesFromChatId$(chatId: string): Observable<ChatMessage[]> {
    return this.firebaseService.canSubscribeFirebase$.pipe(
      switchMap(canAccess => (canAccess ? this.afDatabase.list<ChatMessage>(`chat/${chatId}/messages`).valueChanges() : of([])))
    );
  }

  getNbUnreadForCurrentInterview$(): Observable<number> {
    const chatId = this.currentInterview$.getValue().chatId;
    return this.getNbUnreadByChatId$(chatId);
  }
  getNbUnreadByChatId$(chatId: string): Observable<number> {
    return this.userService.getUser$().pipe(switchMap(user => this.getNbUnreadByChatIdAndUID$(chatId, user)));
  }
  private getNbUnreadByChatIdAndUID$(chatId: string, user: User): Observable<number> {
    if (!user) {
      return of(0);
    }

    return this.chatContexts$.asObservable().pipe(
      filter(contexts => !!contexts),
      map(chatContexts => {
        const chatContext = chatContexts.find(context => context.chatId === chatId);
        if (!chatContext || !chatContext.messages || !chatContext.messages.length) {
          return 0;
        }

        return chatContext.messages.filter(m => m.userId !== user.id && (chatContext.lastVisit === undefined || m.date > chatContext.lastVisit)).length;
      })
    );
  }

  getNbContextUnread$(): Observable<number> {
    return this.userService.getUser$().pipe(mergeMap(user => this.getNbContextUnreadForUser$(user)));
  }
  private getNbContextUnreadForUser$(user: User): Observable<number> {
    if (!user) {
      return of(0);
    }

    return this.chatContexts$.asObservable().pipe(
      startWith([]),
      filter(contexts => !!contexts),
      map(chatContexts => chatContexts.filter(context => hasUnreadMessages(context, user)).length)
    );
  }

  private getUsersFromChatId$(chatId: string) {
    return this.firebaseService.canSubscribeFirebase$.pipe(switchMap(canAccess => (canAccess ? this.afDatabase.object<number>(`chat/${chatId}/users`).valueChanges() : of(null))));
  }

  private getLastVisitOfUser$(chatId: string, uid: string): Observable<number> {
    return this.firebaseService.canSubscribeFirebase$.pipe(
      switchMap(canAccess => (canAccess ? this.afDatabase.object<number>(`chat/${chatId}/lastVisit/${uid}`).valueChanges() : of(null)))
    );
  }
  updateLastVisit(chatId: string): void {
    this.firebaseService.canSubscribeFirebase$
      .pipe(
        switchMap(canAccess => (canAccess ? this.angularFireLiteAuth.user : of(null))),
        filter(user => !!user),
        take(1),
        tap(user => this.afDatabase.object(`chat/${chatId}/lastVisit/${user.uid}`).set(new Date().getTime()))
      )
      .subscribe();
  }
  updateLastVisitOnCurrentInterview(): void {
    const chatId = this.currentInterview$.getValue().chatId;
    this.updateLastVisit(chatId);
  }

  getInterviews$(candidateTypes?: Array<CandidateType>): Observable<Interview[]> {
    let queryParam = '';
    if (Array.isArray(candidateTypes) && candidateTypes.length) {
      queryParam = '?candidateTypes=';
      queryParam = queryParam + candidateTypes.pop();
      candidateTypes.forEach(t => {
        queryParam = queryParam + ',' + t;
      });
    }
    return this.http.get<Interview[]>(environment.apiEntryPoint + '/interviews' + queryParam);
  }

  get3eInterviews(): Observable<Interview[]> {
    return this.getInterviews$([CandidateType.TROISIEME]);
  }

  getApprentiFormProInterviews(): Observable<Interview[]> {
    return this.getInterviews$([CandidateType.APPRENTI, CandidateType.FORM_PRO]);
  }

  getInterview$(recruiterId: number, candidateId: number): Observable<Interview> {
    this.currentInterview$.next(null);
    return this.http.get<Interview>(`${environment.apiEntryPoint}/interviews/${recruiterId}/${candidateId}`).pipe(
      tap(i => this.currentInterview$.next(i)),
      tap(i => this.initLocalFirebaseConnection(i))
    );
  }

  private initLocalFirebaseConnection(interview: Interview): void {
    this.discussion$.next([]);

    this.firebaseService.canSubscribeFirebase$.subscribe(canAccess => {
      if (canAccess) {
        // ICI PUTAIN, rentre une seule fois mais ne subscribe pas
        this.angularFireLiteAuth.user
          .pipe(
            // first(),
            mergeMap(() => {
              return this.afDatabase.list(`chat/${interview.chatId}/messages`).valueChanges();
            }),
            takeUntil(this.currentInterview$.pipe(skip(1))),
            tap((messages: ChatMessage[]) => this.discussion$.next(messages))
          )
          .subscribe(); // why he do not subscribe there ? because of chat/interview.chatId/messages ?

        this.angularFireLiteAuth.user
          .pipe(
            switchMap(() =>
              this.afDatabase
                .object<string>(`chat/${interview.chatId}/appointmentsUpdated`)
                .valueChanges()
                .pipe(
                  skip(1), // the previous value should not reload appointments, only new values reload appointments
                  takeUntil(
                    this.currentInterview$.pipe(
                      skip(1),
                      filter(i => !i)
                    )
                  ) // once the current is loaded and until its unload
                )
            ),
            tap(date => this.appointmentUpdated$.next(date))
          )
          .subscribe();
      }
    });
  }

  sendOnChat$(interview: Interview, userId: number, messageStr: string): Observable<any> {
    const { candidateId, recruiterId } = this.currentInterview$.getValue();
    const message = {
      userId: userId,
      date: new Date().getTime(),
      message: messageStr
    };

    /* Mise à jour des users autorisés, si pas encore fait */
    let chat = this.chatContextsById[interview.chatId];
    if (chat && !chat.users) {
      let users = {};
      users['R' + interview.recruiterId] = true;
      if (interview.candidate.type == CandidateType.TROISIEME) {
        users['S' + interview.candidate.schoolId] = true;
      } else {
        users['C' + interview.candidateId] = true;
      }
      return this.createUsersRights(interview.chatId, users).pipe(switchMap(() => this.sendMessage(interview, message, recruiterId, candidateId)));
    }

    return this.sendMessage(interview, message, recruiterId, candidateId);
  }

  private createUsersRights(chatId: string, users: any): Observable<void> {
    return from(this.afDatabase.object('chat/' + chatId + '/users').set(users));
  }

  private sendMessage(interview: Interview, message: any, recruiterId: number, candidateId: number): Observable<any> {
    // On rentre ici dès le premier envoi
    return from(this.afDatabase.list(`chat/${interview.chatId}/messages`).push(message)).pipe(
      delay(1000),
      mergeMap(() => this.http.get(`${environment.apiEntryPoint}/interviews/${recruiterId}/${candidateId}/newMessage`))
    );
  }

  refreshAppointments$(): Observable<Appointment[]> {
    const { recruiterId, candidateId } = this.currentInterview$.getValue();
    return this.http.get<Appointment[]>(`${environment.apiEntryPoint}/interviews/${recruiterId}/${candidateId}/appointments`).pipe(
      tap(appointments => {
        this.currentInterview$.next({
          ...this.currentInterview$.getValue(),
          appointments: appointments ? appointments : []
        });
      })
    );
  }

  addAppointment$(appointment: Appointment): Observable<Appointment> {
    const { recruiterId, candidateId } = this.currentInterview$.getValue();
    const url = `${environment.apiEntryPoint}/interviews/${recruiterId}/${candidateId}/appointments`;
    return this.http.post<Appointment>(url, appointment).pipe(
      tap(newAppointment => {
        const interview = Object.assign({}, this.currentInterview$.getValue());
        interview.appointments = interview.appointments ? [...interview.appointments, newAppointment] : [newAppointment];
        this.currentInterview$.next(interview);
        this.rtAppointmentsUpdated(interview.chatId);
      })
    );
  }

  updateAppointment$(appointment: Appointment): Observable<Appointment> {
    const { recruiterId, candidateId } = this.currentInterview$.getValue();
    const url = `${environment.apiEntryPoint}/interviews/${recruiterId}/${candidateId}/appointments/${appointment.id}`;
    return this.http.put<Appointment>(url, appointment).pipe(
      tap(updatedAppointment => {
        const interview = Object.assign({}, this.currentInterview$.getValue());
        interview.appointments = interview.appointments.map(app => (app.id === updatedAppointment.id ? updatedAppointment : app));
        this.currentInterview$.next(interview);
        this.rtAppointmentsUpdated(interview.chatId);
      })
    );
  }

  deleteAppointment$(appointment: Appointment): Observable<Appointment> {
    const { recruiterId, candidateId } = this.currentInterview$.getValue();
    const url = `${environment.apiEntryPoint}/interviews/${recruiterId}/${candidateId}/appointments/${appointment.id}`;
    return this.http.delete<Appointment>(url).pipe(
      tap(() => {
        const interview = Object.assign({}, this.currentInterview$.getValue());
        interview.appointments = interview.appointments.filter(app => app.id !== appointment.id);
        this.currentInterview$.next(interview);
        this.rtAppointmentsUpdated(interview.chatId);
      })
    );
  }

  private rtAppointmentsUpdated(chatId: string): void {
    this.firebaseService.canSubscribeFirebase$.subscribe(canAccess => {
      if (canAccess) {
        this.afDatabase.object(`chat/${chatId}/appointmentsUpdated`).set(new Date().toISOString());
      }
    });
  }

  hire$(recruiterId: number, candidateId: number, hireOption: any): Observable<Interview> {
    return this.http.post<Interview>(`${environment.apiEntryPoint}/interviews/${recruiterId}/${candidateId}/hired`, hireOption).pipe(
      tap(interview => {
        if (interview) {
          this.currentInterview$.next(interview);
        }
      })
    );
  }

  close$(recruiterId: number, candidateId: number, type: UserType): Observable<void> {
    return this.http.get<void>(`${environment.apiEntryPoint}/interviews/${recruiterId}/${candidateId}/${type}/close`);
  }

  resetInterview(): void {
    this.currentInterview$.next(null);
  }

  getCurrentInterview$(): Observable<Interview> {
    return this.currentInterview$.asObservable().pipe(filter(i => i !== null));
  }

  getDiscussion$(): Observable<ChatMessage[]> {
    return this.discussion$.pipe(filter(messages => messages !== undefined));
  }

  getAppointmentUpdated$(): Observable<string> {
    return this.appointmentUpdated$.pipe(filter(messages => !!messages));
  }

  sendFeedback$(recruiterId: number, candidateId: number, feedback: Feedback): Observable<any> {
    return this.http.post(`${environment.apiEntryPoint}/interviews/${recruiterId}/${candidateId}/feedback`, feedback);
  }

  /* Web services dedicated to 3e contacts */

  cancelContact3e(candidateId: number): Observable<Interview> {
    const body = { candidateId: candidateId };
    return this.http.post<Interview>(`${environment.apiEntryPoint}/interviews/cancelContact3e`, body);
  }

  acceptContact3e(candidateId: number): Observable<Interview> {
    const body = { candidateId: candidateId };
    return this.http.post<Interview>(`${environment.apiEntryPoint}/interviews/acceptContact3e`, body);
  }

  refuseContact3e(candidateId: number): Observable<Interview> {
    const body = { candidateId: candidateId };
    return this.http.post<Interview>(`${environment.apiEntryPoint}/interviews/refuseContact3e`, body);
  }

  proposeInternship3e(candidateId: number, internship: InternshipProposal): Observable<Interview> {
    const body = { candidateId: candidateId, internship: internship };
    return this.http.post<Interview>(`${environment.apiEntryPoint}/interviews/proposeInternship3e`, body);
  }

  cancelInternship3e(candidateId: number): Observable<Interview> {
    const body = { candidateId: candidateId };
    return this.http.post<Interview>(`${environment.apiEntryPoint}/interviews/cancelInternship3e`, body);
  }

  uploadFile$(recruiterId: number, candidateId: number, data: any): Observable<Interview> {
    return this.http.post<Interview>(`${environment.apiEntryPoint}/interviews/${recruiterId}/${candidateId}/uploadFile`, data);
  }

  updateFile$(recruiterId: number, candidateId: number, data: any): Observable<Interview> {
    return this.http.post<Interview>(`${environment.apiEntryPoint}/interviews/${recruiterId}/${candidateId}/updateFile`, data);
  }

  removeFile$(recruiterId: number, candidateId: number, token: string): Observable<Interview> {
    return this.http.post<Interview>(`${environment.apiEntryPoint}/interviews/${recruiterId}/${candidateId}/removeFile`, {token: token});
  }

  downloadFile$(recruiterId: number, candidateId: number, token: string): void {
    const headers = new HttpHeaders({ Accept: '*' });
    this.http
      .get(`${environment.apiEntryPoint}/interviews/${recruiterId}/${candidateId}/downloadFile?token=` + token, { observe: 'response', responseType: 'blob', headers })
      .subscribe((res: any) => {
        let contentDisposition;
        if (this.isNative) {
          contentDisposition = res.headers['Content-Disposition'] || res.headers['content-disposition'] || '';
        } else {
          contentDisposition = res.headers.get('Content-Disposition') || '';
        }
        const matches = /filename=([^;]+)/gi.exec(contentDisposition);
        let fileName;
        if (matches) {
          fileName = matches[1].trim();
        } else {
          fileName = 'untitled.pdf';
        }
        fileName = fileName.replace(/\"/gi, '');
        const blob = new Blob([res.body], { type: 'application/octet-stream' });
        if (this.isNative) {
          let reader = this.getFileReader();
          reader.onloadend = readerEvent => {
            if (reader.error) {
              console.error(reader.error);
            } else {
              let base64data: any = readerEvent.target['result'];
              let mimeType = base64data.substring(base64data.indexOf(':') + 1, base64data.indexOf(';'));
              let options: IWriteOptions = { replace: true };
              this.file.writeFile(this.file.dataDirectory, fileName, blob, options).then(res => {
                this.fileOpener.open(this.file.dataDirectory + fileName, mimeType);
              });
            }
          };
          reader.readAsDataURL(res.body);
        } else {
          fileSaver.saveAs(blob, fileName);
        }
      });
  }

  getFileReader(): FileReader {
    const fileReader = new FileReader();
    const zoneOriginalInstance = (fileReader as any)['__zone_symbol__originalInstance'];
    return zoneOriginalInstance || fileReader;
  }
}

function hasUnreadMessages(context: ChatContext, user: User): boolean {
  if (!context.messages || context.messages.length === 0) {
    return false;
  }
  const unreadMessage = context.messages.filter(m => m.userId !== user.id && (context.lastVisit === undefined || m.date > context.lastVisit));
  return unreadMessage.length > 0;
}
