import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { shareReplay } from "rxjs/operators";
import { environment } from "../../../environments/environment";

export class Arml {
  territory: string[];
  address: string[];
  contact: string[];
  nbAntennas: string;
}

@Injectable()
export class ArmlService {

  readonly arml$: Observable<Arml[]>;

  constructor(private http: HttpClient) {
    this.arml$ = http.get<Arml[]>(`${environment.domain}/media/arml.json`).pipe(
      shareReplay()
    );
  }

}
