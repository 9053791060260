import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'anie-politic-confidential',
  templateUrl: './politic-confidential.component.html',
  styleUrls: ['./politic-confidential.component.scss'],
})
export class PoliticConfidentialComponent {

  constructor(private route: ActivatedRoute,
              private router: Router) { }

  goToDetails(){
    this.router.navigate(['../advancedPoliticConfidentiality'], {relativeTo: this.route});
  }
}
