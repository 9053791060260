import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PasswordFormControlComponent } from "./password-form-control.component";
import { ReactiveFormsModule } from "@angular/forms";
import { SharedModule } from "../shared.module";

@NgModule({
  declarations: [
    PasswordFormControlComponent
  ],
  exports: [
    PasswordFormControlComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SharedModule
  ]
})
export class PasswordFormControlModule { }
