import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { filter, map, mergeMap, pairwise, tap } from "rxjs/operators";
import { UserService } from "../user/user.service";
import { BehaviorSubject, merge, of } from "rxjs";
import { AuthService } from "../security/auth.service";
import { User } from "../user/user";
import { environment } from "../../environments/environment";
import { AngularFireAuth } from "@angular/fire/auth";
import { WINDOW_ID } from "./injectorTokens";

enum ConnectionState {
  SAME_AS_PREVIOUS = 'SAME_AS_PREVIOUS',
  JUST_LOGGED_IN = 'JUST_LOGGED_IN',
  JUST_LOGGED_OUT = 'JUST_LOGGED_OUT'
}
@Injectable()
export class FirebaseService {
  constructor(
    private http: HttpClient,
    private userService: UserService,
    private authService: AuthService,
    private angularFireAuth: AngularFireAuth,
    @Inject(WINDOW_ID) private window: Window
  ) {}

  public canSubscribeFirebase$ = new BehaviorSubject<boolean>(false);

  initFirebase(): void {
    // initially consider user as disconnected
    // the user might be logged in from previous time
    // firebase auth can stay alive for several day but ours has a 30min lifetime
    this.angularFireAuth.auth.signOut();

    // (service if token is valid will only fired the first connected user does not initiate with null)
    const connectionState$ = merge(of(null), this.userService.getUser$()).pipe(
      map(user => this.isUserLoggedIn(user)),
      pairwise(),
      map(([prevConnected, currentConnected]) => {
        if (prevConnected === currentConnected) {
          return ConnectionState.SAME_AS_PREVIOUS;
        } else if (!prevConnected && currentConnected) {
          return ConnectionState.JUST_LOGGED_IN;
        } else if (prevConnected && !currentConnected) {
          return ConnectionState.JUST_LOGGED_OUT;
        }
      })
    );

    // log in user to firebase
    connectionState$
      .pipe(
        filter(state => state === ConnectionState.JUST_LOGGED_IN),
        mergeMap(() => this.http.get<string>(environment.apiEntryPoint + '/users/firebase')),
        tap(token => {
          this.angularFireAuth.auth.signInWithCustomToken(token).then(() => {
            this.canSubscribeFirebase$.next(true);
          });
        })
      )
      .subscribe();

    // log out users from firebase
    connectionState$
      .pipe(
        filter(state => state === ConnectionState.JUST_LOGGED_OUT),
        tap(() => this.angularFireAuth.auth.signOut())
      )
      .subscribe();
  }

  private isUserLoggedIn(user: User): boolean {
    return !!user && !!user.id && this.authService.isTokenNotExpired();
  }

  manageCapacitorNotifPush() {
    // Laisser vide, le browser ne s'inquiète pas des notifications push :)
    // Et aussi, #MerciIE
  }
}
