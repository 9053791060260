import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "../../../environments/environment";
import AppInfoJson from "../../../assets/app.info.json";

class AppInfo {
  version: string;
  buildType: string;
  date: string;
}

export enum CompatibilityState {
  UPDATE_REQUIRED = 'UPDATE_REQUIRED',
  UPDATE_AVAILABLE = 'UPDATE_AVAILABLE',
  UP_TO_DATE = 'UP_TO_DATE'
}

export class CompatibilityVersion {
  compatibility: CompatibilityState;
  message: string;
}

@Injectable()
export class NewVersionService {
  readonly appInfo$ = new BehaviorSubject(AppInfoJson);
  readonly checkVersion$ = this.getCall$();
  constructor(private http: HttpClient) {}

  private getCall$(): Observable<CompatibilityVersion> {
    let version = AppInfoJson.version;
    let params = new HttpParams();
    params = params.append('version', version);
    return this.http.get<CompatibilityVersion>(environment.apiEntryPoint + '/checkVersion', { params });
  }
}
