import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { PopinAdminMaintenanceService } from "../popin-admin-maintenance/popin-admin-maintenance.service";
import { map } from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class MaintenanceGuard implements CanActivate {

  constructor(
    private popinAdminMaintenanceService: PopinAdminMaintenanceService,
    private router: Router,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.popinAdminMaintenanceService.showMaintenance$.pipe(
      map(isMaintenance => {
        if (!isMaintenance) {
          return true;
        } else {
          return this.router.createUrlTree(['/maintenance']);  
        }
      }),
    );
  }
}
