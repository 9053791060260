import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'breakline'
})
export class BreaklinePipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return '';
    }
    return value.replace(new RegExp('\r?\n', 'g'), '<br>');
  }
}
