export enum OS {
  MacOS = 'MacOS',
  iOS = 'iOS',
  Windows = 'Windows',
  Android = 'Android',
  Linux = 'Linux',
}

function getOS() {
  const userAgent = window.navigator.userAgent;
  const platform = window.navigator.platform;
  const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
  const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'];
  const iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = OS.MacOS;
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = OS.iOS;
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = OS.Windows;
  } else if (/Android/.test(userAgent)) {
    os = OS.Android;
  } else if (/Linux/.test(platform)) {
    os = OS.Linux;
  }

  return os;
}

export function isOS(os: OS): boolean {
  return getOS() === os;
}
