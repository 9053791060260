import { Component, OnInit } from "@angular/core";
import { LegalNoticeService } from "./legal-notice.service";

@Component({
  selector: 'anie-legal-notice',
  templateUrl: './legal-notice.component.html',
  styleUrls: ['./legal-notice.component.scss']
})
export class LegalNoticeComponent implements OnInit {

  legalNotice: string;

  constructor(private legalNoticeService: LegalNoticeService) { }

  ngOnInit() {
    this.legalNoticeService.getLegalNotice$()
      .subscribe(ln => this.legalNotice = ln);
  }

}
