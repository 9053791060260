import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { filter, first, mergeMap, tap } from "rxjs/operators";
import { SwUpdate } from "@angular/service-worker";
import { environment } from "../../../environments/environment";
import { CompatibilityState, CompatibilityVersion, NewVersionService } from "./new-version.service";
import { isOS, OS } from "../../shared/utils/platform-utils";
import { deviceReady$ } from "../../shared/utils/cordova-utils";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: 'anie-new-version-available',
  templateUrl: './new-version-available.component.html',
  styleUrls: ['./new-version-available.component.scss']
})
export class NewVersionAvailableComponent implements OnInit {

  @ViewChild('updateFrontDialog', { static: true }) updateFrontDialog: TemplateRef<any>;
  @ViewChild('updateAppDialog', { static: true }) updateAppDialog: TemplateRef<any>;

  compatibilityAnswer: CompatibilityVersion;
  showAndroidLink = isOS(OS.Android);
  showIOSLink = isOS(OS.iOS);

  constructor(
    private swUpdate: SwUpdate,
    private newVersionService: NewVersionService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) { }

  ngOnInit() {
    if (!!environment.domain) {
      deviceReady$.pipe(
        mergeMap(() => this.newVersionService.checkVersion$),
        first(),
      ).subscribe(cv => {
        if(cv.compatibility === CompatibilityState.UPDATE_REQUIRED) {
          this.openUpdateAppModal();
        }
        this.compatibilityAnswer = cv;
        if (cv.compatibility === CompatibilityState.UPDATE_AVAILABLE) {
          this.snackBar.open("Une nouvelle version de l'application est disponible", "",{duration: 2000, panelClass: ['snackbar', 'error']});

        }
      });
    }

    if (!environment.domain && this.swUpdate.isEnabled) {
      // on web app, service worker is responsible for updating the client
      this.swUpdate.available.pipe(
        filter(event => event.current !== event.available),
        tap(() => this.openUpdateFrontModal()),
      ).subscribe();
    }
  }

  updateFront(): void {
    this.swUpdate.activateUpdate().then(() => document.location.reload());
  }

  openUpdateFrontModal(){
    this.dialog.open(this.updateFrontDialog, { disableClose: true });
  }

  openUpdateAppModal(){
    this.dialog.open(this.updateAppDialog, { disableClose: true });
  }
}
