import { Directive, ElementRef, HostListener, OnDestroy, OnInit } from "@angular/core";
import { KeyboardOpenService } from "../keyboard-open.service";

@Directive({
  // as the goal is to add a genereric behavior to vanilla HTML, the selector juste select an HTML element
  selector: 'input'
})
export class KeyboardOpenDirective implements OnInit, OnDestroy {

  constructor(
    private elementRef: ElementRef,
    private keyboardOpenService: KeyboardOpenService,
  ) { }

  ngOnInit(): void {
    this.keyboardOpenService.addField(this.elementRef);
  }

  ngOnDestroy(): void {
    this.keyboardOpenService.removeField(this.elementRef);
  }

  @HostListener('focus')
  getFocus() {
    this.keyboardOpenService.updateFocuseState(this.elementRef, true);
  }

  @HostListener('blur')
  getBlur() {
    this.keyboardOpenService.updateFocuseState(this.elementRef, false);
  }

}
