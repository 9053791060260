import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { Referential } from "../basic/referentials.service";
import { environment } from "../../../../environments/environment";

@Injectable()
export class JobsCentersService {

  constructor(private http: HttpClient) {
  }

  searchCenters$(q: string, t?: number): Observable<Referential[]> {
    let params = new HttpParams();
    params = params.append('q', q);
    if (t || t === 0) {
      params = params.append('t', t.toString());
    }
    return this.http.get<Referential[]>(environment.apiEntryPoint + '/jobs/centers', {params});
  }

  searchDegree$(s: string): Observable<Referential[]> {
    let params = new HttpParams();
    params = params.append('s', s);
    return this.http.get<Referential[]>(environment.apiEntryPoint + '/jobs/centers/degree', {params});
  }

  searchDegreeName$(s: string, t?: string): Observable<Referential[]> {
    let params = new HttpParams();
    params = params.append('s', s);
    if (t) {
      params = params.append('t', t);
    }
    return this.http.get<Referential[]>(environment.apiEntryPoint + '/jobs/centers/degree-type', {params});
  }

  searchSites$(q: string, t: string): Observable<Referential[]> {
    let params = new HttpParams();
    params = params.append('q', q);
    params = params.append('t', t);
    return this.http.get<Referential[]>(environment.apiEntryPoint + '/jobs/centers/site', {params});
  }
}
