import { Inject, NgModule, NgZone } from '@angular/core';
import { RootRoutingModule } from './root-routing.module';
import { LayoutComponent } from './layout/layout.component';
import { HomeComponent } from './home/home.component';
import { SharedModule } from '../shared/shared.module';
import { UserService } from '../user/user.service';
import { SecurityModule } from '../security/security.module';
import { ProfileCompleteGuard } from '../user/profile-complete.guard';
import { OfferService } from '../offers/offer.service';
import { ReferentialsService } from '../shared/referentials/basic/referentials.service';
import { globalReducers } from '../technical/store/global-reducer';
import { metaReducers } from '../technical/store/meta-reducer';
import { AnieQuestionsEffects } from '../anie/store/anie-questions.effect';
import { EffectsModule } from '@ngrx/effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { environment } from '../../environments/environment';
import { JobsCategoryService } from '../shared/referentials/jobs-category/jobs-category.service';
import { ArticleService } from '../articles/article.service';
import { ComingSoonMatchComponent } from './coming-soon/coming-soon-match/coming-soon-match.component';
import { ComingSoonNotificationComponent } from './coming-soon/coming-soon-notification/coming-soon-notification.component';
import { FaqComponent } from './faq/faq.component';
import { MarkdownModule } from 'ngx-markdown';
import { StatsService } from '../shared/stats.service';
import { LegalNoticeComponent } from './legal-notice/legal-notice.component';
import { LegalNoticeService } from './legal-notice/legal-notice.service';
import { WINDOW_ID } from '../shared/injectorTokens';
import { MatchService } from '../match/match.service';
import { AvatarService } from '../user/profile/avatar/avatar.service';
import { PaginateHttpService } from '../shared/utils/http/paginate-http.service';
import { InterviewService } from '../match/interview.service';
import { NotificationsService } from '../notifications/notifications.service';
import { TestErrorService } from '../shared/test-error.service';
import { CguComponent } from './cgu/cgu.component';
import { PoliticConfidentialComponent } from './politic-confidential/politic-confidential.component';
import { UpdateAuthorizationForRgpdComponent } from './update-authorization-for-rgpd/update-authorization-for-rgpd.component';
import { FirebaseService } from '../shared/firebase.service';
import { DeveloperComponent } from './developer/developer.component';
import { DeveloperService } from './developer/developer.service';
import { Router } from '@angular/router';
import { KeyboardOpenService } from '../shared/keyboard-open.service';
import { NewVersionAvailableComponent } from './new-version-available/new-version-available.component';
import { NewVersionService } from './new-version-available/new-version.service';
import { ArmlComponent } from './arml/arml.component';
import { ArmlService } from './arml/arml.service';
import { PopinAdminMaintenanceComponent } from './popin-admin-maintenance/popin-admin-maintenance.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { SignInComponent } from './home/sign-in/sign-in.component';
import { ForgottenPasswordComponent } from './home/sign-in/forgotten-password/forgotten-password.component';
import { ResetPasswordComponent } from './home/sign-in/reset-password/reset-password.component';
import { PasswordFormControlModule } from '../shared/password-form-control/password-form-control.module';
import { JobsCentersService } from '../shared/referentials/jobs-centers/jobs-centers.service';
import { TroisiemeComponent } from './troisieme/troisieme.component';
import { TroisiemesService } from '../troisiemes/troisiemes.service';
import { SchoolService } from '../troisiemes/school.service';
import { ReportService } from '../shared/form-component/report/report.service';
import { AdvancedPoliticConfidentialComponent } from './politic-confidential/advanced-politic-confidential.component';
import { IonicModule, Platform } from '@ionic/angular';
import { NetworkService } from '../network.service';
import { NotConnectedComponent } from './not-connected/not-connected.component';
import { Browser, Plugins } from '@capacitor/core';
import { PopinRecruiterUpdateProfileComponent } from './popin-recruiter-update-profile/popin-recruiter-update-profile.component';

const { StatusBar } = Plugins;

@NgModule({
  imports: [
    SharedModule,
    SecurityModule,
    RootRoutingModule,
    StoreModule.forRoot(globalReducers, {
      metaReducers: metaReducers,
      runtimeChecks: { strictStateImmutability: true, strictActionImmutability: true }
    }),
    EffectsModule.forRoot([AnieQuestionsEffects]),
    !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 50 }) : [],
    MarkdownModule.forChild(),
    PasswordFormControlModule,
    IonicModule
  ],
  exports: [RootRoutingModule],
  declarations: [
    LayoutComponent,
    HomeComponent,
    ComingSoonMatchComponent,
    ComingSoonNotificationComponent,
    FaqComponent,
    LegalNoticeComponent,
    UpdateAuthorizationForRgpdComponent,
    CguComponent,
    PoliticConfidentialComponent,
    AdvancedPoliticConfidentialComponent,
    DeveloperComponent,
    NewVersionAvailableComponent,
    ArmlComponent,
    PopinAdminMaintenanceComponent,
    MaintenanceComponent,
    SignInComponent,
    ForgottenPasswordComponent,
    ResetPasswordComponent,
    TroisiemeComponent,
    PopinRecruiterUpdateProfileComponent,
    NotConnectedComponent
  ],
  providers: [
    UserService,
    ProfileCompleteGuard,
    OfferService,
    ReferentialsService,
    JobsCategoryService,
    ArticleService,
    JobsCentersService,
    StatsService,
    KeyboardOpenService,
    LegalNoticeService,
    AvatarService,
    LegalNoticeService,
    MatchService,
    InterviewService,
    PaginateHttpService,
    NotificationsService,
    FirebaseService,
    TestErrorService,
    DeveloperService,
    ArmlService,
    NewVersionService,
    TroisiemesService,
    SchoolService,
    ReportService,
    { provide: WINDOW_ID, useValue: window }
  ]
})
export class RootModule {
  constructor(
    firebaseAuthService: FirebaseService,
    interviewService: InterviewService,
    notificationService: NotificationsService,
    networkService: NetworkService,
    router: Router,
    platform: Platform,
    ngZone: NgZone,
    @Inject(WINDOW_ID) private window: Window
  ) {
    // Ajoute des listeners sur online & offline tout en haut de l'appli ;
    networkService.initNetworkWatch();
    firebaseAuthService.initFirebase();
    interviewService.initGlobalChatNotif();
    notificationService.initInAppNotification();

    // setBackgroundColor method doesn't work in ... iOS :')
    if (platform.is('hybrid')) StatusBar.setBackgroundColor({ color: '#c20012' }).then();

    // markdown can dispatch event for local rerouting but as they are outside of angular
    // it has to be catch somewhere
    this.window.document.addEventListener(
      'routeTo',
      (e: CustomEvent) => {
        e.preventDefault();
        router.navigateByUrl(e.detail);
      },
      false
    );
  }
}
