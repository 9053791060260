import { MarkedOptions, MarkedRenderer } from "ngx-markdown";
import { environment } from "../../environments/environment";

export function markedOptionsFactory(): MarkedOptions {
  const renderer = new MarkedRenderer();
  const isNative = !!environment.domain;

  renderer.link = (href: string, title: string, text: string) => {
    const isMediaLink = /^\/media/.test(href);
    const isLocalLink = /^\//.test(href);
    const target = isLocalLink && !isMediaLink ? '_self' : '_blank';

    let hrefContent;
    if (isLocalLink && !isMediaLink) {
      hrefContent = `javascript:document.dispatchEvent(new CustomEvent('routeTo', {detail: '${href}'})); void(0)`;
    } else if (!isNative) {
      hrefContent = href;
    } else if (isNative) {
      return `<a href="${href}" ${title ? 'title="' + title + '"' : ''} anieExternalLink>
      ${text.replace(/\//g, '/<wbr>')}
      </a>`;
    }

    return `<a href="${hrefContent}" target="${target}" ${title ? 'title="' + title + '"' : ''} ${isMediaLink ? 'download' : ''}>
      ${text.replace(/\//g, '/<wbr>')}
    </a>`;
  };
  renderer.text = (text: string) => {
    const punctuationWithNBSP = / ([;:!?]|(\.\.\.))/g;
    return text.replace(punctuationWithNBSP, '&nbsp;$1 ');
  };
  // display of tooltips is controlled by CSS see: src/styles/_tooltips.scss
  renderer.em = (str: string) => {
    return `<span class="md-tooltip" tabindex="0"><i class="md-tooltip-icon icon-faq"></i><em>${str}</em></span>`;
  };

  return {
    renderer: renderer,
    gfm: true,
    tables: true,
    breaks: false,
    pedantic: false,
    sanitize: false,
    smartLists: true,
    smartypants: false,
  } as MarkedOptions;
}

