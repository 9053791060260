import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'replaceAnchor'
})
export class ReplaceAnchorPipe implements PipeTransform {

  transform(value: any, newChar = ''): any {
    value = value.replace( /\ /gi, newChar);    // Replace all spaces
    value = value.replace( /\./g, newChar);     // Replace all dots
    return value.replace(/[0-9]/g, newChar);    // Replace all numbers
  }

}
