export const environment = {
  production: false,
  domain: '', // empty domain is also used to differentiate cordova app from web app
  apiEntryPoint: '/api', // cannot use dynamic expression with this
  config: {
    apiKey: 'AIzaSyDM_TvHbUkBmcxcnkToBL34y-JT-Gn8ACw',
    authDomain: 'jump-anie-preprod.firebaseapp.com',
    databaseURL: 'https://jump-anie-preprod.firebaseio.com',
    projectId: 'jump-anie-preprod',
    storageBucket: 'jump-anie-preprod.appspot.com',
    messagingSenderId: '352813197061'
  },
  skillgymUrl: 'https://test.skillgym.biz/default.asp?' +
    'ut=25E7B-0B402-AB2E6-D6FBC-6B677' +
    '&uc=92A4C6AEF9C063E3' +
    '&up=926E162E4CE364E5EFC67EB' +
    '&cmpK=F37845815418054CFA447FB84DF01498334FF83418A047F284BF' +
    '&mat=[matricule]' +
    '&gen=[gender]'
};
