import { AfterViewInit, Component, HostBinding, HostListener, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { delay, distinct, filter, first, map, skip, takeUntil, tap } from 'rxjs/operators';
import { UserService } from '../../user/user.service';
import { BehaviorSubject, combineLatest, interval, Subject, Subscription } from 'rxjs';
import { CandidateType, UserType } from '../../user/user';
import { WINDOW_ID } from '../../shared/injectorTokens';
import { Meta } from '@angular/platform-browser';
import { NotificationsService } from '../../notifications/notifications.service';
import { InterviewService } from '../../match/interview.service';
import { MatchService } from '../../match/match.service';
import { LottieAnimComponent } from '../../shared/ui/lottie-anim/lottie-anim.component';
import { PopinAdminMaintenanceService } from '../popin-admin-maintenance/popin-admin-maintenance.service';
import { environment } from '../../../environments/environment';
import { NavController, Platform } from '@ionic/angular';
import { HistoryService } from '../history.service';
// Type Safe. Current capacitor 2 limitation
import { AppTrackingTransparencyPlugin, AppTrackingStatusResponse } from 'capacitor-plugin-app-tracking-transparency';
import { Plugins } from '@capacitor/core';

const AppTrackingTransparency = Plugins.AppTrackingTransparency as AppTrackingTransparencyPlugin;

const HEADER_HEIGHT_ANIMATION_DETECTION = 100;

export enum LayoutDisplayMode {
  KEEP_ALL = 'KEEP_ALL',
  KEEP_HEADER = 'KEEP_HEADER',
  KEEP_NAV = 'KEEP_NAV',
  KEEP_NOTHING = 'KEEP_NOTHING'
}

@Component({
  selector: 'anie-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent implements OnInit, OnDestroy, AfterViewInit {
  unsubscribeAll$ = new Subject<void>();
  afterViewInit$ = new Subject<void>();
  isNatif: boolean = this.platform.is('hybrid');
  isIos: boolean = this.platform.is('ios');

  menuOpened: boolean;

  currentDisplayMode = LayoutDisplayMode.KEEP_ALL;
  layoutTitle: string;
  isRecruiter = false;
  isCandidate = false;
  is3e = false;
  isLoggedIn$ = this.userService.isLoggedIn$();
  hasScrollLimitBeingPassed = false;
  showBack = false;

  iosTrackingAsked = false;
  iosTrackingLoopSub: Subscription;

  cguUrl = environment.domain + '/detailsCGU'; // domain is required for externalLinks
  politicConfidentialityUrl = environment.domain + '/politicConfidentiality'; // domain is required for externalLinks
  legalNoticeUrl = environment.domain + '/legalNotice'; // domain is required for externalLinks

  nbPeOffers$ = this.userService.getUser$().pipe(map(u => (u && u.recruiter && u.recruiter.nbNewPEOffers) || 0));
  nbNotifs$ = this.notificationsService.nbUnseenNotifications$();
  nbMatchActions$ = combineLatest(this.interviewService.getNbContextUnread$(), this.matchService.getNbToAccept$()).pipe(map(([unRead, toAccept]) => unRead + toAccept));
  animNbNotifs$ = new BehaviorSubject<boolean>(false);

  @ViewChild(LottieAnimComponent, { static: false }) toggleMenuAnimation: LottieAnimComponent;
  isMaintenance$ = this.popinAdminMaintenanceService.isMaintenance$;

  @HostBinding('class.show-header') get showHeader() {
    return this.currentDisplayMode === LayoutDisplayMode.KEEP_ALL || this.currentDisplayMode === LayoutDisplayMode.KEEP_HEADER;
  }

  @HostBinding('class.show-nav') get showNav() {
    return this.currentDisplayMode === LayoutDisplayMode.KEEP_ALL || this.currentDisplayMode === LayoutDisplayMode.KEEP_NAV;
  }

  @HostBinding('class.hide-nav') get hideNav() {
    return this.currentDisplayMode !== LayoutDisplayMode.KEEP_ALL && this.currentDisplayMode !== LayoutDisplayMode.KEEP_NAV;
  }

  @ViewChild('handicapDialog', { static: false }) handicapDialog: TemplateRef<any>;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private navCtrl: NavController,
    private userService: UserService,
    private notificationsService: NotificationsService,
    private interviewService: InterviewService,
    private matchService: MatchService,
    private meta: Meta,
    private platform: Platform,
    private historyService: HistoryService,
    private popinAdminMaintenanceService: PopinAdminMaintenanceService,
    @Inject(WINDOW_ID) private window: Window
  ) {}

  ngOnInit() {
    this.initializeAppTracking();

    this.historyService.nativeHistoryBack(this.router.url);
    if (this.isNatif) {
      this.historyService.hardwareAndroidBackButton();
    }

    this.menuOpened = window.matchMedia('(min-width: 845px)').matches;
    if (this.menuOpened) {
      this.afterViewInit$
        .pipe(
          first(),
          tap(() => this.toggleMenuAnimation && this.toggleMenuAnimation.setFrame('#Outro_Start'))
        )
        .subscribe();
    }

    this.manageDisplayMode(this.getLeafRoute(this.route.snapshot));

    this.router.events
      .pipe(
        takeUntil(this.unsubscribeAll$),
        filter(event => event instanceof NavigationEnd),
        map(() => this.getLeafRoute(this.route.snapshot)),
        tap(leafRoute => this.manageDisplayMode(leafRoute)),
        tap(leafRoute => this.adaptScroll(leafRoute)),
        tap(() => (this.showBack = Array.isArray(this.historyService.history) && this.historyService.history.length > 1))
      )
      .subscribe();

    this.userService
      .getUser$()
      .pipe(
        takeUntil(this.unsubscribeAll$),
        tap(user => (this.isRecruiter = !!user && user.profile === UserType.RECRUITER)),
        tap(user => (this.isCandidate = !!user && user.profile === UserType.CANDIDATE)),
        tap(user => (this.is3e = !!user && user.profile === UserType.CANDIDATE && user.candidate.type === CandidateType.TROISIEME))
      )
      .subscribe();

    this.meta.addTags([
      { property: 'og:title', content: 'Anie' },
      { property: 'og:site_name', content: 'Anie' },
      { property: 'og:image', content: this.window.location.origin + '/assets/img/shared-image.jpg' },
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: this.window.location.origin },
      { property: 'og:description', content: "L'appli qui connecte les employeurs et apprentis de la Région Occitanie." },
      { property: 'og:locale', content: 'fr_FR' }
    ]);

    // handle an observable to manage usage of animation class
    this.notificationsService
      .nbUnseenNotifications$()
      .pipe(
        skip(1),
        distinct(),
        takeUntil(this.unsubscribeAll$),
        tap(() => this.animNbNotifs$.next(false)), // before to add the class shortly remove it to replay it if already played
        delay(200),
        tap(() => this.animNbNotifs$.next(true))
      )
      .subscribe();
  }

  ngAfterViewInit(): void {
    this.afterViewInit$.next();
  }

  ngOnDestroy(): void {
    this.unsubscribeAll$.next();
    this.unsubscribeAll$.complete();
    this.afterViewInit$.complete();
  }

  manageDisplayMode(leafRoutSnapshot: ActivatedRouteSnapshot): void {
    // how display the layout header without footer ?
    const rawDisplayMode = this.getFirstDataFromLeaf(leafRoutSnapshot, 'layoutDisplay');
    this.currentDisplayMode = <LayoutDisplayMode>rawDisplayMode || LayoutDisplayMode.KEEP_ALL;

    // what title should be used (default '')
    this.layoutTitle = this.getFirstDataFromLeaf(leafRoutSnapshot, 'layoutTitle') || '';
  }

  adaptScroll(leafRoutSnapshot: ActivatedRouteSnapshot): void {
    const layoutScrollTop = this.getFirstDataFromLeaf(leafRoutSnapshot, 'layoutScrollTop');
    if (layoutScrollTop) {
      this.window.scrollTo(0, 0);
    }
  }

  getLeafRoute(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    while (route.children.length) {
      if (route.children.length > 1) {
        console.warn('More than one child !');
      }
      route = route.children[0];
    }
    return route;
  }

  /**
   * run through all routes (from leaf to root parent) and
   * retrieve the first data 'dataKey' found
   * if nothing is found in route return null
   * @param {ActivatedRouteSnapshot} leafRoute
   * @param {string} dataKey
   * @returns {*}
   */
  getFirstDataFromLeaf(leafRoute: ActivatedRouteSnapshot, dataKey: string): string {
    let currentRoute = leafRoute;
    let containKey = !!currentRoute.data[dataKey];
    while (!containKey && currentRoute.parent) {
      currentRoute = currentRoute.parent;
      containKey = !!currentRoute.data[dataKey];
    }
    return containKey ? currentRoute.data[dataKey] : null;
  }

  /*
   * navCtrl ne fonctionne pas en natif, d'où service homemade
   * */
  back() {
    if (this.isNatif) {
      this.historyService.back();
    } else {
      this.navCtrl.back();
      this.historyService.backWithoutNavigate();
    }
  }

  @HostListener('document:scroll', ['$event'])
  onScroll($event): void {
    const currentScroll = $event.target.scrollingElement.scrollTop;
    if (this.hasScrollLimitBeingPassed && currentScroll < HEADER_HEIGHT_ANIMATION_DETECTION) {
      this.hasScrollLimitBeingPassed = false;
    } else if (!this.hasScrollLimitBeingPassed && currentScroll > HEADER_HEIGHT_ANIMATION_DETECTION) {
      this.hasScrollLimitBeingPassed = true;
    }
  }

  logout(): void {
    this.userService.logout().subscribe(() => this.router.navigate(['/home']));
  }

  public async getStatus(): Promise<AppTrackingStatusResponse> {
    return AppTrackingTransparency.getStatus();
  }

  public async requestPermission(): Promise<AppTrackingStatusResponse> {
    return AppTrackingTransparency.requestPermission();
  }

  async initializeAppTracking() {
    if (this.isIos) {
      this.iosTrackingLoopSub = interval(1000)
        .pipe(
          filter(() => !this.iosTrackingAsked),
          tap(async () => {
            const response = await this.getStatus();
            console.log('GetStatus', response.status);
            // Valeurs possibles : 'authorized' | 'denied' | 'notDetermined' | 'restricted'
            // @ts-ignore
            if (!response || response.status === 'notDetermined') {
              const response2 = await this.requestPermission();
              if (response2 && response2.status !== 'notDetermined') {
                console.log('GetPermission', response2.status);
                this.applyAppTrackingTransparency(response2.status);
              }
            } else {
              // @ts-ignore
              this.applyAppTrackingTransparency(response.status);
            }
          })
        )
        .subscribe();
    } else {
      this.activateTracking();
    }
  }

  activateTracking() {
    // @ts-ignore
    tarteaucitron.init({
      privacyUrl: '/politicConfidentiality' /* Privacy policy url */,
      hashtag: '#tarteaucitron' /* Open the panel with this hashtag */,
      cookieName: 'tarteaucitron' /* Cookie name */,
      orientation: 'bottom' /* Banner position (top - bottom) */,
      showAlertSmall: true /* Show the small banner on bottom right */,
      cookieslist: false /* Show the cookie list */,
      showIcon: false /* Show cookie icon to manage cookies */,
      iconPosition: 'BottomRight' /* BottomRight, BottomLeft, TopRight and TopLeft */,
      DenyAllCta: true /* Show the deny all button */,
      AcceptAllCta: true /* Show the accept all button when highPrivacy on */,
      highPrivacy: true /* HIGHLY RECOMMANDED Disable auto consent */,
      handleBrowserDNTRequest: false /* If Do Not Track == 1, disallow all */,
      removeCredit: true /* Remove credit link */,
      moreInfoLink: true /* Show more info link */,
      useExternalCss: false /* If false, the tarteaucitron.css file will be loaded */,
      readmoreLink: '/politicConfidentiality' /* Change the default readmore link */,
      mandatory: true /* Show a message about mandatory cookies */
    });
    // @ts-ignore
    tarteaucitron.user.domainHost = '//occitanie';
    // @ts-ignore
    tarteaucitron.user.matomoTagManagerId = '06ce7893-2e41-4139-a9ff-fe7b07ee0da8';
    // @ts-ignore
    (tarteaucitron.job = tarteaucitron.job || []).push('matomotagmanager');
    // @ts-ignore
    tarteaucitron.load();
  }

  applyAppTrackingTransparency(status: string) {
    if (status === 'authorized') {
      this.activateTracking();
    }
    this.iosTrackingAsked = true;
    if (this.iosTrackingLoopSub) {
      this.iosTrackingLoopSub.unsubscribe();
    }
  }
}
