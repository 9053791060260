import { Pipe, PipeTransform } from "@angular/core";
import { accentFolding } from "../utils/string-utils";

@Pipe({
  name: 'accentFolding'
})
export class AccentFoldingPipe implements PipeTransform {

  transform(value: string): string {
    return accentFolding(value);
  }

}
