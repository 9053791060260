import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { shareReplay } from "rxjs/operators";
import { environment } from "../../../environments/environment";

export class Developer {
  territory: string[];
  sector: string;
  name: string[];
  contact: string[];
}

@Injectable()
export class DeveloperService {

  readonly developers$: Observable<Developer[]>;

  constructor(private http: HttpClient) {
    this.developers$ = http.get<Developer[]>(`${environment.domain}/media/developpeurs.json`).pipe(
      shareReplay()
    );
  }
}
