import { Component, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { Plugins } from '@capacitor/core';
import { environment } from '../environments/environment';

const { App } = Plugins;

@Component({
  selector: 'anie-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(private router: Router, private zone: NgZone) {
    this.initializeApp();
  }

  /* This method redirects deeplinks to the appropriate page of the app */
  initializeApp() {
    App.addListener('appUrlOpen', (data: any) => {
      this.zone.run(() => {
        let domain = environment.domain;
        const slug = data.url.split(domain).pop();
        if (slug || slug == '') {
          this.router.navigateByUrl(slug).catch(e => {
            this.router.navigateByUrl('/anie');
          });
        }
        // If no match, do nothing - let regular routing logic take over
      });
    });
  }
}
