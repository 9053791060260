import { Inject, Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { Action, Store } from "@ngrx/store";
import { Actions, Effect, ofType } from "@ngrx/effects";

import { combineLatest, EMPTY, Observable, of } from "rxjs";
import { delay, filter, map, mergeMap, take, tap } from "rxjs/operators";

import * as anieSelectors from "./anie.selectors";
import * as anieActions from "./anie.actions";
import { AnieAnswerQuestionAction, AnieEndedAction, AnieInitAction, AnieLeaveAction, AnieResetChatbotAction, AnieResetToAction } from "./anie.actions";
import { AnieAnswer, AnieChoice, AnieQuestion, AnieType } from "./anie.model";
import { AnieState } from "./anie.reducers";

import { FirstConnectionStep, ProfileCompleteGuard } from "../../user/profile-complete.guard";
import { OfferService } from "../../offers/offer.service";
import { UserService } from "../../user/user.service";
import { WINDOW_ID } from "../../shared/injectorTokens";
import { environment } from "../../../environments/environment";
import { goTo } from "../../shared/utils/navigation-utils";

@Injectable()
export class AnieQuestionsEffects {

  @Effect()
  anieInit$: Observable<Action> = this.actions$
    .pipe(
      ofType<AnieInitAction>(anieActions.ANIE_INIT),
      map((action: anieActions.AnieInitAction) => getUrlFromAction(action.idForm, action.contextId)),
      mergeMap(url => this.getQuestion(url, new AnieAnswer())),
      map(question => this.mapQuestionToAction(question))
    );

  @Effect()
  anieAnswerQuestion$: Observable<Action> = this.actions$
    .pipe(
      ofType<AnieAnswerQuestionAction>(anieActions.ANIE_ANSWER_QUESTION),
      mergeMap((action: anieActions.AnieAnswerQuestionAction) => {
        // some question use simple value as answer some use complex (need to take the slug)
        const choice: string = action.valueChoice.slug || action.valueChoice;
        return combineLatest(
          this.store$.select(anieSelectors.getAnieType),
          this.store$.select(anieSelectors.getContextId),
          this.store$.select(anieSelectors.getQuestionById(action.id)).pipe(map(q => q.slugSentence)),
          of(choice)
        ).pipe(take(1));
      }),
      mergeMap(([anieType, contextId, slugSentence, choiceValue]) =>
        this.getQuestion(getUrlFromAction(anieType, contextId), new AnieAnswer(slugSentence, choiceValue))),
      map(question => {
        // empty question mean end of the chatbot
        if (question) {
          return this.mapQuestionToAction(question);
        } else {
          return new anieActions.AnieEndedAction();
        }
      })
    );

  /**
   * ask again the last question or reset completely the chatbot
   */
  @Effect()
  anieResetTo$: Observable<Action> = this.actions$
    .pipe(
      ofType<AnieResetToAction>(anieActions.ANIE_RESET_TO),
      mergeMap(() => this.store$.select(anieSelectors.getLastQuestion).pipe(take(1))),
      map(q => !!q ? new anieActions.AnieAnswerQuestionAction(q.id, q.answer) : new anieActions.AnieResetChatbotAction())
    );

  @Effect()
  anieResetChatbot: Observable<Action> = this.actions$
    .pipe(
      ofType<AnieResetChatbotAction>(anieActions.ANIE_RESET_CHATBOT),
      mergeMap(() => combineLatest(
        this.store$.select(anieSelectors.getAnieType),
        this.store$.select(anieSelectors.getContextId)
      ).pipe(take(1))),
      map(([anieType, contextId]) => new anieActions.AnieInitAction(anieType, contextId))
    );

  @Effect({ dispatch: false })
  redirectAfterAnie: Observable<any> = this.actions$
    .pipe(
      ofType<AnieEndedAction>(anieActions.ANIE_ENDED),
      mergeMap(() => this.store$.select(anieSelectors.getAnieType)
                                      .pipe(take(1))),
      mergeMap(anieType => this.manageExitOfChatbot(anieType))
    );

  @Effect({ dispatch: false })
  leaveAnie: Observable<any> = this.actions$
    .pipe(
      ofType<AnieLeaveAction>(anieActions.ANIE_LEAVE),
      mergeMap(() => this.store$.select(anieSelectors.userNeedToBeUpdate).pipe(take(1))),
      filter(needUpdate => needUpdate),
      mergeMap(() => this.userService.refreshConnectedUser$()),
    );

  constructor(private actions$: Actions,
              private store$: Store<AnieState>,
              private profileComplete: ProfileCompleteGuard,
              private offerService: OfferService,
              private userService: UserService,
              private http: HttpClient,
              private router: Router,
              @Inject(WINDOW_ID) private window: Window) {}

  manageExitOfChatbot(anieType: AnieType): Observable<any> {
    switch (anieType) {
      case AnieType.GUIDE_VISITOR:
      case AnieType.GUIDE_CANDIDATE:
      case AnieType.GUIDE_CANDIDATE_PRO:
      case AnieType.GUIDE_TROISIEME:
      case AnieType.GUIDE_RECRUITER: {
        return this.store$.select(anieSelectors.getLastQuestionChoice).pipe(
          take(1), // careful selector are infinite
          tap((choice: AnieChoice) => {
            if (!choice) {
              console.error('Guides must finish with an URL');
              return;
            }
            this.redirectToChoiceUrl(choice.url);
          })
        );
      }
      case AnieType.COMPLETE_PROFILE: {
        // unsuccessful path in complete profile end on a choice with an URL
        return this.store$.select(anieSelectors.getLastQuestionChoice).pipe(
          take(1), // careful selector are infinite
          mergeMap((choice: AnieChoice) => {
            if (choice && choice.url) {
              this.redirectToChoiceUrl(choice.url);
              return EMPTY;
            } else {
              return this.userService.refreshConnectedUser$().pipe(
                mergeMap(() => this.profileComplete.validateFirstConnectionStep$({}, FirstConnectionStep.CANDIDATE_PROFILE))
              );
            }
          }));
      }
      case AnieType.EDIT_PROFILE: {
        return this.userService.refreshConnectedUser$().pipe(
          tap(() => this.router.navigate(['/user', 'profile', 'my-research']))
        );
      }
      case AnieType.PERFECT_CANDIDATE:
      case AnieType.PERFECT_CANDIDATE_PRO: {
        return this.offerService.refreshCurrentOffer().pipe(
          tap(() => this.offerService.goToNextUncompleted())
        );
      }
      case AnieType.QUIZ_ATTITUDE:
      case AnieType.QUIZ_ATTITUDE_PRO: {
        return this.userService.refreshConnectedUser$().pipe(
          mergeMap(() => this.profileComplete.validateFirstConnectionStep$(
            {},
            FirstConnectionStep.CANDIDATE_ATTITUDE,
            ['/user', 'end-quiz']
          ))
        );
      }
    }
  }

  redirectToChoiceUrl(url: string) {
    if (!/^http/.test(url)) {
      this.router.navigateByUrl(url);
    } else {
      goTo(url);
    }
  }

  mapQuestionToAction(question: AnieQuestion): anieActions.AnieQuestionReceivedAction {
    const mappedQuestion = Object.assign({}, question, { id: this.window.performance.now() });
    return new anieActions.AnieQuestionReceivedAction(mappedQuestion);
  }

  getQuestion(url: string, body: AnieAnswer): Observable<AnieQuestion> {
    const minimalDelay$ = of(1).pipe(delay(1000));
    const httpAnswer$ = this.http.put<AnieQuestion>(url, body);
    return combineLatest(minimalDelay$, httpAnswer$).pipe(
      take(1), // just in case of... I had too much trouble with effects replaying because of combineLatest not finished
      map(([useless, response]) => response)
    );
  }

}

function getUrlFromAction(anieType: AnieType, contextId: string): string {
  const contextUrl = contextId ? `/${contextId}` : '';
  return `${environment.apiEntryPoint}/anie/${anieType}${contextUrl}`;
}
