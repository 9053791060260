import { ChangeDetectorRef, Component, forwardRef, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';
import { map, takeUntil, tap } from 'rxjs/operators';
import { Observable, of, Subject } from 'rxjs';
import { formatPostalCode, POSTAL_CODE_MASK_FORMAT, unformatPostalCode } from '../../utils/format-utils';
import { TownshipService } from '../../utils/open-data/geo/township.service';
import { Town } from '../../utils/open-data/geo/town';

@Component({
  selector: 'anie-city-control',
  templateUrl: './city-control.component.html',
  styleUrls: ['./city-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CityControlComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CityControlComponent),
      multi: true
    }
  ]
})
export class CityControlComponent implements OnInit, ControlValueAccessor, Validator, OnChanges, OnDestroy {
  @Input() textCp;
  @Input() textCity;
  @Input() required = true;

  unsubscribeAll$ = new Subject<void>();

  cityForm: FormGroup;
  correspondingCities: Town[];
  postalCodeMaskOption = { mask: POSTAL_CODE_MASK_FORMAT };

  // custom form control
  // tslint:disable-next-line
  propagateChange = (_: any) => {};

  constructor(private fb: FormBuilder, private townshipService: TownshipService, private changeDetectionRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.cityForm = this.fb.group({
      postalCode: ['', Validators.pattern(/\d{2} \d{3}/), this.validatePostalCodeExist.bind(this)],
      city: ['', Validators.required]
    });

    this.changeDetectionRef.detectChanges();
    this.cityForm.valueChanges.pipe(takeUntil(this.unsubscribeAll$)).subscribe(v => {
      const value = { ...v };
      if (value.postalCode) {
        value.postalCode = unformatPostalCode(value.postalCode);
      }
      if (value && value.city && this.correspondingCities && this.correspondingCities.length) {
        const city = this.correspondingCities.find(town => town.nom === value.city);
        if (city) {
          value.longitude = city.centre.coordinates[0];
          value.latitude = city.centre.coordinates[1];
        }
      }
      this.propagateChange(value);
    });

    const postalCodeControl = this.cityForm.get('postalCode');
    postalCodeControl.valueChanges.pipe(takeUntil(this.unsubscribeAll$)).subscribe(() => this.cityForm.patchValue({ city: '' }));
  }

  /*
   * Permet lors d'un refresh de page de remettre dans les inputs correspondantes les valeurs
   * */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.postalCodeExisting && !changes.postalCodeExisting.firstChange) {
      this.cityForm.get('postalCode').setValue(changes.postalCodeExisting.currentValue);
      this.correspondingCities.push({ nom: changes.cityExisting.currentValue });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeAll$.next();
    this.unsubscribeAll$.complete();
  }

  validatePostalCodeExist(c: AbstractControl): Observable<any> {
    // when the formGroup is created, the async Validators are called a first time
    // following code need the form to be defined for cross field dependencies
    if (!this.cityForm) {
      return of({ postalCodeNotFound: true });
    }

    const unformattedPostalCode = unformatPostalCode(c.value);

    // todo display pending ?
    // todo manage error from api.gouv.fr !! (500/502 on the 01/18)
    return this.townshipService.searchTownByPostalCode(unformattedPostalCode).pipe(
      tap(towns => this.setCities(towns, unformattedPostalCode)),
      map(towns => (towns && towns.length ? null : { postalCodeNotFound: true }))
    );
  }

  setCities(towns: Town[], postalCode: string, city?: string): void {
    this.correspondingCities = towns;
    const cityControl = this.cityForm.get('city');
    let newCityValue: Town;
    if (towns.length === 0) {
      newCityValue = null;
    } else if (city && towns.find(c => c.nom === city) !== null) {
      newCityValue = towns.find(c => c.nom === city);
    } else {
      newCityValue = towns[0];
    }

    cityControl.patchValue(newCityValue && newCityValue.nom);
    if (towns && towns.length > 1) {
      cityControl.enable();
    } else {
      cityControl.disable();
    }

    this.propagateChange({
      postalCode,
      city: newCityValue && newCityValue.nom,
      longitude: newCityValue && newCityValue.centre.coordinates[0],
      latitude: newCityValue && newCityValue.centre.coordinates[1]
    });
  }

  writeValue(obj: any): void {
    if (obj && obj.postalCode) {
      this.cityForm.patchValue({ postalCode: formatPostalCode(obj.postalCode) });
      this.townshipService.searchTownByPostalCode(obj.postalCode).subscribe(towns => this.setCities(towns, obj.postalCode, obj.city));
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    // nothing to do unused touch property
  }

  validate(c: AbstractControl): ValidationErrors | null {
    const unformattedPostalCode = c.value && unformatPostalCode(c.value.postalCode);
    const isPostalCodeValid = /^\d{5}$/.test(unformattedPostalCode);
    if (isPostalCodeValid && c.value.city) {
      return null;
    } else if (!isPostalCodeValid) {
      return { postalCode: { valid: false } };
    } else if (!c.value.city) {
      return { city: { unselected: false } };
    }
    console.error('should have been detected as an error or valid');
  }
}
