import { enableProdMode } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";

import { AppModule, canActivateServiceWorker } from "./app/app.module";
import { environment } from "./environments/environment";

if (environment.production) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic().bootstrapModule(AppModule)
    .then(() => {
      // one of dependencies prevent Angular to correctly register the SW => has to be done manually
      if (canActivateServiceWorker) {
        navigator.serviceWorker.register('/ngsw-worker.js');
      }
    })
    .catch(err => console.error(err));
});
