
/**
 * PHONE
 * format 'dd dd dd dd dd'
 * @profile {(RegExp | string)[]}
 */
export const PHONE_MASK_FORMAT = [/\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];

export function formatPhone(raw: string, spacingChar = '\xa0'): string {
  if (!raw) {
    return raw;
  }
  const splitted = [];
  for (let i = 0, len = raw.length; i < len; i += 2) {
    splitted.push(raw.substr(i, 2));
  }
  return splitted.join(spacingChar);
}
export function unformatPhone(raw: string): string {
  return raw.replace(/ /gm, '');
}
/**
 * POSTAL CODE
 * format 'dd dd dd dd dd'
 * @profile {(RegExp | string)[]}
 */
export const POSTAL_CODE_MASK_FORMAT = [/\d/, /\d/, ' ', /\d/, /\d/, /\d/];

export function formatPostalCode(raw: string): string {
  if (!raw) {
    return raw;
  }
  return raw.substr(0, 2) + '\xa0' + raw.substr(2, 3);
}
export function unformatPostalCode(raw: string): string {
  // \D non-digit (avoid special characters as unbreakable space)
  return (raw || '').replace(/\D/gm, '');
}

// taken from this gist: https://gist.github.com/dperini/729294
export const URL_FORMAT = new RegExp(
  '^' +
  // protocol identifier
  '(?:(?:https?|ftp)://)' +
  // user:pass authentication
  '(?:\\S+(?::\\S*)?@)?' +
  '(?:' +
  // IP address exclusion
  // private & local networks
  '(?!(?:10|127)(?:\\.\\d{1,3}){3})' +
  '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})' +
  '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})' +
  // IP address dotted notation octets
  // excludes loopback network 0.0.0.0
  // excludes reserved space >= 224.0.0.0
  // excludes network & broacast addresses
  // (first & last IP address of each class)
  '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])' +
  '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}' +
  '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))' +
  '|' +
  // host name
  '(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)' +
  // domain name
  '(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*' +
  // TLD identifier
  '(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))' +
  // TLD may end with dot
  '\\.?' +
  ')' +
  // port number
  '(?::\\d{2,5})?' +
  // resource path
  '(?:[/?#]\\S*)?' +
  '$', 'i'
);
