import { Injectable, NgZone } from "@angular/core";
import { AngularFireDatabase } from "@angular/fire/database";
import { AngularFireAuth } from "@angular/fire/auth";
import { BehaviorSubject, combineLatest, from, Observable } from "rxjs";
import { isFuture, nowBetween } from "../../shared/utils/date-utils";
import { filter, map, switchMap, tap } from "rxjs/operators";
import DataSnapshot = firebase.database.DataSnapshot;

export class PopinAdmin {
  title: string;
  message: string;
  startAt?: string; // isoString
  endAt?: string; // isoString
  isReady?: boolean;

  // not stored in FireBase
  id: string; // correspond to firebase key
  closed?: boolean; // the user closed the popin
}

@Injectable({
  providedIn: 'root'
})

export class PopinAdminMaintenanceService {

  private readonly _maintenanceDisabled = new BehaviorSubject<boolean>(false);
  readonly isMaintenance$ = this.angularFireDatabase.object<boolean>(`admin/maintenance`).valueChanges();
  readonly showMaintenance$ = combineLatest(this.isMaintenance$, this._maintenanceDisabled).pipe(
    map(([maintenanceActive, maintenanceDisabled]) => maintenanceActive && ! maintenanceDisabled),
  );

  private readonly _popinsRef = this.angularFireDatabase.list<PopinAdmin>(`admin/popins/`);

  private _popins = new BehaviorSubject<PopinAdmin[]>(undefined);
  readonly displayedPopins$: Observable<PopinAdmin[]> = this._popins.pipe(
    filter(list => list !== undefined),
    map(list => list.filter(popin => this._isPopinDisplayable(popin))),
  );

  constructor(
    private angularFireAuth: AngularFireAuth,
    private angularFireDatabase: AngularFireDatabase,
    private ngZone: NgZone,
  ) {
    this.initAllPopins();
  }

  initAllPopins(): void {

    const initFirstList$ = from(this._popinsRef.query.once('value')).pipe(
      map(snapshot => this.parseSnapshotToList(snapshot)),
      tap(popins => this._popins.next(popins)),
    );

    const childModifications$ = nbToSkip => new Observable<DataSnapshot>(observer => {
      this._popinsRef.query.on('child_changed', child => this.ngZone.run(() => observer.next(child)));
      this._popinsRef.query.on('child_removed', child => this.ngZone.run(() => observer.next(child)));
      let nbAdded = 0;
      this._popinsRef.query.on('child_added', child => {
        if (nbAdded < nbToSkip) {
          nbAdded++;
          return;
        }
        this.ngZone.run(() => observer.next(child));
      });
    });

    initFirstList$.pipe(
      switchMap(popins => childModifications$(popins.length)),
      map(snapshot => this._getPopinFromSnapshot(snapshot)),
      tap(popin => this._upsertPopin(popin)),
    ).subscribe();
  }

  closePopin(popinToClose: PopinAdmin): void {
    const popinList = this._popins.getValue();
    const newList = popinList.map(p => p.id === popinToClose.id ? {...p, closed: true} : p);
    this._popins.next(newList);
  }

  private _postponeDisplay(date: Date) {
    const openAtMs = date.getTime();
    const nowMs = new Date().getTime();
    const delayMs = openAtMs - nowMs;

    // re-emit the dataset to trigger recalculation of displayed popins
    setTimeout(() => this._popins.next(this._popins.getValue()), delayMs);
  }

  private _getPopinFromSnapshot(snapshot: DataSnapshot): PopinAdmin {
    const popin: PopinAdmin = {
      ...snapshot.val(),
      id: snapshot.key,
      closed: false,
    };
    if (popin.startAt && isFuture(new Date(popin.startAt))) {
      this._postponeDisplay(new Date(popin.startAt));
    }
    if (popin.endAt && isFuture(new Date(popin.endAt))) {
      this._postponeDisplay(new Date(popin.endAt));
    }
    return popin;
  }

  private _isPopinDisplayable(popin: PopinAdmin): boolean {
    return popin.isReady && !popin.closed && nowBetween(new Date(popin.startAt), new Date(popin.endAt));
  }

  private parseSnapshotToList(snapshot: DataSnapshot): PopinAdmin[] {
    const popins: PopinAdmin[] = [];
    snapshot.forEach(popinSnapshot => {
      popins.push(this._getPopinFromSnapshot(popinSnapshot));
    });
    return popins;
  }

  private _upsertPopin(popin: PopinAdmin): void {
    const popins = this._popins.getValue();
    const oldPopin = popins.find(p => p.id === popin.id);
    if (oldPopin) {
      const newList = popins.map(p => p.id === popin.id ? { ...popin, closed: oldPopin.closed } : p);
      this._popins.next(newList);
    } else {
      this._popins.next([ ...popins, popin ]);
    }
  }

  disableMaintenance() {
    this._maintenanceDisabled.next(true);
  }
}
