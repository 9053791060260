import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../../environments/environment";

@Injectable()
export class ReportService {

  constructor(
    private http: HttpClient,
  ) { }

  reportUser$(userId: number, reasonSlug: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('reasonSlug', reasonSlug);
    return this.http.get(`${environment.apiEntryPoint}/users/${userId}/report`, { params });
  }

  reportCandidate$(candidateId: number, reasonSlug: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('reasonSlug', reasonSlug);
    return this.http.get(`${environment.apiEntryPoint}/candidates/${candidateId}/report`, { params });
  }

  reportOffer$(offerId: number, reasonSlug: string): Observable<any> {
    let params = new HttpParams();
    params = params.append('reasonSlug', reasonSlug);
    return this.http.get(`${environment.apiEntryPoint}/offers/${offerId}/report`, { params });
  }
}
