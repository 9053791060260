import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../../../../security/auth.service";
import { UserService } from "../../../../user/user.service";

@Component({
  selector: 'anie-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetForm: FormGroup;
  token: string;
  hasExpired = false;

  constructor(private fb: FormBuilder,
              private userService: UserService,
              private authService: AuthService,
              private route: ActivatedRoute,
              private router: Router) { }

  ngOnInit() {
    this.token = this.route.snapshot.queryParams.key;
    try {
      this.hasExpired = !this.authService.isTokenNotExpired(this.token);
    } catch (err) {
      this.hasExpired = true;
      console.error(err);
    }

    this.resetForm = this.fb.group({
      password: ''
    });
  }

  validate(): void {
    if (this.resetForm.valid) {
      const token = this.route.snapshot.queryParams.key;
      this.userService.resetPassword$(this.resetForm.value.password, token).subscribe(
        () => this.router.navigate(['/anie']),
        () => this.hasExpired = true // only possible error => token expired
        );
    }
  }

}
