import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { TextMaskModule } from "angular2-text-mask";
import { TownshipService } from "./utils/open-data/geo/township.service";
import { InputFilledDirective } from "./utils/forms/input-filled.directive";
import { AccordionComponent } from "./ui/accordion/accordion.component";
import { CityControlComponent } from "./form-component/city-control/city-control.component";
import { LanguageControlComponent } from "./form-component/language-control/language-control.component";
import { SearchControlComponent } from "./form-component/search-control/search-control.component";
import { TrainingDurationsPipe } from "./referentials/basic/training-durations.pipe";
import { DurationUnitPipe } from "./referentials/basic/duration-unit.pipe";
import { BusyOverlayDirective } from "./ui/pending/busy-overlay.directive";
import { BusyComponent } from "./ui/pending/busy.component";
import { BusyButtonDirective } from "./ui/pending/busy-button.directive";
import { PostalCodePipe } from "./pipes/postal-code.pipe";
import { ModalComponent } from "./ui/modal/modal.component";
import { FirstLetterCapitalizedPipe } from "./pipes/first-letter-capitalized.pipe";
import { SocialNetworksComponent } from "./ui/social-networks/social-networks.component";
import { MissingFieldsComponent } from "./ui/missing-fields/missing-fields.component";
import { RouterModule } from "@angular/router";
import { RateControlComponent } from "./form-component/rate-control/rate-control.component";
import { BreaklinePipe } from "./pipes/breakline.pipe";
import { AvatarUrlPipe, LocalUrlPipe } from "./pipes/url.pipe";
import { ExternalLinkDirective } from "./directives/external-link.directive";
import { FilterByPipe } from "./pipes/filter-by.pipe";
import { ReplaceAnchorPipe } from "./pipes/replace-anchor.pipe";
import { KeyboardOpenDirective } from "./directives/keyboard-open.directive";
import { LottieAnimComponent } from "./ui/lottie-anim/lottie-anim.component";
import { InfoComponent } from "./ui/info/info.component";
import { AccentFoldingPipe } from "./pipes/accent-folding.pipe";
import { MatTabsModule } from "@angular/material/tabs";
import { MatCardModule } from "@angular/material/card";
import { MatButtonModule } from "@angular/material/button";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatListModule } from "@angular/material/list";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatSnackBarModule } from "@angular/material/snack-bar";
import { ReportComponent } from "./form-component/report/report.component";
import { MatDialogModule } from "@angular/material/dialog";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MatMomentDateModule, MomentDateAdapter } from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { DragDropDirective } from "./directives/drag-drop.directive";
import { MatTableModule } from "@angular/material/table";
import { FileOpener } from "@ionic-native/file-opener/ngx";
import { File } from "@ionic-native/file/ngx";

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'DD/MM/YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'DD/MM/YYYY'
  },
};

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    TextMaskModule,
    RouterModule,
    MatTabsModule,
    MatCardModule,
    MatButtonModule,
    MatExpansionModule,
    MatListModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatDialogModule,
    MatRadioModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatTableModule
  ],
  declarations: [
    InputFilledDirective,
    AccordionComponent,
    CityControlComponent,
    LanguageControlComponent,
    SearchControlComponent,
    TrainingDurationsPipe,
    DurationUnitPipe,
    BusyButtonDirective,
    BusyOverlayDirective,
    BusyComponent,
    PostalCodePipe,
    ModalComponent,
    FirstLetterCapitalizedPipe,
    SocialNetworksComponent,
    MissingFieldsComponent,
    SocialNetworksComponent,
    RateControlComponent,
    BreaklinePipe,
    AvatarUrlPipe,
    LocalUrlPipe,
    ExternalLinkDirective,
    FilterByPipe,
    ReplaceAnchorPipe,
    KeyboardOpenDirective,
    LottieAnimComponent,
    InfoComponent,
    AccentFoldingPipe,
    ReportComponent,
    DragDropDirective,
  ],
  exports: [
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    TextMaskModule,
    InputFilledDirective,
    AccordionComponent,
    CityControlComponent,
    LanguageControlComponent,
    SearchControlComponent,
    TrainingDurationsPipe,
    DurationUnitPipe,
    BusyButtonDirective,
    BusyOverlayDirective,
    BusyComponent,
    PostalCodePipe,
    ModalComponent,
    FirstLetterCapitalizedPipe,
    SocialNetworksComponent,
    MissingFieldsComponent,
    SocialNetworksComponent,
    RateControlComponent,
    BreaklinePipe,
    AvatarUrlPipe,
    LocalUrlPipe,
    ExternalLinkDirective,
    FilterByPipe,
    ReplaceAnchorPipe,
    KeyboardOpenDirective,
    LottieAnimComponent,
    InfoComponent,
    AccentFoldingPipe,
    ReportComponent,
    MatTabsModule,
    MatCardModule,
    MatButtonModule,
    MatExpansionModule,
    MatListModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatDialogModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatTooltipModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    DragDropDirective,
    MatTableModule
  ],
  providers: [
    TownshipService,
    DatePipe,
    FileOpener,
    File,
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class SharedModule {}
