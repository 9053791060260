import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, map, switchMap, takeUntil, tap } from 'rxjs/operators';

export class SearchValue {
  slug: string;
  label: string;
}

@Component({
  selector: 'anie-search-control',
  templateUrl: './search-control.component.html',
  styleUrls: ['./search-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchControlComponent),
      multi: true
    }
  ]
})
export class SearchControlComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Input() searching$$: (s: string) => Observable<SearchValue[]>;
  @Input() searchLabel = 'Recherche';
  @Input() selectedLabel = '';
  @Input() required = true;
  @Input() validators: boolean; // inutilé pour le moment
  @Output() shareSelectedValue = new EventEmitter();
  unsubscribeAll$ = new Subject<void>();

  searchForm: FormGroup;
  foundValues: SearchValue[];
  selectedValue: SearchValue;
  tooMuchValues: boolean = false;
  noValue: boolean = false;

  // custom form control
  propagateChange = (_: any) => {};

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.searchForm = this.fb.group({
      searchInput: '',
      resultSlug: ''
    });

    const safeResearch$ = queryString => {
      if (queryString.length < 3) {
        return of([]);
      }
      return this.searching$$(queryString).pipe(
        catchError(() => of([])) // errors breaks the whole pipe chain
      );
    };

    // todo show pending
    this.searchForm
      .get('searchInput')
      .valueChanges.pipe(
        debounceTime(300),
        takeUntil(this.unsubscribeAll$),
        switchMap(s => safeResearch$(s)),
        tap(list => (this.tooMuchValues = list.length > 10)),
        tap(list => (this.noValue = list.length === 0)),
        map(list => list.slice(0, 10))
      )
      .subscribe(results => {
        this.foundValues = results;
      });

    this.searchForm
      .get('resultSlug')
      .valueChanges.pipe(
        takeUntil(this.unsubscribeAll$),
        map(slug => this.foundValues && this.foundValues.find(sv => sv.slug === slug))
      )
      .subscribe(sv => {
        this.selectedValue = sv;
        this.shareSelectedValue.emit(this.selectedValue);
        this.propagateChange(sv);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribeAll$.next();
    this.unsubscribeAll$.complete();
  }

  activeSearchMode(): void {
    this.searchForm.get('resultSlug').patchValue('');
    this.searchForm.get('searchInput').patchValue('');
  }

  writeValue(obj: SearchValue): void {
    if (obj) {
      this.selectedValue = obj;
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    // nothing to do unused touch property
  }
}
