import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Page, Pagination } from "./page";
import { exhaustMap, map, takeUntil, tap } from "rxjs/operators";
import { Params } from "@angular/router";

export interface Paginator<T> {
  getResults$(): Observable<T[]>;
  getNextPage(): void;
  hasNextPage$(): Observable<boolean>;
  unsubscribeAll(): void;
}

class PaginatorImpl<T> implements Paginator<T> {

  private _results$ = new BehaviorSubject<T[]>([]);
  private _meta$ = new BehaviorSubject<Pagination>(null);
  private _nextPage$ = new Subject<void>();
  private _params: HttpParams;
  private _unsubscribeAll = new Subject<void>();

  constructor(private http: HttpClient, private url: string, filters?: Params) {
    this._params = this.objectToHttpParams(filters);

    this._nextPage$.pipe(
      map(() => this._meta$.getValue()),
      map(meta => (meta && meta.page) || 0),
      map(currentPage => currentPage + 1),
      // exhaustMap prevent to send several request at the same time
      // while a request is running no new request is sent
      exhaustMap(nextPageNumber => this.getPage(nextPageNumber, this._params)),
      takeUntil(this._unsubscribeAll),
    ).subscribe();
    this._nextPage$.next();
  }

  private objectToHttpParams(filters: Params = {}): HttpParams {
    let params = new HttpParams();
    Object.keys(filters).forEach(key => params = params.append(key, filters[key]));
    return params;
  }

  private getPage(pageNumber: number = 1, params: HttpParams): Observable<any> {
    params = params.append('page', '' + pageNumber);
    params = params.append('perPage', '12');
    return this.http.get<Page<T>>(this.url, { params }).pipe(
      tap(page => this._meta$.next(page.pagination)),
      map(page => page.results),
      tap(results => this._results$.next([...this._results$.getValue(), ...results])),
    );
  }

  getResults$(): Observable<T[]> {
    return this._results$.asObservable().pipe(
      takeUntil(this._unsubscribeAll),
    );
  }

  getNextPage(): void {
    this._nextPage$.next();
  }

  hasNextPage$(): Observable<boolean> {
    return this._meta$.asObservable().pipe(
      map(pagination => !!pagination && pagination.page < pagination.pageCount),
      takeUntil(this._unsubscribeAll),
    );
  }

  unsubscribeAll(): void {
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

}

@Injectable()
export class PaginateHttpService {

  constructor(private http: HttpClient) { }

  paginatorFactory<T>(url: string, filters?: Params): Paginator<T> {
    return new PaginatorImpl<T>(this.http, url, filters);
  }

}
