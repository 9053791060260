import { Component, Input } from "@angular/core";
import { User } from "../../../user/user";
import { UserService } from "../../../user/user.service";
import { map, take } from "rxjs/operators";

interface IMissingField {
  key: string;
  label: string;
  url: string[];
}

export const MISSING_FIELD_VISIBILITY = 'visibility';

const MAPPING_MISSING_FIELDS: IMissingField[] = [
  {key: 'disabled', label: 'Votre profil est désactivé', url: ['/user', 'profile', 'my-account']},
  {key: 'degreeCenter', label: 'La formation suivie', url: ['/user', 'profile', 'my-research']},
  {key: 'jobDesiredSlug', label: 'Le métier recherché', url: ['/user', 'profile', 'my-research']},
  {key: 'durationDesiredSlug', label: 'La durée de la formation recherchée', url: ['/user', 'profile', 'my-research']},
  {
    key: 'companySizeDesiredSlug',
    label: 'La taille de la structure pour la formation',
    url: ['/user', 'profile', 'my-research']
  }, {
    key: 'attitudes',
    label: 'Votre profil avec le Quiz ou SkillGym Attitudes',
    url: ['/user', 'profile', 'my-application']
  }, {
    key: 'badBirthDate',
    label: 'Votre âge ne vous permet pas d\'accéder aux offres',
    url: ['/user', 'profile', 'my-profile']
  }, {
    key: MISSING_FIELD_VISIBILITY,
    label: 'Votre profil n\'est pas visible par les employeurs',
    url: ['/user', 'profile', 'my-profile']
  }
];

const KNOWN_BUT_UNTRACKED: string[] = ['degreeLevel', 'degreeType'];

@Component({
  selector: 'anie-missing-fields',
  templateUrl: './missing-fields.component.html',
  styleUrls: ['./missing-fields.component.scss']
})

export class MissingFieldsComponent {

  @Input() complementaryFields: string[] = [];
  user: User;
  mappedMissingFields$ = this.userService.getUser$().pipe(
    take(1),
    map(u => this._getAllMissingFieldSlugs(u)),
    map(list => this._mapMissingFields(list)),
    map(list => list.length ? list : null) // use ngIf capability
  );

  constructor(private userService: UserService) {}

  private _getAllMissingFieldSlugs(user: User): string [] {
    let userFields = (user && user.candidate && user.candidate.missingFields) || [];

    if (user.disabled) {
        userFields = [ ...userFields, 'disabled'];
    }

    const complementaryFields = this.complementaryFields || [];
    return [...userFields, ...complementaryFields];
  }

  private _mapMissingFields(list: string[]): IMissingField[] {
    return list
      .map(key => {
        const correspondingObject = MAPPING_MISSING_FIELDS.find(m => m.key === key);
        if (!correspondingObject && KNOWN_BUT_UNTRACKED.indexOf(key) === -1) {
          console.error(`The missing field '${key}' does not have equivalent in mapping table.`);
        }
        return correspondingObject;
      })
      .filter(mf => !!mf); // remove field that was not mapped
  }

}
