import { Pipe, PipeTransform } from "@angular/core";
import { Referential, ReferentialsService } from "./referentials.service";
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs/operators';

@Pipe({
  name: 'trainingDurations'
})
export class TrainingDurationsPipe implements PipeTransform {

  referentials: Array<Referential> = [];
  referentials$: BehaviorSubject<Referential[]> = new BehaviorSubject(null);

  constructor(private referentialsService: ReferentialsService) {
    referentialsService.trainingDurations$.pipe(
      switchMap((res)=>{
        this.referentials = this.referentials.concat(res);
        return referentialsService.trainingDurationsPro$;
      }),
      tap((res2)=>{
        this.referentials = this.referentials.concat(res2);
        this.referentials$.next(this.referentials);
      })
    ).subscribe();
  }

  transform(slug: string): Observable<Referential> {
    return this.referentials$.pipe(
      filter((referentials)=>{
        return referentials && referentials.length > 0;
      }),
      map((referentials)=>{
        return referentials.find(r => r.slug === slug)
      })
    );
  }

}
