import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { NavigationEnd, Router } from "@angular/router";
import { combineLatest, concat, Observable, of } from "rxjs";
import { filter, map } from "rxjs/operators";
import { environment } from "../../../environments/environment";
import { UserService } from "../../user/user.service";

@Component({
  selector: 'anie-update-authorization-for-rgpd',
  templateUrl: './update-authorization-for-rgpd.component.html',
  styleUrls: ['./update-authorization-for-rgpd.component.scss']
})
export class UpdateAuthorizationForRgpdComponent implements OnInit {
  @ViewChild('rgpdDialog', { static: true }) rgpdDialog: TemplateRef<any>;

  showModal$: Observable<boolean>;
  rgpdForm: FormGroup;
  detailsCguURL: string;
  politicConfidentialityURL: string;
  dialogRef: any;
  updating: boolean;

  constructor(private fb: FormBuilder, private userService: UserService, private router: Router, private dialog: MatDialog) {}

  ngOnInit() {
    this.rgpdForm = this.fb.group({
      rgpdData: [false, Validators.requiredTrue],
      rgpdCondition: [false, Validators.requiredTrue]
    });

    // get an observable on all routes to know if the current route is whitelisted
    const initialRoute$ = of(this.router.url);
    const futurNewRoute$ = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map((event: NavigationEnd) => event.url)
    );
    const allRoutes$ = concat(initialRoute$, futurNewRoute$);
    const isWhiteListed$ = allRoutes$.pipe(map(path => this.isWhiteListed(path)));

    const mustValidateRGPD$ = this.userService.getUser$().pipe(map(u => u && u.emailConfirmed && (!u.rgpdCondition || !u.rgpdData)));
    this.showModal$ = combineLatest(isWhiteListed$, mustValidateRGPD$).pipe(map(([isWhite, mustValid]) => !isWhite && mustValid));
    this.showModal$.subscribe(showModal => {
      if (showModal) {
        this.open();
      }
    });
    this.detailsCguURL = environment.domain + '/detailsCGU';
    this.politicConfidentialityURL = environment.domain + '/politicConfidentiality';
  }

  validate(): void {
    if (this.rgpdForm.valid) {
      const clonedValue = Object.assign({}, this.rgpdForm.value);
      this.updating = true;
      this.userService.safeUpdateUserProfile$(clonedValue).subscribe(() => {
        this.dialogRef.close();
        this.updating = false;
      });
    }
  }

  isWhiteListed(path: string): boolean {
    const whiteListedUrls = ['/detailsCGU', '/politicConfidentiality', '/advancedPoliticConfidentiality'];
    for (const whiteListedUrl of whiteListedUrls) {
      if (path.indexOf(whiteListedUrl) === 0) {
        return true;
      }
    }
    return false;
  }

  open() {
    if (!this.dialogRef) {
      this.dialogRef = this.dialog.open(this.rgpdDialog, { disableClose: true });
    }
  }
}
