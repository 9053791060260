import { Component, HostListener, OnInit } from '@angular/core';
import { PopinAdminMaintenanceService } from '../popin-admin-maintenance/popin-admin-maintenance.service';
import { Router } from '@angular/router';
import { filter, first, tap } from 'rxjs/operators';

enum KonamiArea {
  HEADER = 'header',
  IMAGE = 'image',
  TEXT = 'text'
}

const konamiSerie = [
  KonamiArea.HEADER,
  KonamiArea.HEADER,
  KonamiArea.HEADER,
  KonamiArea.IMAGE,
  KonamiArea.IMAGE,
  KonamiArea.IMAGE,
  KonamiArea.TEXT,
  KonamiArea.TEXT,
  KonamiArea.TEXT
];

@Component({
  selector: 'anie-maintenance',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.scss']
})
export class MaintenanceComponent implements OnInit {
  konamiAreas = KonamiArea;
  currentSerie: KonamiArea[] = [];
  konamiOk = false;

  constructor(private popinAdminMaintenanceService: PopinAdminMaintenanceService, private router: Router) {}

  ngOnInit(): void {
    this.popinAdminMaintenanceService.showMaintenance$
      .pipe(
        filter(b => !b),
        first(),
        tap(() => this.router.navigate(['/']))
      )
      .subscribe();
  }

  @HostListener('document:click')
  konamiClick(event: MouseEvent, konamiArea?: KonamiArea): void {
    if (konamiArea) {
      this.currentSerie.push(konamiArea);
      event.stopPropagation();
      // check konami equality
      if (!this.konamiOk && this.currentSerie.length === konamiSerie.length) {
        this.konamiOk = true;
        for (let i = 0; i < konamiSerie.length; i++) {
          this.konamiOk = this.konamiOk && this.currentSerie[i] === konamiSerie[i];
        }
      }
    } else {
      this.currentSerie = [];
    }
  }

  mdp(event: Event): void {
    const content = (<HTMLInputElement>event.target).value;
    if (content === 'Jumpez comme vous êtes') {
      this.popinAdminMaintenanceService.disableMaintenance();
    }
  }
}
