import { Component, forwardRef, Input, OnDestroy, OnInit } from "@angular/core";
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: 'anie-password-form-control',
  templateUrl: './password-form-control.component.html',
  styleUrls: ['./password-form-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordFormControlComponent),
      multi: true
    }, {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PasswordFormControlComponent),
      multi: true,
    }
  ]
})
export class PasswordFormControlComponent implements OnInit, OnDestroy, ControlValueAccessor, Validator {

  unsubscribeAll$ = new Subject<void>();

  SPECIAL_CHARACTER_ASCII = '!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~';
  passwordForm: FormGroup;

  @Input() label = 'Mot de passe';

  // custom form control
  propagateChange = (_: any) => {};

  constructor(private fb: FormBuilder,
              private dialog: MatDialog) { }

  ngOnInit() {
    const requiredPasswordValidator = Validators.compose([
      Validators.required,
      this.validatePassword.bind(this)
    ]);
    this.passwordForm = this.fb.group({
      password: ['', requiredPasswordValidator],
      confirmed: ''
    }, {  validator: this.validatePasswordConfirmation });

    this.passwordForm.valueChanges
      .pipe(takeUntil(this.unsubscribeAll$))
      .subscribe(v => {
        const valueToPropagate = this.passwordForm.valid ? v.password : '';
        this.propagateChange(valueToPropagate);
      });
  }

  ngOnDestroy(): void {
    this.unsubscribeAll$.next();
    this.unsubscribeAll$.complete();
  }

  validatePassword(c: FormControl) {
    const hasLowercaseRegExp = /[a-z]/;
    const hasUppercaseRegExp = /[A-Z]/;
    const hasDigitRegExp = /\d/;
    const specialCharaEscaped = this.SPECIAL_CHARACTER_ASCII.replace(/[.?*+^$[\]\\(){}|-]/g, '\\$&');
    const hasSpecialCharacterRegExp = new RegExp(`[${specialCharaEscaped}]`);

    let hasOneInvalid = false;
    const errors: any = {};

    if (c.value.length < 6) {
      hasOneInvalid = true;
      errors.toShort = { valid: false };
    }
    if (!hasLowercaseRegExp.test(c.value)) {
      hasOneInvalid = true;
      errors.hasLowercase = { valid: false };
    }
    if (!hasUppercaseRegExp.test(c.value)) {
      hasOneInvalid = true;
      errors.hasUpperase = { valid: false };
    }
    if (!hasDigitRegExp.test(c.value)) {
      hasOneInvalid = true;
      errors.hasDigit = { valid: false };
    }
    if (!hasSpecialCharacterRegExp.test(c.value)) {
      hasOneInvalid = true;
      errors.hasSpecialCharacter = { valid: false };
    }

    return hasOneInvalid ? errors : null;
  }

  /**
   * Custom validator to check that password and confirmed one are equal
   * @param {FormGroup} group
   * @returns {null}
   */
  validatePasswordConfirmation(group: FormGroup) {
    const password = group.controls['password'];
    const confirmed = group.controls['confirmed'];

    if (password.value !== confirmed.value) { // this is the trick
      confirmed.setErrors({validatePasswordConfirmation: true});
    } else {
      const errors = confirmed.errors;
      if (errors && errors.validatePasswordConfirmation) {
        delete errors.validatePasswordConfirmation;

        // has other errors that we want to keep tracks of
        if (Object.keys(errors).length) {
          confirmed.setErrors(errors);
        } else {
          // if not null is the "no error" value (empty object doesn't work)
          confirmed.setErrors(null);
        }
      }
    }
  }

  isControlInvalid(formControlName: string): boolean {
    const formControl = this.passwordForm.get(formControlName);
    return formControl.touched && formControl.invalid;
  }

  getError(fieldName: string, errorName: string) {
    const formControl = this.passwordForm.get(fieldName);
    return formControl.invalid && formControl.errors[errorName];
  }

  writeValue(): void {
    // nothing to do unused touch property
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    // nothing to do unused touch property
  }

  validate(): ValidationErrors | null {
    if (this.passwordForm.valid) {
      return null;
    }
    return {notValid: true};
  }
}
