import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: 'filterBy'
})
export class FilterByPipe implements PipeTransform {

  transform(items: any[], field: string, value: string): any {
    if (!items) {
      return [];
    }
    return items.filter(item => item[field] === value );
  }

}
