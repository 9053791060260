import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, combineLatest, concat, from, Observable, of, Subject } from "rxjs";
import { AnieNotification } from "./notification";
import { UserService } from "../user/user.service";
import { concatMap, debounceTime, filter, first, map, mergeMap, pairwise, switchMap, takeUntil, tap } from "rxjs/operators";
import { environment } from "../../environments/environment";
import { ActivatedRoute } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireDatabase } from "@angular/fire/database";
import { User } from "firebase";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Platform } from "@ionic/angular";
import { FirebaseService } from "../shared/firebase.service";

export class InAppNotificationFilter {
  key: Symbol;
  predicate: (notif: AnieNotification) => boolean;
}

@Injectable()
export class NotificationsService {
  private unseenIds$ = new BehaviorSubject<number[]>(undefined);
  private inAppNotificationFilters: InAppNotificationFilter[] = [];
  private isNatif: boolean = this.platform.is('hybrid');

  constructor(
    private http: HttpClient,
    private userService: UserService,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private angularFireAuth: AngularFireAuth,
    private angularFireDatabase: AngularFireDatabase,
    private platform: Platform,
    private firebaseService: FirebaseService
  ) {}

  initInAppNotification(): void {
    this.angularFireAuth.user
      .pipe(
        switchMap(user => (user ? this.getUnreadNotificationList$(user.uid) : of([]))),

        // Share the formated value (~ list of still unseen notification ids) with the rest of the app
        tap((val: number[]) => this.unseenIds$.next(val)),

        pairwise(), // group the last 2 values to monitor evolutions
        tap(([previousNotifs, nextNotifs]) => this.manageNewNotification(previousNotifs, nextNotifs))
      )
      .subscribe();

    if (this.isNatif) this.firebaseService.manageCapacitorNotifPush();
  }

  private getUnreadNotificationList$(uid: string): Observable<number[]> {
    return this.firebaseService.canSubscribeFirebase$.pipe(
      switchMap(canAccess => {
        if (canAccess) {
          return this.angularFireDatabase.list<number>(`notifications/${uid}`).valueChanges();
        }
      })
    );
  }

  private manageNewNotification(previousNotifs, nextNotifs): void {
    const newNotifs = nextNotifs
      .filter(notifId => previousNotifs.indexOf(notifId) === -1)
      .map(id => this.http.get<AnieNotification>(`${environment.apiEntryPoint}/notifications/${id}`));

    concat(...newNotifs)
      .pipe(
        filter((notif: AnieNotification) => this.keepNotifFromContext(notif)),
        tap(notif => this.snackBar.open(notif.text, '', { duration: 2000, panelClass: ['snackbar', 'warning'] })),
        tap(notif => this._refreshUser(notif))
      )
      .subscribe();
  }

  private keepNotifFromContext(notif: AnieNotification): boolean {
    // if at least on of the predicate is false the the notification will be silence
    for (const fil of this.inAppNotificationFilters) {
      if (!fil.predicate(notif)) {
        return false;
      }
    }
    return true;
  }

  addFilterToInApp(fil: InAppNotificationFilter): void {
    this.inAppNotificationFilters.push(fil);
  }

  removeFilterToInApp(key: Symbol): void {
    this.inAppNotificationFilters = this.inAppNotificationFilters.filter(i => i.key !== key);
  }

  setNotificationToRead(notif: AnieNotification): Observable<AnieNotification> {
    return this.http.get<AnieNotification>(`${environment.apiEntryPoint}/notifications/${notif.id}/read`);
  }

  getNotifications$(): Observable<AnieNotification[]> {
    const notifications$ = this.http.get<AnieNotification[]>(environment.apiEntryPoint + '/notifications');
    const unseenIds$ = this.unseenIds$.pipe(
      filter(val => val !== undefined),
      first()
    );
    const uid$ = this.angularFireAuth.user.pipe(
      filter(userData => !!userData),
      first(),
      map((userData: User) => userData.uid)
    );

    // Observable.create allow to add tear down function to stop sub process
    // here sub processes correspond to load new notifications on the fly
    return new Observable((observer: any) => {
      // allow to unsubscribe sub-processes on tear down (see the end of the function)
      const unsubscribeAll$ = new Subject<void>();

      // notifs represent what will be expose by the service
      const notifs$ = new BehaviorSubject<AnieNotification[]>(undefined);
      notifs$.pipe(filter(val => val !== undefined)).subscribe(observer); // <= passing the observer to the subscribe make the parent 'Observable.create' equivalent to notifs$

      // all sub-processes are started here
      // initiate and keep notifications up to date if new one's are fired
      combineLatest(notifications$, unseenIds$, uid$)
        .pipe(
          takeUntil(unsubscribeAll$),
          map(([notifs, unseenIds, uid]) => this.mergeNotifAndUnseen(notifs, unseenIds, uid)),
          tap(([notifs]) => notifs$.next(notifs)),
          tap(([notifs, unseenIds, uid]) => this.dynamicallyReloadNewNotification(uid, notifs$, unsubscribeAll$))
        )
        .subscribe();

      // tear-down/unsubscribe function
      return () => {
        unsubscribeAll$.next();
        unsubscribeAll$.complete();
        notifs$.complete();
      };
    });
  }

  private nbAppointmentsUnseen$() {
    return this.getNotifications$()
      .pipe(
        map(list => (list && list.length ? list : null)), // using null instead of empty list allow to use falsy comparison in ngIf
        map(p => p.filter(r => r.slug === 'candidate.interviewscheduled')),
        map(p => p.filter(r => !r.hasBeenRead && !r.hasBeenSeen)),
        map(e => e.length)
      )
  }

  getAppointmentsUnseen$() {
    return this.nbAppointmentsUnseen$();
  }

  private mergeNotifAndUnseen(notifications: AnieNotification[], unseenIds: number[], uid: string): [AnieNotification[], number[], string] {
    // remove all used id
    // if the suppression was made directly, can be confusing for the user
    setTimeout(() => {
      this.firebaseService.canSubscribeFirebase$
        .pipe(
          tap(canAccess => {
            if (canAccess) {
              unseenIds.forEach(id => this.angularFireDatabase.object(`notifications/${uid}/${id}`).remove());
            }
          })
        )
        .subscribe();
    }, 2000);

    const mappedNotifs = notifications.map(n => ({ ...n, hasBeenSeen: unseenIds.indexOf(n.id) === -1 }));
    return [mappedNotifs, unseenIds, uid];
  }

  private dynamicallyReloadNewNotification(uid: string, notifs$: BehaviorSubject<AnieNotification[]>, unsubscribeAll$: Observable<void>): void {
    this.firebaseService.canSubscribeFirebase$
      .pipe(
        tap(canAccess => {
          if (canAccess) {
            this.angularFireDatabase
              .list<number>(`notifications/${uid}`)
              .valueChanges()
              .pipe(
                pairwise(),
                map(([previousId, newIds]) => extractNewIds(previousId, newIds)),
                mergeMap(ids => from(ids)),
                concatMap(addedId => this.http.get<AnieNotification>(`${environment.apiEntryPoint}/notifications/${addedId}`)),
                tap((addedNotif: AnieNotification) => notifs$.next([addedNotif, ...notifs$.getValue()])),
                debounceTime(2000), // if the suppression was made directly, can be confusing for the user
                tap((addedNotif: AnieNotification) => this.angularFireDatabase.object(`notifications/${uid}/${addedNotif.id}`).remove()),
                takeUntil(unsubscribeAll$)
              )
              .subscribe();
          }
        })
      )
      .subscribe();

    function extractNewIds(previousList: number[], newList: number[]): number[] {
      return newList.filter(id => previousList.indexOf(id) === -1);
    }
  }

  nbUnseenNotifications$(): Observable<number> {
    return this.unseenIds$.pipe(
      filter(val => val !== undefined),
      map(arr => arr.length)
    );
  }

  private _refreshUser(notif: AnieNotification): void {
    if (['admin.user.enable', 'admin.user.disable'].indexOf(notif.slug) !== -1) {
      this.userService
        .refreshConnectedUser$()
        .pipe(first())
        .subscribe();
    }
  }
}
