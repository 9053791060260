import { Component, OnDestroy, OnInit, Renderer2 } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";

@Component({
  selector: 'anie-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  loginForm: FormGroup;

  constructor(private renderer: Renderer2,
              private fb: FormBuilder,
  ) {
  }


  ngOnInit() {
    this.loginForm = this.fb.group({
      email: '',
      password: ''
    });
    this.renderer.addClass(document.body, 'home-login');
  }

  ngOnDestroy(): void {
    this.renderer.removeClass(document.body, 'home-login');
  }
}
