import { Component, ElementRef, OnInit } from "@angular/core";

declare let require: any;
const lottie: any = require('lottie-web/build/player/lottie.js');

@Component({
  selector: 'anie-lottie-anim',
  templateUrl: './lottie-anim.component.html',
  styleUrls: ['./lottie-anim.component.scss']
})
export class LottieAnimComponent implements OnInit {

  anim: any;

  constructor(
    private elementRef: ElementRef,
  ) { }

  ngOnInit() {
    this.anim = lottie.loadAnimation({
      container: this.elementRef.nativeElement,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: `./assets/lottie-animation/animweb_burger_02.json`,
    });
  }

  play(from: string, to: string) {
    const frameFrom = this.anim.animationData.markers.find(m => m.cm === from).tm;
    const frameTo = this.anim.animationData.markers.find(m => m.cm === to).tm;
    this.anim.playSegments([frameFrom, frameTo], true);
  }

  setFrame(markerName: string) {
    if (this.anim.animationData && this.anim.animationData.markers) {
      // already loaded
      const marker = this.anim.animationData.markers.find(m => m.cm === markerName);
      this.anim.goToAndStop(marker.tm, true);
    } else {
      // waiting on loading
      this.anim.addEventListener('DOMLoaded', () => {
        const marker = this.anim.animationData.markers.find(m => m.cm === markerName);
        this.anim.goToAndStop(marker.tm, true);
      });
    }
  }

}
