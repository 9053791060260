import { Component } from "@angular/core";
import { environment } from "../../../environments/environment";

@Component({
  selector: 'app-not-connected',
  templateUrl: './not-connected.component.html',
  styleUrls: ['./not-connected.component.scss']
})
export class NotConnectedComponent {
  constructor() {}

  isNative = !!environment.domain;

  reloadApp(){
    if(this.isNative){
      document.location.href = 'index.html';
    }
    else {
      window.location.replace(environment.domain + "/anie");
    }
  }
}
