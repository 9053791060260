import { Inject, Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Observable, of, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Router } from "@angular/router";
import { LOCAL_STORAGE_TARGET_URL_ID } from "./auth.guard";
import { WINDOW_ID } from "../shared/injectorTokens";
import { StatsService } from "../shared/stats.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Platform } from "@ionic/angular";
import { HTTP } from "@ionic-native/http/ngx";

@Injectable()
export class TokenInterceptorService implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private statsService: StatsService,
    private router: Router,
    private platform: Platform,
    private httpNative: HTTP,
    private snackBar: MatSnackBar,
    @Inject(WINDOW_ID) private window: Window
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    const currentToken = this.authService.getToken();
    if (currentToken && this.authService.isTokenNotExpired()) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${currentToken}`
        }
      });

    }

    // send the newly created request
    return next.handle(request).pipe(
      map(event => this.extractDataAndStoreToken(event)),
      catchError(err => this.handleErrors(err))
    );
  }

  extractDataAndStoreToken(event: HttpEvent<{ data: any; token: string }>): HttpEvent<{ data: any; token: string }> {
    /* possibility to store failed request for a retry manager */
    if (event instanceof HttpResponse && event.body && event.body.token !== undefined) {
      const { token, data } = event.body;
      if (token !== null && token !== '') {
        this.authService.setToken(token);
      } else if (token === '') {
        this.authService.setToken(null);
        this.router.navigate(['/anie']);
      }
      event = event.clone({ body: data });
    }
    return event;
  }

  handleErrors(err: HttpErrorResponse) {
    //console.log("ERREUR HTTP INTERCEPTOR", err);
    if (err.status === 401) {
      const currentUrl = this.router.url;
      localStorage.setItem(LOCAL_STORAGE_TARGET_URL_ID, currentUrl);
      this.router.navigate(['/home']);
      return of(null);
    } else if (err.status === 418) {
      const message = !/teapot/.test(err.statusText) ? err.statusText : err.error && err.error.statusText;
      this.snackBar.open(message, '', { duration: 2000, panelClass: ['snackbar', 'error'] });
    } else if (err.status === 503) {
      this.window.location.reload();
    } else if (err.status >= 500) {
      this.snackBar.open('Oups ... Désolé une erreur imprévue est survenue, veuillez réessayez plus tard.', '', { duration: 2000, panelClass: ['snackbar', 'error'] });
    }
    this.statsService.sendStat({
      message: 'error HTTP',
      err
    });
    return throwError(err);
  }
}
