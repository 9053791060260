import { Directive, ElementRef, HostListener } from "@angular/core";
import { environment } from "../../../environments/environment";
import { goTo } from "../utils/navigation-utils";

@Directive({
  selector: '[anieExternalLink]'
})
export class ExternalLinkDirective {

  constructor(private elementRef: ElementRef) {}

  @HostListener('click', ['$event'])
  openExternalLink(event: Event) {
    // there is a domain => it's a native app => it's require to override external links
    if (!!environment.domain) {
      event.preventDefault();
      const linkElement = <HTMLLinkElement>this.elementRef.nativeElement;
      goTo(linkElement.href);
    }
    // else default behavior is adapted
  }

}
