import { Observable } from "rxjs";
import { shareReplay } from "rxjs/operators";
import { environment } from "../../../environments/environment";

export const deviceReady$ = new Observable(observer => {
  if (!environment.domain) {
    observer.next();
    observer.complete();
  } else {
    window.document.addEventListener('deviceready', () => {
      observer.next();
      observer.complete();
    });
  }
}).pipe(
  shareReplay(),
);
