import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Article, ArticleTheme, Chapter, rerouteCategoryAll, ThemeLabel, urlNavigatorArticles } from '../../articles/article';
import { ArticleService } from '../../articles/article.service';
import { BehaviorSubject, combineLatest, Observable, of, Subject } from 'rxjs';
import { CandidateType, User, UserType } from '../../user/user';
import { map, mergeMap, take, takeUntil, tap } from 'rxjs/operators';
import { UserService } from '../../user/user.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'anie-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit, OnDestroy {
  UserType = UserType;
  CandidateType = CandidateType;
  ThemeLabel = ThemeLabel;
  userType$ = new BehaviorSubject<any>(null);
  unsubscribeAll$ = new Subject<void>();

  candidateFaq: Chapter[] = [];
  candidateProFaq: Chapter[] = [];
  recruiterFaq: Chapter[] = [];
  recruiterProFaq: Chapter[] = [];
  troisiemeFaq: Chapter[] = [];
  recruiterTroisiemeFaq: Chapter[] = [];

  displayedFaq: Chapter[];
  displaySelector: boolean = true;
  displaySwitchForRecruiter: boolean = true;
  previousCandidateType = [];

  user: User;

  valueUserType: string;
  valueCandidateType: string;

  @ViewChild('themeSection', { static: true }) themeSection: ElementRef;
  @ViewChild('questionSection', { static: true }) questionSection: ElementRef;

  selectedThemeIndex: number;

  get selectedTheme(): Chapter {
    if (!(this.selectedThemeIndex >= 0) || !this.displayedFaq) {
      return null;
    }
    return this.displayedFaq[this.selectedThemeIndex];
  }

  selectedQuestionIndex: number;

  get selectedQuestion(): Article {
    if (!this.selectedTheme || !(this.selectedThemeIndex >= 0) || !(this.selectedQuestionIndex >= 0) || !this.displayedFaq) {
      return null;
    }
    return this.selectedTheme.articles[this.selectedQuestionIndex];
  }

  constructor(private userService: UserService, private articleService: ArticleService, private router: Router, private route: ActivatedRoute) {}

  ngOnInit() {
    this.userService
      .getUser$()
      .pipe(
        take(1),
        tap((user: User) => {
          this.user = user;
          if (user?.profile === UserType.RECRUITER) {
            urlNavigatorArticles(this.router, this.route, UserType.RECRUITER, CandidateType.APPRENTI);
          }
          if (user?.profile === UserType.CANDIDATE && user.candidate.type === CandidateType.APPRENTI) {
            urlNavigatorArticles(this.router, this.route, UserType.CANDIDATE, CandidateType.APPRENTI);
          }
          if (user?.profile === UserType.CANDIDATE && user.candidate.type === CandidateType.FORM_PRO) {
            urlNavigatorArticles(this.router, this.route, UserType.CANDIDATE, CandidateType.FORM_PRO);
          }
          if (user?.profile === UserType.CANDIDATE && user.candidate.type === CandidateType.TROISIEME) {
            urlNavigatorArticles(this.router, this.route, UserType.CANDIDATE, CandidateType.TROISIEME);
          }
          if (!user) {
            urlNavigatorArticles(this.router, this.route, UserType.CANDIDATE, CandidateType.APPRENTI);
          }
        }),
        mergeMap(u => this.getCorrespondingFaq(u)),
        tap(([candidateFaq, candidateProFaq, recruiterFaq, recruiterProFaq, troisiemeFaq, recruiterTroisiemeChapter, user]) => {
          this.candidateFaq = candidateFaq;
          this.candidateProFaq = candidateProFaq;
          this.recruiterFaq = recruiterFaq;
          this.recruiterProFaq = recruiterProFaq;
          this.troisiemeFaq = troisiemeFaq;
          this.recruiterTroisiemeFaq = recruiterTroisiemeChapter;

          const oldValue = this.userType$.getValue();
          this.userType$.next(oldValue);
        })
      )
      .subscribe();

    this.route.queryParams
      .pipe(
        takeUntil(this.unsubscribeAll$),
        tap(params => (this.selectedThemeIndex = params.theme > 0 ? params.theme : 0)),
        tap(params => (this.selectedQuestionIndex = params.question)),
        tap(params => this.scroll(params)),
        tap(params => {
          this.valueUserType = params['user-type'];
          this.valueCandidateType = params['candidate-type'];
          this.previousCandidateType.push(this.valueCandidateType);
        }),
        map(params => [params['user-type'], params['candidate-type'], params['theme'], params['question']]),
        tap(userType => this.userType$.next(userType))
      )
      .subscribe();

    // Change displayed chapter on userType
    this.userType$.pipe().subscribe(data => {
      // if (this.previousCandidateType && data[1] !== this.previousCandidateType[this.previousCandidateType.length - 2]) {
      //   rerouteCategoryAll(this.router, this.route, { theme: 0, question: -1 });
      //   // {theme : 0} pour pointer sur une première demande
      // }

      if (data[0] === UserType.CANDIDATE) {
        switch (data[1]) {
          case CandidateType.ALL:
            this.displayedFaq = this.candidateFaq.concat(this.candidateProFaq, this.troisiemeFaq);
            break;
          case CandidateType.APPRENTI:
            this.displayedFaq = this.candidateFaq;
            break;
          case CandidateType.FORM_PRO:
            this.displayedFaq = this.candidateProFaq;
            break;
          case CandidateType.TROISIEME:
            this.displayedFaq = this.troisiemeFaq;
            break;
        }
      } else if (data[0] === UserType.RECRUITER) {
        switch (data[1]) {
          case CandidateType.ALL:
            this.displayedFaq = this.recruiterFaq.concat(this.recruiterProFaq, this.recruiterTroisiemeFaq);
            break;
          case CandidateType.APPRENTI:
            this.displayedFaq = this.recruiterFaq;
            break;
          case CandidateType.FORM_PRO:
            this.displayedFaq = this.recruiterProFaq;
            break;
          case CandidateType.TROISIEME:
            this.displayedFaq = this.recruiterTroisiemeFaq;
            break;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribeAll$.next();
    this.unsubscribeAll$.complete();
    this.userType$.complete();
  }

  getCorrespondingFaq(user: User): Observable<[Chapter[], Chapter[], Chapter[], Chapter[], Chapter[], Chapter[], User]> {
    const candidateFaq$ = this.articleService.getChapters$(ArticleTheme.CANDIDATE_FAQ, true);

    const candidateProFaq$ = this.articleService.getChapters$(ArticleTheme.CANDIDATE_PRO_FAQ, true);

    const recruiterFaq$ = this.articleService.getChapters$(ArticleTheme.RECRUITER_FAQ, true);

    const recruiterProFaq$ = this.articleService.getChapters$(ArticleTheme.RECRUITER_PRO_FAQ, true);

    const troisiemeFaq$ = this.articleService.getChapters$(ArticleTheme.TROISIEME_FAQ, true);

    const recruiterTroisiemeFaq$ = this.articleService.getChapters$(ArticleTheme.RECRUITER_TROISIEME_FAQ, true);

    return combineLatest(candidateFaq$, candidateProFaq$, recruiterFaq$, recruiterProFaq$, troisiemeFaq$, recruiterTroisiemeFaq$, of(user));
  }

  scroll(params: any): void {
    let elementToScrollTo;
    if (params.theme && params.theme !== '-1') {
      elementToScrollTo = this.themeSection.nativeElement;
    }
    if (params.question && params.question !== '-1') {
      elementToScrollTo = this.questionSection.nativeElement;
    }
    if (elementToScrollTo) {
      setTimeout(() => {
        elementToScrollTo.scrollIntoView({ behavior: 'smooth' });
      });
    }
  }
}
