/**
 APIs to expose :
 * GET /api/chapters/:theme
 (theme: ArticleTheme = recruiterArticle | candidateArticle | pedagogicArticle)
 Response: { data: Chapter[], token: string } (where Article[] don't have optional field)
 * GET /api/articles/:articleSlug
 (articleSlug the slug of the targeted Article)
 Response: { data: Article, token: string }
 */

import { ActivatedRoute, Router } from "@angular/router";
import { CandidateType, UserType } from "../user/user";

export enum ArticleTheme {
  RECRUITER_ARTICLE = 'recruiterArticle',
  RECRUITER_PRO_ARTICLE = 'recruiterProArticle',
  RECRUITER_TROISIEME = 'recruiterTroisiemeArticle',

  CANDIDATE_ARTICLE = 'candidateArticle',
  CANDIDATE_PRO_ARTICLE = 'candidateProArticle',
  TROISIEME_ARTICLE = 'troisiemeArticle',

  PEDAGOGIC_ARTICLE = 'pedagogicArticle',
  PEDAGOGIC_PRO_ARTICLE = 'pedagogicProArticle',
  PEDAGOGIC_TROISIEME_ARTICLE = 'pedagogicTroisiemeArticle',

  RECRUITER_FAQ = 'recruiterFaq',
  RECRUITER_PRO_FAQ = 'recruiterProFaq',

  CANDIDATE_FAQ = 'candidateFaq',
  CANDIDATE_PRO_FAQ = 'candidateProFaq',

  TROISIEME_FAQ = 'troisiemeFaq',
  RECRUITER_TROISIEME_FAQ = 'recruiterTroisiemeFaq'
}

export enum ThemeLabel {
  CANDIDATE = 'Candidats',
  RECRUITER = 'Employeur',
  ALL = 'TOUS',
  APPRENTI = 'Apprentissage',
  FORM_PRO = 'Formation professionnelle',
  TROISIEME = 'Stages 3e'
}

export class Chapter {
  id: number;
  title: string;
  position: number;
  themes?: Themes[];
  articles: Article[];
}

export class Themes {
  chapterId?: number;
  theme?: string;
}

export class Article {
  slug: string;
  title: string;
  subtitle: string;
  image: string; // name or relative path from baseRef or absolute URL ???
  mediaType: MediaType;
  mediaUrl?: string;
  content?: string; // MarkDown file
  position: number;
  modified?: string;
}

export class FrontArticle extends Article {
  chapterName?: string;
  themes?: Themes[];
}

export enum MediaType {
  AUDIO = 'audio',
  VIDEO = 'video',
  GAME = 'game'
}

export class ParamsArticles {
  chapter?: string;
  courses?: string;
  ['candidate-type']?: string;
  ['user-type']?: string;
}

/*
 * Permet de revenir sur le filtre TOUS indépendemment du userType / candidateType
 * */
export function rerouteCategoryAll(router: Router, route: ActivatedRoute, queryParams: {}): void {
  router.navigate([], {
    relativeTo: route,
    queryParams: queryParams,
    queryParamsHandling: 'merge'
  });
}

/**
 * Permet d'aller à l'url choisie en fonction du compte utilisateur / visiteur en fonction de userType & candidateType
 * */
export function urlNavigatorArticles(router: Router, route: ActivatedRoute, userType?: UserType, candidateType?: CandidateType) {
  router.navigate([], {
    relativeTo: route,
    queryParams: { ['user-type']: userType, ['candidate-type']: candidateType },
    queryParamsHandling: 'merge'
  });
}
