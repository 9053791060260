import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";

@Injectable()
export class TestErrorService {

  constructor(private http: HttpClient) { }

  getError(code: number): any {
    return this.http.get(`${environment.apiEntryPoint}/error/${code}`).subscribe();
  }

}
