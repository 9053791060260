import { Component, OnInit } from "@angular/core";
import { Developer, DeveloperService } from "./developer.service";
import { combineLatest, Observable } from "rxjs";
import { FormBuilder, FormGroup } from "@angular/forms";
import { debounceTime, distinctUntilChanged, map, startWith } from "rxjs/operators";
import { accentFolding } from "../../shared/utils/string-utils";

@Component({
  selector: 'anie-developer',
  templateUrl: './developer.component.html',
  styleUrls: ['./developer.component.scss']
})
export class DeveloperComponent implements OnInit {

  developers$: Observable<Developer[]>;

  devForm: FormGroup;

  constructor(private developerService: DeveloperService,
              private fb: FormBuilder) { }

  ngOnInit() {
    this.devForm = this.fb.group({ search: '' });
    const searchValue$ = this.devForm.get('search').valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      distinctUntilChanged(),
      map(val => accentFolding(val)),
    );

    const devList$ = this.developerService.developers$.pipe(
      map(devList => devList.map(dev => {
        const cloned = {
          territory: dev.territory.map(str => accentFolding(str)),
          sector: accentFolding(dev.sector),
          name: dev.name.map(str => accentFolding(str)),
          contact: dev.contact.map(str => accentFolding(str)),
        };
        return {original: dev, folded: cloned};
      }))
    );

    this.developers$ = combineLatest(searchValue$, devList$).pipe(
      map(([searchValue, devList]) => {
        if (!searchValue || !devList) {
          return devList;
        }
        return devList.filter(dev => {
          const matchTerritory = !!dev.folded.territory.find(t => t.includes(searchValue));
          if (matchTerritory) {
            return true;
          }
          const matchName = !!dev.folded.name.find(n => n.includes(searchValue));
          if (matchName) {
            return true;
          }

          return dev.folded.sector.includes(searchValue);
        });
      }),
      map(devList => devList.map(dev => dev.original)),
    );
  }

}
