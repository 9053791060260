import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs";
import { Recruiter } from "../user/user";
import { Interview } from "../match/interview";
import { Page } from '../shared/utils/http/page';

@Injectable()
export class TroisiemesService {

  constructor(private http: HttpClient) {
  }

  createInterview(recruiterId: number): Observable<Interview> {
    const body = { recruiterId: recruiterId };
    const headers = new HttpHeaders({ 'Accept': 'application/json' });
    return this.http.post<Interview>(`${environment.apiEntryPoint}/interviews/3e/create`, body, {headers});
  }

  getAvailableEntreprises(params: any): Observable<Page<Recruiter>> {
    return this.http.post<Page<Recruiter>>(environment.apiEntryPoint + '/interviews/3e/getAvailableEntreprises', params);
  }

  getContacts(status: Array<string>): Observable<Array<Interview>> {
    const body = { status: status };
    return this.http.post<Array<Interview>>(`${environment.apiEntryPoint}/interviews/3e/getContacts`, body);
  }

  getContact(candidateId: number, recruiterId: number): Observable<Interview> {
    const body = { candidateId: candidateId, recruiterId: recruiterId };
    return this.http.post<Interview>(`${environment.apiEntryPoint}/interviews/3e/getContact`, body);
  }

  getEntreprise(recruiterId: number): Observable<Recruiter> {
    const body = { recruiterId: recruiterId };
    return this.http.post<Recruiter>(`${environment.apiEntryPoint}/interviews/3e/getRecruiter`, body);
  }

  cancelContact(recruiterId: number): Observable<Interview> {
    const body = { recruiterId: recruiterId };
    return this.http.post<Interview>(`${environment.apiEntryPoint}/interviews/3e/cancelContact`, body);
  }

  cancelInternship(recruiterId: number): Observable<Interview> {
    const body = { recruiterId: recruiterId };
    return this.http.post<Interview>(`${environment.apiEntryPoint}/interviews/3e/cancelInternship`, body);
  }

  acceptInternship(recruiterId: number): Observable<Interview> {
    const body = { recruiterId: recruiterId };
    const headers = new HttpHeaders({ 'Accept': 'application/json' });
    return this.http.post<Interview>(`${environment.apiEntryPoint}/interviews/3e/acceptInternship`, body, {headers});
  }

  refuseInternship(recruiterId: number): Observable<Interview> {
    const body = { recruiterId: recruiterId };
    return this.http.post<Interview>(`${environment.apiEntryPoint}/interviews/3e/refuseInternship`, body);
  }


}
