import { Pipe, PipeTransform } from "@angular/core";
import { GenderType } from "../../../user/user";

export enum DurationUnit {
  WEEK = 'week',
  MONTH = 'month',
  YEAR = 'year',
}

@Pipe({
  name: 'durationUnit'
})
export class DurationUnitPipe implements PipeTransform {

  /**
   *
   * @param {DurationUnit} value
   * @param {number} num allow pluralization
   * @param {GenderType} gender
   * @returns {string}
   */
  transform(value: DurationUnit, num: number, gender: GenderType = GenderType.MALE): string {
    const plural = num > 1 ? 's' : '';
    switch (value) {
      case DurationUnit.WEEK:
        return 'semaine' + plural;
      case DurationUnit.MONTH:
        return 'mois';
      case DurationUnit.YEAR:
        const yearLabel = gender === GenderType.MALE ? 'an' : 'année';
        return yearLabel + plural;
      default:
        console.error(`${value} doesn't exist`);
    }
    return null;
  }

}
