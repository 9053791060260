import { Injectable, NgZone } from "@angular/core";
import { filter } from "rxjs/operators";
import { NavigationEnd, Router } from "@angular/router";
import { Platform } from "@ionic/angular";
import { Plugins } from "@capacitor/core";
import { MatDialog } from "@angular/material/dialog";

@Injectable({
  providedIn: 'root'
})
export class HistoryService {
  constructor(private router: Router,
              private platform: Platform,
              private ngZone: NgZone,
              private dialog: MatDialog) {}

  history = [];

  public nativeHistoryBack(firstUrl) {
    this.history = [...this.history, firstUrl];
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((urlAfterRedirect: NavigationEnd) => {
      this.history = [...this.history, urlAfterRedirect.urlAfterRedirects];
    });
  }

  public back() {
    this.router.navigateByUrl(this.getPreviousUrl());
    // Oui, 2 fois, et alors ? DO YOU HAVE A PROBLEM BRO ? WANT TO FIGHT ? Tank Stan Marsh...
    this.history.splice(-1, 1);
    this.history.splice(-1, 1);
  }

  public backWithoutNavigate() {
    // Oui, 2 fois, et alors ? DO YOU HAVE A PROBLEM BRO ? WANT TO FIGHT ? Tank Stan Marsh...
    this.history.splice(-1, 1);
    this.history.splice(-1, 1);
  }

  public getPreviousUrl(): string {
    return this.history[this.history.length - 2] || '/anie';
  }

  // Android Hardware Button back
  public hardwareAndroidBackButton() {
    Plugins.App.addListener('backButton',  (state) => {
      this.ngZone.run( () => {
        let nbModals = this.dialog.openDialogs.length;
        if(nbModals > 0){
          this.dialog.closeAll();
        }
        else if (this.router.url === '/anie' && this.history.length <= 1) {
          navigator['app'].exitApp();
        } else {
          this.back();
        }
      });

    });
  }

  // Exit ANIE with Android Harware Button
  public exitApp() {
    if (this.router.url === '/anie' && this.history.length === 0) {
      navigator['app'].exitApp();
    }
  }
}
