import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: 'anie-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @ViewChild('questionDialog', { static: true }) questionDialog: TemplateRef<any>;
  dialogRef: any;

  validate: boolean = false;

  @Input() validationLabel = 'Valider';
  @Output() validated = new EventEmitter<boolean>();

  constructor(private dialog: MatDialog) {}

  ngOnInit() {
    this.validate = false;
    this.dialogRef = this.dialog.open(this.questionDialog);
    this.dialogRef.afterClosed().subscribe(result => {
      this.validated.emit(this.validate);
    });
  }

  close() {
    this.dialogRef.close();
  }

  yes(): void {
    this.validate = true;
    this.close();
  }
}
