import { Component, Inject, Input, OnChanges } from "@angular/core";
import { Meta } from "@angular/platform-browser";
import { WINDOW_ID } from "../../injectorTokens";
import { environment } from "../../../../environments/environment";
import { Router } from "@angular/router";

export class OpenGraph {
  title: string;
  image: string;
  description: string;
  audio?: string;
  video?: string;
}

@Component({
  selector: 'anie-social-networks',
  templateUrl: './social-networks.component.html',
  styleUrls: ['./social-networks.component.scss']
})
export class SocialNetworksComponent implements OnChanges {
  @Input() openGraphData: OpenGraph;
  @Input() orientation?: string = 'HORIZONTAL'; /* Valeurs possibles : "HORIZONTAL" ou "VERTICAL" */

  get domain(): string {
    // web env have empty string as domain
    return environment.domain || this.window.location.origin;
  }

  get path(): string {
    const splittedPath = this.router.url.split('?');
    return splittedPath.length ? splittedPath[0] : '';
  }

  get articleUrl(): string {
    if (!this.domain.startsWith('https://anie.laregion/')) {
      if (this.path.startsWith('/3e-internship')) {
        return 'https://anie.laregion.fr';
      } else {
        return 'https://anie.laregion.fr' + this.path;
      }
    }
    return this.domain + this.path;
  }

  get facebookSharedUrl(): string {
    // Il semblerait que ce soit interdit par FB de mettre un message prefilled
    return (
      'https://www.facebook.com/sharer/sharer.php?u=' +
      encodeURIComponent(this.articleUrl) +
      '&name=' +
      escapeHTMLAndEncodeURI((this.openGraphData && this.openGraphData.title) || '') +
      '&description=' +
      escapeHTMLAndEncodeURI((this.openGraphData && this.openGraphData.description) || '')
    );
  }

  get twitterSharedUrl(): string {
    // return 'https://twitter.com/intent/tweet?text=' + encodeURIComponent(this.articleUrl) + ' ' + ((this.openGraphData && this.openGraphData.description) || ''); // works
    return 'https://twitter.com/intent/tweet?text=' + encodeURIComponent(this.articleUrl);
  }

  get linkedInSharedUrl(): string {
    // Pas possible message prefilled avec cette méthode, il faudrait passer par l'api v2 de LinkedIn, erreur de CORS en front donc passage par le back
    return (
      'https://www.linkedin.com/shareArticle?mini=true' +
      '&url=' +
      encodeURIComponent(this.articleUrl) +
      '&title=' +
      escapeHTMLAndEncodeURI((this.openGraphData && this.openGraphData.title) || '') +
      '&summary=' +
      escapeHTMLAndEncodeURI((this.openGraphData && this.openGraphData.description) || '')
    );
  }

  constructor(private meta: Meta, private router: Router, @Inject(WINDOW_ID) private window: Window) {}

  ngOnChanges() {
    if (this.openGraphData) {
      let domain = (this.path.startsWith('/3e-internship') || this.path.startsWith('/match/interview')) ? 'https://anie.laregion.fr/' : this.domain;
      const meta = [
        { property: 'og:title', content: escapeHTML(this.openGraphData.title) },
        { property: 'og:site_name', content: 'Anie' },
        { property: 'og:image', content: domain + this.openGraphData.image },
        { property: 'og:image:width', content: '600' },
        { property: 'og:image:height', content: '400' },
        { property: 'og:url', content: this.articleUrl },
        { property: 'og:description', content: escapeHTML(this.openGraphData.description) },
        { property: 'og:locale', content: 'fr_FR' },
      ];

      if (this.openGraphData.video) {
        meta.push({ property: 'og:type', content: 'video.other' }, { property: 'og:video', content: this.domain + this.openGraphData.video });
      } else if (this.openGraphData.audio) {
        meta.push({ property: 'og:type', content: 'video.other' }, { property: 'og:audio', content: this.domain + this.openGraphData.audio });
      } else {
        meta.push({ property: 'og:type', content: 'article' });
      }
      meta.forEach(elem => {
        if (this.meta.getTag(`property="${elem.property}"`)) {
          this.meta.updateTag(elem, `property="${elem.property}"`);
        } else {
          this.meta.addTag(elem);
        }
      });
      this.meta.addTags(meta);
    }
  }
}

function escapeHTML(s: string): string {
  return s.replace(/&nbsp;/gm, '\xa0');
}

function escapeHTMLAndEncodeURI(s: string): string {
  return encodeURIComponent(escapeHTML(s));
}
