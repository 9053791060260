import { Injectable, NgZone } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Router } from "@angular/router";
import { environment } from "../environments/environment";

export enum NetworkValues {
  ONLINE = 'online',
  OFFLINE = 'offline'
}

@Injectable({
  providedIn: 'root'
})
export class NetworkService {
  status = new BehaviorSubject<NetworkValues>(NetworkValues.ONLINE);
  private realTimeStatus = NetworkValues.ONLINE;

  isNative = !!environment.domain;

  constructor(private ngZone: NgZone,
              private router: Router) {}

  handleNetworkChange(network: NetworkValues, force?: boolean) {
    this.realTimeStatus = network;
    if(force){
      if(network == NetworkValues.OFFLINE  && this.status.getValue() != network){
        this.status.next(network);
        this.router.navigateByUrl('notConnected');
      }
    }
    else {
      if(network == NetworkValues.OFFLINE){
        //Ici on temporise le fait de passer en mode Offline pour exclure les microcoupures internet et changement de réseau mobile
        setTimeout(() => {
          if(this.realTimeStatus == NetworkValues.OFFLINE && this.status.getValue() != network){
            //On ne passe en mode offline que si on a une coupure internet supérieure à 2 secondes
            this.status.next(network);
            this.router.navigateByUrl('notConnected');
          }
        }, 2000);
      }
      else if(network == NetworkValues.ONLINE && this.status.getValue() != network){
        this.status.next(network);
      }
    }
  }

  initNetworkWatch(){
    if(!navigator.onLine) {
      this.handleNetworkChange(NetworkValues.OFFLINE, true);
    }
    addEventListener('online', () => {
      this.ngZone.run(() => {
        this.handleNetworkChange(NetworkValues.ONLINE);
      });
    });
    addEventListener('offline', () => {
      this.ngZone.run(() => {
        this.handleNetworkChange(NetworkValues.OFFLINE)
      });
    });
  }
}
