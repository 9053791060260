import { ChangeDetectorRef, Component, forwardRef, OnDestroy, OnInit } from "@angular/core";
import { AbstractControl, ControlValueAccessor, FormArray, FormBuilder, FormGroup, NG_VALUE_ACCESSOR } from "@angular/forms";
import { LanguageSpoken } from "../../../user/user";
import { Referential, ReferentialsService } from "../../referentials/basic/referentials.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

class LanguageItem {
  languageSlug: string;
  skillSlug: string;
}

@Component({
  selector: 'anie-language-control',
  templateUrl: './language-control.component.html',
  styleUrls: ['./language-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LanguageControlComponent),
      multi: true
    }
  ]
})
export class LanguageControlComponent implements OnInit, OnDestroy, ControlValueAccessor {

  unsubscribeAll$ = new Subject<void>();

  languageForm: FormGroup;
  langList: Referential[];
  langSkillList: Referential[];

  // custom form control
  propagateChange = (_: LanguageSpoken[]) => {};

  get languagesControls(): AbstractControl[] {
    // must be done on controller side because without it the type checking made in template for AOT thow errors
    const languagesArray = <FormArray> this.languageForm.get('languages');
    return languagesArray.controls;
  }

  constructor(private fb: FormBuilder,
              private changeDetectorRef: ChangeDetectorRef,
              private referentialsService: ReferentialsService) { }

  ngOnInit() {
    this.referentialsService.languages$.subscribe(l => this.langList = l);
    this.referentialsService.languageSkills$.subscribe(l => this.langSkillList = l);
    this.languageForm = this.fb.group({
      languages: this.fb.array([])
    });

    this.languageForm.valueChanges
      .pipe(takeUntil(this.unsubscribeAll$))
      .subscribe(v => this.propagateNewValue(v.languages));
  }

  ngOnDestroy(): void {
    this.unsubscribeAll$.next();
    this.unsubscribeAll$.complete();
  }

  propagateNewValue(languages: LanguageItem[]) {
    const propagatedList = languages
      .filter(langItem => !!langItem.languageSlug)
      .map(langItem => ({
        language: this.langList && this.langList.find(l => l.slug === langItem.languageSlug),
        skill: this.langSkillList && this.langSkillList.find(s => s.slug === langItem.skillSlug)
      }));
    this.propagateChange(propagatedList);
  }

  createLanguage(language: LanguageItem = {languageSlug: '', skillSlug: this.langSkillList[0].slug}): FormGroup {
    return this.fb.group(language);
  }

  addLanguage(): void {
    const languages = <FormArray> this.languageForm.get('languages');
    languages.push(this.createLanguage());
    this.changeDetectorRef.detectChanges();
  }

  deleteLanguage(index: number): void {
    const languages = <FormArray> this.languageForm.get('languages');
    languages.removeAt(index);
  }

  writeValue(languages: LanguageSpoken[]): void {
    if (languages && languages.length) {
      const languagesArray = <FormArray> this.languageForm.get('languages');
      languages
        .map(l => ({ languageSlug: l.language.slug, skillSlug: l.skill.slug }))
        .forEach(l => languagesArray.push(this.createLanguage(l)));
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void { /* nothing to do unused touch property */ }
}
