import { Component } from "@angular/core";

@Component({
  selector: 'anie-cgu',
  templateUrl: './cgu.component.html',
  styleUrls: ['./cgu.component.scss']
})
export class CguComponent {

  constructor(
  ) {}

}
