import { Injectable } from "@angular/core";
import { PossibleMatch } from "./match";
import { PaginateHttpService, Paginator } from "../shared/utils/http/paginate-http.service";
import { HttpClient } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { environment } from "../../environments/environment";
import { map, switchMap } from "rxjs/operators";
import { AngularFireDatabase } from "@angular/fire/database";
import { AngularFireAuth } from "@angular/fire/auth";
import { FirebaseService } from "../shared/firebase.service";

@Injectable()
export class MatchService {

  constructor(
    private paginateService: PaginateHttpService,
    private http: HttpClient,
    private angularFireDatabase: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private firebaseService: FirebaseService
  ) {}

  getNbToAccept$(): Observable<number> {
    return this.firebaseService.canSubscribeFirebase$.pipe(
      switchMap(canAccess => canAccess ? this.angularFireAuth.user : of(null)),
      switchMap(user => user ? this.angularFireDatabase.list(`matchToAccept/${user.uid}`).valueChanges() : of(null)),
      map((list: any[]) => list ? list.length : 0)
    );
  }

  getOpportunities(offerId?: number): Paginator<PossibleMatch> {
    return this.paginateService.paginatorFactory(environment.apiEntryPoint + '/matches/opportunities', offerId ? {offerId} : undefined);
  }

  getTinder(): Observable<PossibleMatch[]> {
    return this.http.get<PossibleMatch[]>(environment.apiEntryPoint + '/matches/tinder');
  }

  getMatch(candidateId: number, offerId: number): Observable<PossibleMatch> {
    return this.http.get(`${environment.apiEntryPoint}/matches/${candidateId}/${offerId}`);
  }

  sendAccepted(candidateId: number, offerId: number, hasAccepted: boolean, motivation?: string): Observable<PossibleMatch> {
    const body = motivation ? { value: hasAccepted, motivation } : { value: hasAccepted };
    return this.http.put<PossibleMatch>(`${environment.apiEntryPoint}/matches/${candidateId}/${offerId}/accepted`, body);
  }

}
