import { Component } from "@angular/core";
import { UserService } from "../../user/user.service";
import { LOCAL_STORAGE_TARGET_URL_ID } from "../../security/auth.guard";
import { Router } from "@angular/router";

@Component({
  selector: 'anie-troisieme',
  templateUrl: './troisieme.component.html',
  styleUrls: ['./troisieme.component.scss']
})
export class TroisiemeComponent {
  isRecruiter$ = this.userService.getUser$();

  constructor(private userService: UserService,
              private router: Router) {}

  goToLogin(){
    const targetedRoute = "/user/profile/recruiter";
    localStorage.setItem(LOCAL_STORAGE_TARGET_URL_ID, targetedRoute);
    this.router.navigate(['/home']);
  }
}
