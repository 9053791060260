import { Injectable } from '@angular/core';
import { first, map, shareReplay } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';

export class Referential {
  slug: string;
  label: string;
  shortLabel?: string;
  description?: string;
  type?: string;
}

@Injectable()
export class ReferentialsService {
  readonly referentials$ = this.http.get<{ [key: string]: Referential[] }>(environment.apiEntryPoint + '/referentials').pipe(shareReplay());
  readonly languages$: Observable<Referential[]> = this.referentials$.pipe(map(all => all.languages));
  readonly degrees$: Observable<Referential[]> = this.referentials$.pipe(map(all => all.degrees));
  readonly trainingDurations$: Observable<Referential[]> = this.referentials$.pipe(map(all => all.trainingDurations));
  readonly trainingDurationsPro$: Observable<Referential[]> = this.referentials$.pipe(map(all => all.trainingDurationsPro));
  readonly languageSkills$: Observable<Referential[]> = this.referentials$.pipe(map(all => all.languageSkills));
  readonly companySizes$: Observable<Referential[]> = this.referentials$.pipe(map(all => all.companySizes));
  readonly reasons$: Observable<Referential[]> = this.referentials$.pipe(map(all => all.reasons));
  readonly reportOffersReasons$: Observable<Referential[]> = this.referentials$.pipe(map(all => all.reportOffersReasons));
  readonly reportUserReasons$: Observable<Referential[]> = this.referentials$.pipe(map(all => all.reportUserReasons));
  readonly perimeters$: Observable<Referential[]> = this.referentials$.pipe(map(all => all.perimeters));
  readonly offersDurations$: Observable<Referential[]> = this.referentials$.pipe(map(all => all.offersDurations));
  readonly candidateAttitudes$: Observable<Referential[]> = this.referentials$.pipe(map(all => all.candidateAttitudes));
  readonly recruiterAttitudes$: Observable<Referential[]> = this.referentials$.pipe(map(all => all.recruiterAttitudes));
  readonly permis$: Observable<Referential[]> = this.referentials$.pipe(map(all => all.permis));

  constructor(private http: HttpClient) {
    // do a first retrival to reduce waiting time later
    this.referentials$.pipe(first()).subscribe();
  }
}
