import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";

const TOKEN_KEY = 'token';

@Injectable()
export class AuthService {

  constructor() { }

  getToken(): string {
    return localStorage.getItem(TOKEN_KEY);
  }

  removeToken(): void {
    localStorage.removeItem(TOKEN_KEY);
  }

  setToken(token: string): void {
    if (token) {
      localStorage.setItem(TOKEN_KEY, token);
    } else {
      localStorage.removeItem(TOKEN_KEY);
    }
  }

  isTokenNotExpired(token = this.getToken()): boolean {
    try {
      const helper = new JwtHelperService();
      return !helper.isTokenExpired(token);
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  isTokenExpired(): boolean {
    return !this.isTokenNotExpired();
  }
}
