import { Component, Input, TemplateRef, ViewChild } from "@angular/core";
import { Candidate, Recruiter } from "../../../user/user";
import { ReferentialsService } from "../../referentials/basic/referentials.service";
import { FormBuilder, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { ReportService } from "./report.service";
import { Offer } from "../../../offers/offer";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: 'anie-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent {
  @ViewChild('reportDialog', { static: true }) reportDialog: TemplateRef<any>;

  @Input() candidateReported: Candidate;
  @Input() recruiterReported: Recruiter;
  @Input() offerReported: Offer;
  reportUserReasons$ = this.referentialsService.reportUserReasons$;
  reportOffersReasons$ = this.referentialsService.reportOffersReasons$;

  submission: Subscription;
  dialogRef: any;

  form = this.fb.group({
    reasonSlug: [null, Validators.required]
  });

  constructor(
    private referentialsService: ReferentialsService,
    private reportService: ReportService,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {
  }

  open() {
    this.dialogRef = this.dialog.open(this.reportDialog);
  }

  exit() {
    this.dialogRef.close();
  }

  submit(): void {
    if (this.submission && !this.submission.closed) {
      // previous request still running
      return;
    }

    if (this.recruiterReported) {
      this.submission = this.reportService.reportUser$(this.recruiterReported.user.id, this.form.value.reasonSlug).subscribe(() => {
        this.snackBar.open('Votre signalement a bien été envoyé. Il sera traité dans les meilleurs délais.', '', { duration: 2000, panelClass: ['snackbar', 'success'] });
        this.exit();
      });
    }
    if (this.candidateReported) {
      this.submission = this.reportService.reportCandidate$(this.candidateReported.id, this.form.value.reasonSlug).subscribe(() => {
        this.snackBar.open('Votre signalement a bien été envoyé. Il sera traité dans les meilleurs délais.', '', { duration: 2000, panelClass: ['snackbar', 'success'] });

        this.exit();
      });
    }
    if (this.offerReported) {
      this.submission = this.reportService.reportOffer$(this.offerReported.id, this.form.value.reasonSlug).subscribe(() => {
        this.snackBar.open('Votre signalement a bien été envoyé. Il sera traité dans les meilleurs délais.', '', { duration: 2000, panelClass: ['snackbar', 'success'] });
        this.exit();
      });
    }
  }
}
