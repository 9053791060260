import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from "@angular/core";
import { Observable, Subject, Subscription } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";

@Component({
  selector: 'anie-busy',
  templateUrl: './busy.component.html',
  styleUrls: ['./busy.component.scss']
})
export class BusyComponent implements OnChanges, OnDestroy {

  @Input() subscription: Subscription;
  @Input() observable: Observable<boolean>;
  @Input() isPending = false;
  unsubscribe = new Subject<void>();

  constructor() { }

  ngOnChanges(simpleChanges: SimpleChanges): void {

    // reset previous behaviour
    if (!simpleChanges.isPending) {
      this.isPending = false;
    }
    this.unsubscribe.next();

    if (this.subscription) {
      this.isPending = true;
      this.subscription.add(() => this.isPending = false);
    } else if (this.observable) {
      this.observable.pipe(
        takeUntil(this.unsubscribe),
        tap(val => this.isPending = val),
      ).subscribe();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
