import { Component, ElementRef, HostBinding, HostListener, Input, OnInit, Renderer2, TemplateRef, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: 'anie-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class InfoComponent implements OnInit {

  @ViewChild('infoDialog', { static: true }) infoDialog: TemplateRef<any>;
  dialogRef: any;

  showMessage = false;
  @Input() asPopup = false; // Si true modale, sinon tooltip
  @Input() message?: string; // Message à afficher dans le tooltip
  @Input() absolute = false; // positionnement absolu ou pas
  @Input() top = 0;
  @Input() left: any = 'inherit';
  @Input() right: any = 'inherit';
  @Input() px = true; // px ou e
  @HostBinding('class.replace-input') replaceInputCss = false;

  constructor(private renderer: Renderer2, private el: ElementRef, private dialog: MatDialog) {}

  ngOnInit(): void {
    let pxOrPercent;
    this.replaceInputCss = this.absolute;
    this.px === true ? (pxOrPercent = 'px') : (pxOrPercent = '%');
    this.renderer.setStyle(this.el.nativeElement, 'left', this.left + pxOrPercent);
    this.renderer.setStyle(this.el.nativeElement, 'top', this.top + pxOrPercent);
    this.renderer.setStyle(this.el.nativeElement, 'right', this.right + pxOrPercent);
  }

  openMessage(e: Event): void {
    if (!this.showMessage) {
      this.showMessage = true;
      e.stopPropagation();
    }
    if(this.showMessage){
      this.openDialog();
    }
  }

  openDialog(){
    this.dialogRef = this.dialog.open(this.infoDialog);
    this.dialogRef.afterClosed().subscribe(result => {
      this.closeLocally();
    });
  }

  @HostListener('document:click')
  closeMessageFromExternalClick(): void {
    this.showMessage = false;
  }

  @HostListener('window:scroll')
  closeMessageFromExternalScroll(): void {
    if (this.showMessage) {
      this.showMessage = false;
    }
  }

  closeLocally(e?: Event): void {
    if (this.asPopup) {
      this.showMessage = false;
    }
  }
}
