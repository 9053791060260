import { Inject, Injectable } from "@angular/core";
import { HttpBackend, HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { map, retryWhen, switchMap } from "rxjs/operators";
import { WINDOW_ID } from "./injectorTokens";
import { fromEvent } from "rxjs";
import { NewVersionService } from "../root/new-version-available/new-version.service";

@Injectable()
export class StatsService {

  private readonly http = new HttpClient(this.httpBackend);

  constructor(
    private httpBackend: HttpBackend,
    private router: Router,
    private versionService: NewVersionService,
    @Inject(WINDOW_ID) private window: Window
  ) {}

  sendStat(statData: any = {}): void {
    if (statData.url || statData.appInfo) {
      console.error('url or appInfo are reserved key words for stats');
    }
    this.versionService.appInfo$.pipe(
      map(appInfo => ({ ...statData, appInfo, url: this.router.url})),
      switchMap(body => this.http.post(environment.apiEntryPoint + '/events', body)),
      retryWhen(() => fromEvent(this.window, 'online')),
    ).subscribe();
  }

}
