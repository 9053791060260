import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from '../../user/user.service';
import { filter, finalize, first, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'anie-popin-recruiter-update-profile',
  templateUrl: './popin-recruiter-update-profile.component.html',
  styleUrls: ['./popin-recruiter-update-profile.component.scss']
})
export class PopinRecruiterUpdateProfileComponent implements OnInit {
  @ViewChild('updateProfileDialog', { static: true }) updateProfileDialog: TemplateRef<any>;
  dialogRef;
  message: string;
  route: string[];
  constructor(private dialog: MatDialog, private userService: UserService, private router: Router) {}
  ngOnInit() {
    this.userService
      .getUser$()
      .pipe(
        filter(user => !!user),
        tap(user => {
          if (this.dialogRef == null) {
            if (
              user &&
              user.recruiter &&
              user.recruiter.jobCategory &&
              (user.recruiter.siren == null || user.recruiter.siren === '' || user.recruiter.siren.match(/^\d{14}$/) == null)
            ) {
              this.message = 'Veuillez vous rendre dans votre profil, merci de renseigner votre numéro de SIRET.';
              this.route = ['/user', 'sign-up', 'user-info'];
              this.dialogRef = this.dialog.open(this.updateProfileDialog);
            } else if (user && user.recruiter && user.recruiter.jobCategory && (!Array.isArray(user.recruiter.jobSubcategories) || user.recruiter.jobSubcategories.length === 0)) {
              this.message = 'Veuillez vous rendre dans votre profil, et depuis "votre activité", rajoutez au moins une sous-catégorie du secteur d\'activité lié à votre société.';
              this.route = ['/user', 'profile', 'recruiter'];
              this.dialogRef = this.dialog.open(this.updateProfileDialog);
            }
          }
        })
      )
      .subscribe();
  }

  close() {
    this.router.navigate(this.route);
    this.dialogRef.close();
    this.dialogRef = null;
  }
}
