import { ActionReducerMap } from "@ngrx/store";

import { anieReducer, AnieState } from "../../anie/store";

export interface GlobalState {
  anie: AnieState;
}

export const globalReducers: ActionReducerMap<GlobalState> = {
  anie: anieReducer,
};
