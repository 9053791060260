export enum AnieType {
  GUIDE_VISITOR = 'guideVisitor',
  GUIDE_CANDIDATE = 'guideCandidate',
  GUIDE_CANDIDATE_PRO = 'guideCandidatePro',
  GUIDE_TROISIEME = 'guideTroisieme',
  GUIDE_RECRUITER = 'guideRecruiter',
  COMPLETE_PROFILE = 'completeProfile',
  EDIT_PROFILE = 'editProfile',
  PERFECT_CANDIDATE = 'perfectCandidate',
  PERFECT_CANDIDATE_PRO = 'perfectCandidatePro',
  QUIZ_ATTITUDE = 'quizAttitude',
  QUIZ_ATTITUDE_PRO = 'quizAttitudePro',
}

export enum AnieQuestionType {
  BUTTONS = 'buttons',
  SELECT_LIST = 'selectList',
  POSTAL_CODE = 'postalCode',
  JOB = 'job',
  CENTER = 'input',
}

export class AnieQuestion {

  /**
   * technical id for NGRX generated with window.performance.now()
   */
  id: number;

  slugSentence: string;
  anieSentence: string;
  type: AnieQuestionType;
  choices: AnieChoice[];

  answer?: any;
}

export class AnieChoice {
  slug: string;
  label: string;
  url?: string;
}

export class AnieAnswer {
  constructor(public slugSentence: string = '',
              public valueChoice: string = '') {}
}
