import { ElementRef, Inject, Injectable, Renderer2, RendererFactory2 } from "@angular/core";
import { debounceTime, distinctUntilChanged, map } from "rxjs/operators";
import { Subject } from "rxjs";
import { WINDOW_ID } from "./injectorTokens";

@Injectable()
export class KeyboardOpenService {
  private readonly className = 'input-focused';
  private readonly listUpdated$ = new Subject<void>();

  /**
   * Keep track of all field having the keyboard-open.directive.ts
   * @type {any[]}
   */
  private fields: { elementRef: ElementRef; isFocused: boolean; }[] = [];

  private readonly renderer: Renderer2;

  constructor(
    rendererFactory: RendererFactory2,
    @Inject(WINDOW_ID) private window: Window,
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);

    // in some use case user might click on send button that will refocus the input immediatly after (chat)
    // in that case it's useless to remove the class
    this.listUpdated$.pipe(
      debounceTime(100),
      map(() => this.hasAtLeastOneFocus()),
      distinctUntilChanged(),
    ).subscribe(isFocused => {
      if (isFocused) {
        this.renderer.addClass(this.window.document.body, this.className);
      } else {
        this.renderer.removeClass(this.window.document.body, this.className);
      }
    });
  }

  addField(elementRef: ElementRef): void {
    this.fields.push({ elementRef, isFocused: false });
  }

  removeField(elementRef: ElementRef): void {
    const index = this.fields.findIndex(f => f.elementRef === elementRef);
    this.fields.splice(index, 1);
    this.listUpdated$.next();
  }

  updateFocuseState(elementRef: ElementRef, isFocus: boolean): void {
    const field = this.fields.find(f => f.elementRef === elementRef);
    field.isFocused = isFocus;
    this.listUpdated$.next();
  }

  private hasAtLeastOneFocus(): boolean {
    return this.fields.find(f => f.isFocused) != null;
  }

}
