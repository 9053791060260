import { Directive, ElementRef, Input, OnDestroy, OnInit, Renderer2 } from "@angular/core";
import { FormControlName } from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Directive({
  selector: '[anieInputFilled]'
})
export class InputFilledDirective implements OnInit, OnDestroy {

  // contient 'true' ou true si la class est bloquée toujours présente
  @Input() anieInputFilled = false;

  unsubscribeAll$ = new Subject<void>();

  constructor(private elementRef: ElementRef,
              private formControlName: FormControlName,
              private rd: Renderer2) {
  }

  ngOnInit(): void {
    this.addClassOnValue(this.formControlName.value);
    if (! this.anieInputFilled) {
      this.formControlName.valueChanges
        .pipe(takeUntil(this.unsubscribeAll$))
        .subscribe(val => this.addClassOnValue(val));
    }
  }

  ngOnDestroy(): void {
    this.unsubscribeAll$.next();
    this.unsubscribeAll$.complete();
  }

  addClassOnValue(val: string | number): void {
    if (this.anieInputFilled || val || val === 0) {
      this.rd.addClass(this.elementRef.nativeElement.parentNode, 'input--filled');
    } else {
      this.rd.removeClass(this.elementRef.nativeElement.parentNode, 'input--filled');
    }
  }
}
