import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { take } from "rxjs/operators";
import { Subscription } from "rxjs";
import { ProfileCompleteGuard } from "../../../user/profile-complete.guard";
import { UserService } from "../../../user/user.service";
import { StatsService } from "../../../shared/stats.service";
import { AuthService } from '../../../security/auth.service';

@Component({
  selector: 'anie-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit {

  loginForm: FormGroup;
  hasError = false;
  hasErrorAdmin = false;
  sub: Subscription;

  constructor(private fb: FormBuilder,
              private userService: UserService,
              private router: Router,
              private statsService: StatsService,
              private route: ActivatedRoute,
              private authService: AuthService,
              private profileComplete: ProfileCompleteGuard) { }

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: '',
      password: ''
    });
  }

  signIn(): void {
    this.sub = this.userService.signIn$(this.loginForm.value.email, this.loginForm.value.password).subscribe(
      user => this.profileComplete.goToNextStep(user),
      err => this.handleErrors(err)
    );
  }

  handleErrors(err: Error): void {
    this.authService.removeToken();
    if (err && err.message === 'access-admin') {
      this.hasErrorAdmin = true;
    }
    this.hasError = true;
    // if user start to modify his login/password
    // reset the error message => allow him to see if there is another error on second try
    this.loginForm.valueChanges.pipe(take(1)).subscribe(() => {
      this.hasErrorAdmin = false;
      this.hasError = false;
    });
  }

  saveEvent(): void {
    this.statsService.sendStat({
      message: 'starting sign-up'
    });
  }

}
