import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { environment } from "../../../../environments/environment";

export class JobCategory {
  slug: string;
  label: string;
  parentSlug?: string;
}

@Injectable()
export class JobsCategoryService {

  constructor(private http: HttpClient) {
  }

  getActivitySector$(q?: string): Observable<JobCategory[]> {
    if (q) {
      return this.getJobCategories$([1], q);
    } else {
      return this.getJobCategories$([1]);
    }
  }

  getSubcategoryActivitySector$(): Observable<JobCategory[]> {
    return this.getJobCategories$([2,3]);
  }

  searchRome$(q: string): Observable<JobCategory[]> {
    return this.getJobCategories$([3, 4], q);
  }

  searchSubCategory$(q: string): Observable<JobCategory[]> {
    return this.getJobCategories$([3], q);
  }

  searchAllJobCategories$(q: string): Observable<JobCategory[]> {
    return this.getJobCategories$([1, 2, 3, 4], q);
  }

  private getJobCategories$(levels: number[], q?: string): Observable<JobCategory[]> {
    let params = new HttpParams();
    params = params.append('levels', levels.join(','));
    if (q) {
      params = params.append('q', q);
    }
    return this.http.get<JobCategory[]>(environment.apiEntryPoint + '/jobs/categories', {params});
  }

  getRome$(slug: string): Observable<JobCategory> {
    return this.http.get<JobCategory>(`${environment.apiEntryPoint}/jobs/categories/${slug}`);
  }
}
