import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { School } from "./school";

@Injectable()
export class SchoolService {
  constructor(private http: HttpClient) {}

  getSchool$(pc) {
    return this.http.post<School[]>(`${environment.apiEntryPoint}/3e/schools`, { postalCode: pc });
  }
}
