import { Pipe, PipeTransform } from "@angular/core";
import { formatPostalCode } from "../utils/format-utils";

@Pipe({
  name: 'postalCode'
})
export class PostalCodePipe implements PipeTransform {

  transform(postalCode: string): string {
    return formatPostalCode(postalCode);
  }

}
