import { Component, OnInit } from "@angular/core";
import { UserService } from "../../../user/user.service";
import { Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { UserType } from "../../../user/user";

@Component({
  selector: 'anie-coming-soon-match',
  templateUrl: './coming-soon-match.component.html',
  styleUrls: ['./coming-soon-match.component.scss']
})
export class ComingSoonMatchComponent implements OnInit {

  isRecruiter$: Observable<boolean>;

  constructor(private userService: UserService) { }

  ngOnInit() {
    this.isRecruiter$ = this.userService.getUser$().pipe(
      take(1),
      map(u => u.profile === UserType.RECRUITER)
    );
  }

}
