import { CandidateType, LanguageSpoken, Recruiter } from "../user/user";
import { JobCategory } from "../shared/referentials/jobs-category/jobs-category.service";
import { Referential } from "../shared/referentials/basic/referentials.service";
import { Attitudes } from "../shared/models/attitude";

export enum OfferState {
  DRAFT = 'DRAFT',
  PROVIDED = 'PROVIDED',
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED' // when user disabled its account
}

export const OfferType = [
  {
    slug: 'APPRENTI',
    label: 'Apprentissage'
  },
  {
    slug: 'FORM_PRO',
    label: 'Stage de professionnalisation'
  }
];


export class ShortOffer {
  id?: number;
  label?: string;
  handicap?: boolean;
}

export class Offer {
  id?: number;

  label?: string;
  description?: string;
  rome?: JobCategory;
  postalCode?: string;
  city?: string;
  latitude?: number;
  longitude?: number;

  degree?: Referential;
  degreeSlug?: string;
  has_degree?: boolean;
  languages?: LanguageSpoken[];
  duration?: Referential;
  durationSlug?: string;

  attitudes?: Attitudes;

  creationDate?: Date;
  isArchived?: boolean;
  handicap?: boolean;
  expiredat?: string; // ISO string of date (always server -> client) calculated by "offersDurations"
  durationOffersSlug?: string;
  state?: OfferState;

  // for matches
  recruiterId?: number;
  recruiter?: Recruiter;

  // pôle emploi
  pe_id?: string;
  pe_company_name?: string;
  pe_url_postul?: string;

  // admin
  moderate_by_admin?: boolean;

  profil_type?: CandidateType;
  disponible?: string;
  permis?: Permis;

  created?: string;
  modified?: string;
}

export enum PeOfferState {
  NEW = 'new',
  EXCLUDED = 'excluded',
  MERGED = 'merged'
}

export class PeOffer {
  id?: number;
  label?: string;
  state?: PeOfferState;
  attitudeSlug?: string;
}

export class Permis {
  label: string;
  slug: string;
  shortLabel: string;
}
