import { Component } from "@angular/core";
@Component({
  selector: 'anie-advanced-politic-confidential',
  templateUrl: './advanced-politic-confidential.component.html',
  styleUrls: ['./advanced-politic-confidential.component.scss'],
})
export class AdvancedPoliticConfidentialComponent {

  constructor() { }
}
