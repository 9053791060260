import { Directive, ElementRef, Input, OnChanges, Renderer2 } from "@angular/core";
import { Subscription } from "rxjs";

@Directive({
  selector: '[anieBusyButton]'
})
export class BusyButtonDirective implements OnChanges {

  @Input() anieBusyButton: Subscription;

  constructor(private elementRef: ElementRef,
              private renderer: Renderer2) { }

  ngOnChanges(): void {
    if (this.anieBusyButton && !this.anieBusyButton.closed) {
      this.blockElement();
      this.anieBusyButton.add(() => this.unblockElement());
    }
  }

  blockElement() {
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', true);
    this.renderer.addClass(this.elementRef.nativeElement, 'pending-button');
  }

  unblockElement() {
    this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', false);
    this.renderer.removeClass(this.elementRef.nativeElement, 'pending-button');
  }
}
