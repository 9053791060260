import { Inject, Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { UserService } from "./user.service";
import { map, tap } from "rxjs/operators";
import { CandidateType, User, UserType } from "./user";
import { LOCAL_STORAGE_TARGET_URL_ID } from "../security/auth.guard";
import { isYoungerThan } from "../shared/utils/date-utils";
import { WINDOW_ID } from "../shared/injectorTokens";
import { goTo } from "../shared/utils/navigation-utils";

export enum FirstConnectionStep {
  LOCATION = 'LOCATION',
  CANDIDATE_PROFILE = 'CANDIDATE_PROFILE',
  CANDIDATE_CV = 'CANDIDATE_CV',
  CANDIDATE_MOTIVATION = 'CANDIDATE_MOTIVATION',
  // CANDIDATE_PUBLISH_PROFILE = 'CANDIDATE_PUBLISH_PROFILE',
  CANDIDATE_ATTITUDE = 'CANDIDATE_ATTITUDE',
  RECRUITER_YOUR_COMPANY = 'RECRUITER_YOUR_COMPANY',
  RECRUITER_WHAT_NEXT = 'RECRUITER_WHAT_NEXT'
}

class StepOrder {
  order: number;
  step: FirstConnectionStep;
  profile?: UserType;
  isLastStep?: boolean;
}

/**
 * WARNING: wrong configuration in routing system might create infinite loops !!!!
 * @type {StepOrder[]}
 */
const orderedSteps: StepOrder[] = [
  { order: 0, step: FirstConnectionStep.LOCATION },
  { order: 1, step: FirstConnectionStep.RECRUITER_YOUR_COMPANY, profile: UserType.RECRUITER },
  { order: 2, step: FirstConnectionStep.RECRUITER_WHAT_NEXT, profile: UserType.RECRUITER, isLastStep: true },
  { order: 1, step: FirstConnectionStep.CANDIDATE_PROFILE, profile: UserType.CANDIDATE },
  { order: 2, step: FirstConnectionStep.CANDIDATE_CV, profile: UserType.CANDIDATE },
  { order: 3, step: FirstConnectionStep.CANDIDATE_MOTIVATION, profile: UserType.CANDIDATE },
  // { order: 4, step: FirstConnectionStep.CANDIDATE_PUBLISH_PROFILE, profile: UserType.CANDIDATE },
  { order: 4, step: FirstConnectionStep.CANDIDATE_ATTITUDE, profile: UserType.CANDIDATE, isLastStep: true }
];

const orderedSteps3e: StepOrder[] = [
  { order: 0, step: FirstConnectionStep.LOCATION },
  { order: 1, step: FirstConnectionStep.CANDIDATE_PROFILE, profile: UserType.CANDIDATE, isLastStep: true }
];

@Injectable()
export class ProfileCompleteGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router, @Inject(WINDOW_ID) private window: Window) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.userService.getUser$().pipe(
      map(user => {
        // no requestedStep <=> all firstConnectionProcess complete
        let requestedStep;
        if (user.profile === UserType.CANDIDATE && user.candidate.type === CandidateType.TROISIEME) {
          requestedStep = orderedSteps3e.find(s => s.step === next.data.firstConnectionStep);
        } else {
          requestedStep = orderedSteps.find(s => s.step === next.data.firstConnectionStep);
        }

        const isAuthorized = this.isAuthorizedOnStep(requestedStep, user) && this.hasAllConfirmation(user);
        if (!isAuthorized) {
          this.goToNextStep(user);
          return false;
        }
        return true;
      })
    );
  }

  /**
   * null step => after full firstConnection process
   * @param {StepOrder} step
   * @param {User} user
   * @returns {boolean}
   */
  private isAuthorizedOnStep(step: StepOrder, user: User): boolean {
    const lastStepValidatedLabel = user && user.params && user.params.firstConnectionStepDone;
    let lastStepValidated;
    if (user.profile === UserType.CANDIDATE && user.candidate.type === CandidateType.TROISIEME) {
      lastStepValidated = orderedSteps3e.find(s => s.step === lastStepValidatedLabel);
    } else {
      lastStepValidated = orderedSteps.find(s => s.step === lastStepValidatedLabel);
    }

    if (!lastStepValidated) {
      // lastStepValidated is falsy => only first step is authorized
      return step && step.order === 0;
    }

    // step = null
    //    => it's not a step of the first connection process
    //    => after full firstConnection process
    //    => lastStepValidated must correspond to one of the last steps (of the first connection process)
    if (!step) {
      return lastStepValidated.isLastStep;
    }

    // can only access on next step
    return lastStepValidated.order + 1 >= step.order;
  }

  hasAllConfirmation(user: User): boolean {
    let hasAllConfirmations = user.emailConfirmed;
    if (user.profile === UserType.CANDIDATE && isYoungerThan(user.candidate.birthDate, 15)) {
      hasAllConfirmations = hasAllConfirmations && user.candidate.parentValidatedInscription;
    }
    return hasAllConfirmations;
  }

  /**
   * try to go to the next step of the first connection process
   * if there is no next step, go to default or targeted route
   * @param {User} user
   * @param {string[]} nextRoute can set explicitly the next route to go to
   */
  goToNextStep(user: User, nextRoute?: string[] | string): void {
    const lastStepValidated = user.params && user.params.firstConnectionStepDone;
    const lastStepExist = FirstConnectionStep[lastStepValidated] !== undefined;
    if (!this.hasAllConfirmation(user)) {
      this.router.navigate(['/user', 'sign-up', 'confirm-email']);
    } else if (!lastStepValidated || !lastStepExist) {
      this.router.navigate(['/user', 'profile', 'location']);
    } else if (lastStepValidated === FirstConnectionStep.LOCATION) {
      this.router.navigate(['/user', 'profile', user.profile.toLowerCase()]);
    } else if (lastStepValidated === FirstConnectionStep.CANDIDATE_PROFILE) {
      if (user.profile === UserType.CANDIDATE && user.candidate.type === CandidateType.TROISIEME) {
        this.router.navigate(['/anie']);
      } else {
        this.router.navigate(['/user', 'profile', 'candidate', 'cv']);
      }
    } else if (lastStepValidated === FirstConnectionStep.CANDIDATE_CV) {
      this.router.navigate(['/user', 'profile', 'candidate', 'motivation']);
    } else if (lastStepValidated === FirstConnectionStep.CANDIDATE_MOTIVATION) {
      // this.router.navigate(['/user', 'profile', 'candidate', 'publish-profile']);
      this.router.navigate(['/user', 'profile', 'candidate', 'attitude']);
      // } else if (lastStepValidated === FirstConnectionStep.CANDIDATE_PUBLISH_PROFILE) {
    } else if (lastStepValidated === FirstConnectionStep.RECRUITER_YOUR_COMPANY) {
      this.router.navigate(['/user', 'profile', 'recruiter', 'what-next']);
    } else if (this.isFirstConnectionEnded(user)) {
      const targetedURL = localStorage.getItem(LOCAL_STORAGE_TARGET_URL_ID);
      if (nextRoute && nextRoute instanceof Array) {
        this.router.navigate(nextRoute);
      } else if (nextRoute && typeof nextRoute === 'string' && /^http/.test(nextRoute)) {
        goTo(nextRoute);
      } else if (targetedURL) {
        // if user asked for a route that require to be granted before to login
        this.router.navigateByUrl(targetedURL);
      } else {
        this.router.navigate(['/anie']);
      }
    } else {
      console.error('You should have navigate somewhere!');
    }
  }

  private isFirstConnectionEnded(user: User): boolean {
    if (!user || !user.params) {
      return false;
    }
    const lastValidatedStep = user.params.firstConnectionStepDone;
    if (user.profile === UserType.CANDIDATE && user.candidate.type === CandidateType.TROISIEME) {
      return lastValidatedStep === FirstConnectionStep.CANDIDATE_PROFILE;
    } else {
      return lastValidatedStep === FirstConnectionStep.CANDIDATE_ATTITUDE || lastValidatedStep === FirstConnectionStep.RECRUITER_WHAT_NEXT;
    }
  }

  isFirstConnectionEnded$(): Observable<boolean> {
    return this.userService.getUser$().pipe(map(u => this.isFirstConnectionEnded(u)));
  }

  /**
   * save the user (also update the last validated step and navigate)
   * @param {User} user: partial user (only filled updated by the screen)
   * @param {FirstConnectionStep} validatedStep
   * @param {string[]} routeSegments: route to redirect to after the user where update (only if the user ended the first connection process)
   * @returns {Subscription}
   */
  validateFirstConnectionStep$(user: User, validatedStep: FirstConnectionStep, routeSegments?: string[] | string): Observable<User> {
    if (!user.params) {
      user.params = {};
    }
    user.params.firstConnectionStepDone = validatedStep;
    this.userService.updateLocalUser(user);
    return this.userService.updateUserProfile$().pipe(tap(updatedUser => this.goToNextStep(updatedUser, routeSegments)));
  }
}
