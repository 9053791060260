import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { publishLast, refCount } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";

@Injectable()
export class LegalNoticeService {

  /**
   * cached data is reload at each refresh
   */
  private cachedLegalNotice$: Observable<string>;

  constructor(private http: HttpClient) { }

  getLegalNotice$(): Observable<any> {
    if (!this.cachedLegalNotice$) {
      this.cachedLegalNotice$ = this.http
        .get(`${environment.domain}/media/legalNoticeAnie.md`, {responseType: 'text'})
        .pipe(publishLast(), refCount()); // cache the result
    }
    return this.cachedLegalNotice$;
  }

}
