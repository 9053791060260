import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { UserService } from "../user/user.service";
import { AuthService } from "./auth.service";
import { map } from "rxjs/operators";
import { CandidateType, User, UserType } from "../user/user";

export const LOCAL_STORAGE_TARGET_URL_ID = 'targetUrlId';

/**
 * when user wants to access to a specific route without being authorized,
 * it's necessary to keep that route in order to redirect him to it once authorized
 *
 * multiple small views make it a burden to store it in a query params
 * and store it in a service make it vulnerable to multiple reload
 *
 * each time the guard is required, reset the value to local storage in order to
 * avoid redirecting to previous route
 */
function resetTargetUrl(): void {
  localStorage.removeItem(LOCAL_STORAGE_TARGET_URL_ID);
}

function setTargetUrl(url: string): void {
  localStorage.setItem(LOCAL_STORAGE_TARGET_URL_ID, url);
}

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private userService: UserService,
              private authService: AuthService,
              private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    const authorizedTypes = next.data.authorized;
    const authorizedCandidateTypes = next.data.authorizedCandidateTypes;
    if (!authorizedTypes) {
      console.error('AuthGuard requires a "authorized" in route\'s data with the list of authorized user\'s profile');
      return false;
    }

    resetTargetUrl();

    return this.userService.getUser$().pipe(
      map(user => this.testAuthorization(state, authorizedTypes, authorizedCandidateTypes)(user))
    );
  }

  private testAuthorization(state: RouterStateSnapshot, authorizedTypes: UserType[], authorizedCandidateTypes: CandidateType[]): (User) => boolean {
    return user => {
      if (this.isAuthorized(user, authorizedTypes, authorizedCandidateTypes)) {
        return true;
      }

      // if user is not authorized, it's important to redirect him to the right screen
      if (!this.authService.isTokenNotExpired()) {
        this.router.navigate(['/home']);
      } else {
        this.router.navigate(['/anie']);
      }
      setTargetUrl(state.url);
      return false;
    };
  }

  isAuthorized(user: User, authorizedTypes: UserType[], authorizedCandidateTypes: CandidateType[]) {
    let result = user && user.profile && this.authService.isTokenNotExpired();

    if(result){
      if(authorizedTypes.indexOf(user.profile) !== -1){
        if(user.profile == UserType.CANDIDATE && Array.isArray(authorizedCandidateTypes) && authorizedCandidateTypes.length){
          result = result && (user.candidate && user.candidate.type && authorizedCandidateTypes.indexOf(user.candidate.type) !== -1)
        }
      }
      else {
        result = false;
      }
    }

    return result;
  }
}
