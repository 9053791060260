import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { Article, ArticleTheme, Chapter } from "./article";
import { publishLast, refCount } from "rxjs/operators";
import { environment } from "../../environments/environment";

@Injectable()
export class ArticleService {
  private cachedChapters$: { [key: string]: Observable<Chapter[]> | any} = {};

  constructor(private http: HttpClient) {}

  getChapters$(theme: ArticleTheme, fullArticle = false): Observable<Chapter[]> {
    let params = new HttpParams();
    if (fullArticle) {
      params = params.append('full', '1');
    }
    if (!this.cachedChapters$[theme + fullArticle]) {
      this.cachedChapters$[theme + fullArticle] = this.http
        .get<Chapter[]>(`${environment.apiEntryPoint}/chapters/index/${theme}`, { params })
        .pipe(publishLast(), refCount()); // cache the result
    }
    return this.cachedChapters$[theme + fullArticle];
  }

  getArticle$(articleSlug: string): Observable<Article> {
    return this.http.get<Article>(`${environment.apiEntryPoint}/articles/${articleSlug}`);
  }
}
