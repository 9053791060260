import { Injectable } from "@angular/core";
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { HTTP } from "@ionic-native/http/ngx";
import { Platform } from "@ionic/angular";
import { from, Observable } from "rxjs";

type HttpMethod = 'get' | 'post' | 'put' | 'patch' | 'head' | 'delete' | 'upload' | 'download';
@Injectable()
export class NativeHttpInterceptor implements HttpInterceptor {
  constructor(private nativeHttp: HTTP, private platform: Platform) {}
  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.platform.is('desktop') || this.platform.is('mobileweb')) {
      return next.handle(request);
    }
    return from(this.handleNativeRequest(request));
  }
  private async handleNativeRequest(request: HttpRequest<any>): Promise<HttpResponse<any>> {
    //console.log("Request", request);
    /* Rewrite the headers from the request */
    const headerKeys = request.headers.keys();
    const headers = {};
    headerKeys.forEach(key => {
      headers[key] = request.headers.get(key);
    });
    //console.log("Header avant modif", headers);
    if(!headers['Accept']){
      headers['Accept'] = 'application/json';
    }
    /* Rewrite the params from the request */
    const paramsKeys = request.params.keys();
    const params = {};
    paramsKeys.forEach(key => {
      params[key] = request.params.get(key).toString();
    });
    let serializer: any = 'json';
    try {
      await this.platform.ready();
      const method = <HttpMethod>request.method.toLowerCase();
      //console.log('— Envoi Requête à ' + request.url);
      let options: any = {
        method: method,
        data: request.body,
        params: params,
        headers: headers,
        serializer: serializer
      };
      if(request.responseType == "blob"){
        options.responseType = "blob";
      }
      //console.log("Options envoyées", options);
      const nativeHttpResponse = await this.nativeHttp.sendRequest(request.url, options);
      //console.log("Http Response", nativeHttpResponse);
      let body;
      try {
        if(nativeHttpResponse.headers['content-type'].indexOf("application/json") > -1){
          body = JSON.parse(nativeHttpResponse.data);
        }
        else {
          body = nativeHttpResponse.data;
        }
      } catch (error) {
        //console.log('Erreur de parsage JSON de la réponse HTTP');
        //console.log(error, nativeHttpResponse.data);
        body = { response: nativeHttpResponse.data };
      }
      const response = new HttpResponse({
        body: body,
        status: nativeHttpResponse.status,
        headers: nativeHttpResponse.headers,
        url: nativeHttpResponse.url
      } as any);
      //console.log('— SUCCESS sur ' + request.method + ' ' + request.url);
      //console.log(response);
      return Promise.resolve(response);
    } catch (error) {
      if (!error.status) {
        //console.log('— ERREUR JS sur ' + request.method + ' ' + request.url);
        return Promise.reject(error);
      }
      //console.log('— ERREUR HTTP sur ' + request.method + ' ' + request.url);
      //console.log('Erreur ' + error.status + ' : ' + error.error);
      let parsedErr;
      try {
        parsedErr = JSON.parse(error.error);
      } catch (err) {
        parsedErr = error.error;
      }
      const response = new HttpResponse({
        body: parsedErr,
        status: error.status,
        headers: error.headers,
        url: error.url
      });
      return Promise.reject(response);
    }
  }
}
