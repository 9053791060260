import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Town } from "./town";

const GEO_API_URL = 'https://geo.api.gouv.fr';

@Injectable()
export class TownshipService {
  constructor(private http: HttpClient) {}

  /**
   * open data from the gov
   * https://api.gouv.fr/api/api-geo.html
   * allow to retrieve coordinate (see "fields: center" on the technical documentation)
   *
   * @param {string} firstLetters
   * @returns {Observable<any>}
   */
  searchTownByName(firstLetters: string): Observable<Town[]> {
    let params = new HttpParams();
    params = params.append('nom', firstLetters);
    params = params.append('fields', 'nom,code,codesPostaux,centre');
    params = params.append('format', 'json');
    return this.http.get<Town[]>(GEO_API_URL + '/communes', { params });
  }

  searchTownByPostalCode(postalCode: string): Observable<Town[]> {
    if (!postalCode || postalCode.length < 5) {
      return of([]);
    }

    let params = new HttpParams();
    params = params.append('codePostal', postalCode);
    params = params.append('fields', 'nom,code,codesPostaux,centre');
    params = params.append('format', 'json');
    return this.http.get<Town[]>(GEO_API_URL + '/communes', { params });
  }
}
