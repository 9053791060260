import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LayoutComponent, LayoutDisplayMode } from './layout/layout.component';
import { CandidateType, UserType } from '../user/user';
import { AuthGuard } from '../security/auth.guard';
import { ProfileCompleteGuard } from '../user/profile-complete.guard';
import { FaqComponent } from './faq/faq.component';
import { LegalNoticeComponent } from './legal-notice/legal-notice.component';
import { CguComponent } from './cgu/cgu.component';
import { PoliticConfidentialComponent } from './politic-confidential/politic-confidential.component';
import { ArmlComponent } from './arml/arml.component';
import { MaintenanceComponent } from './maintenance/maintenance.component';
import { MaintenanceGuard } from './maintenance/maintenance.guard';
import { SignInComponent } from './home/sign-in/sign-in.component';
import { ForgottenPasswordComponent } from './home/sign-in/forgotten-password/forgotten-password.component';
import { ResetPasswordComponent } from './home/sign-in/reset-password/reset-password.component';
import { TroisiemeComponent } from './troisieme/troisieme.component';
import { AdvancedPoliticConfidentialComponent } from './politic-confidential/advanced-politic-confidential.component';
import { NotConnectedComponent } from './not-connected/not-connected.component';

const routes: Routes = [
  {
    path: 'maintenance',
    component: MaintenanceComponent
  },
  {
    path: 'notConnected',
    component: NotConnectedComponent
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [MaintenanceGuard],
    children: [
      {
        path: '',
        redirectTo: '/anie',
        pathMatch: 'full'
      },
      {
        path: 'home',
        component: HomeComponent,
        data: {
          layoutDisplay: LayoutDisplayMode.KEEP_NOTHING
        }
      },
      {
        path: 'sign-in',
        component: SignInComponent,
        data: { layoutDisplay: LayoutDisplayMode.KEEP_HEADER, layoutTitle: 'Connexion', layoutScrollTop: true }
      },
      {
        path: 'forgotten-password',
        component: ForgottenPasswordComponent,
        data: { layoutDisplay: LayoutDisplayMode.KEEP_HEADER, layoutTitle: 'Mot de passe oublié', layoutScrollTop: true }
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent,
        data: { layoutDisplay: LayoutDisplayMode.KEEP_HEADER, layoutTitle: 'Modifier mon mot de passe', layoutScrollTop: true }
      },
      {
        path: 'anie',
        loadChildren: () => import('app/anie/anie.module').then(m => m.AnieModule)
      },
      {
        path: 'user',
        loadChildren: () => import('app/user/user.module').then(m => m.UserModule)
      },
      {
        path: 'ssosg/:matricule',
        pathMatch: 'full',
        redirectTo: '/user/ssosg/:matricule'
      },
      {
        path: 'articles',
        loadChildren: () => import('app/articles/articles.module').then(m => m.ArticlesModule)
      },
      {
        path: 'offers',
        loadChildren: () => import('app/offers/offers.module').then(m => m.OffersModule)
      },
      {
        path: 'match',
        loadChildren: () => import('app/match/match.module').then(m => m.MatchModule),
        canActivate: [AuthGuard, ProfileCompleteGuard],
        data: { authorized: [UserType.CANDIDATE, UserType.RECRUITER], authorizedCandidateTypes: [CandidateType.APPRENTI, CandidateType.FORM_PRO] }
      },
      {
        path: 'notification',
        loadChildren: () => import('app/notifications/notifications.module').then(m => m.NotificationsModule),
        canActivate: [AuthGuard, ProfileCompleteGuard],
        data: { authorized: [UserType.CANDIDATE, UserType.RECRUITER], layoutTitle: 'Vos notifications' }
      },
      {
        path: '3e-internship',
        loadChildren: () => import('app/troisiemes/troisiemes.module').then(m => m.TroisiemesModule),
        canActivate: [AuthGuard, ProfileCompleteGuard],
        data: { authorized: [UserType.CANDIDATE], authorizedCandidateTypes: [CandidateType.TROISIEME] }
      },
      {
        path: 'feedback/:recruiterId/:candidateId',
        loadChildren: () => import('app/feedback/feedback.module').then(m => m.FeedbackModule),
        canActivate: [AuthGuard, ProfileCompleteGuard],
        data: { authorized: [UserType.CANDIDATE, UserType.RECRUITER] }
      },
      {
        path: 'survey/:recruiterId/:candidateId',
        loadChildren: () => import('app/survey/survey.module').then(m => m.SurveyModule)
      },
      {
        path: 'faq',
        component: FaqComponent,
        data: { layoutTitle: 'FAQ' }
      },
      {
        path: 'arml',
        component: ArmlComponent,
        data: { layoutTitle: "Association Régionale des Missions Locales d'Occitanie" }
      },
      {
        path: 'troisiemes',
        component: TroisiemeComponent,
        data: { layoutTitle: 'Accueillir un élève de 3e' }
      },
      {
        path: 'legalNotice',
        component: LegalNoticeComponent,
        data: { layoutTitle: 'Mentions légales' }
      },
      {
        path: 'detailsCGU',
        component: CguComponent,
        data: { layoutTitle: 'Conditions Générales d’Utilisation' }
      },
      {
        path: 'politicConfidentiality',
        component: PoliticConfidentialComponent,
        data: { layoutTitle: 'Comment sont traitées mes données personnelles' }
      },
      {
        path: 'advancedPoliticConfidentiality',
        component: AdvancedPoliticConfidentialComponent,
        data: { layoutTitle: 'Informations détaillées sur le traitement de mes données personnelles' }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, anchorScrolling: 'enabled' })],
  exports: [RouterModule]
})
export class RootRoutingModule {}
