import { Directive, ElementRef, Input, OnChanges, Renderer2 } from "@angular/core";
import { Subscription } from "rxjs";

@Directive({
  selector: '[anieBusyOverlay]'
})
export class BusyOverlayDirective implements OnChanges {

  @Input() anieBusyOverlay: Subscription;

  constructor(private elementRef: ElementRef,
              private renderer: Renderer2) { }

  ngOnChanges(): void {
    if (this.anieBusyOverlay && !this.anieBusyOverlay.closed) {
      this.renderer.addClass(this.elementRef.nativeElement, 'pending-overlay');
      this.anieBusyOverlay.add(() => this.renderer.removeClass(this.elementRef.nativeElement, 'pending-overlay'));
    }
  }
}
