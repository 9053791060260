import { Pipe, PipeTransform } from "@angular/core";
import { environment } from "../../../environments/environment";

@Pipe({
  name: 'avatarUrl',
  pure: true
})
export class AvatarUrlPipe implements PipeTransform {

  DEFAULT_AVATAR = '/assets/img/default-avatar.jpg';

  transform(value: string): string {
    if (/^data:/.test(value)) {
      // local file doesn't need to load file from the main domain
      return value;
    }
    return environment.domain + (value || this.DEFAULT_AVATAR);
  }

}

@Pipe({
  name: 'localUrl',
  pure: true
})
export class LocalUrlPipe implements PipeTransform {

  transform(value: string): string {
    if (!value || /^http/.test(value)) {
      return value;
    }
    return environment.domain + value;
  }

}
