import { Action } from "@ngrx/store";
import { AnieQuestion, AnieType } from "./anie.model";

export const ANIE_INIT = '[Anie] Init';
export const ANIE_ANSWER_QUESTION = '[Anie] Answer question';
export const ANIE_QUESTION_RECEIVED = '[Anie] Question received';
export const ANIE_RESET_TO = '[Anie] Reset to';
export const ANIE_RESET_CHATBOT = '[Anie] Reset the chatbot';
export const ANIE_ENDED = '[Anie] The End';
export const ANIE_LEAVE = '[Anie] User left';

export class AnieInitAction implements Action {
  type = ANIE_INIT;
  constructor(public idForm: AnieType, public contextId?: string) {}
}

export class AnieAnswerQuestionAction implements Action {
  type = ANIE_ANSWER_QUESTION;
  constructor(public id: number, public valueChoice: any) {}
}

export class AnieQuestionReceivedAction implements Action {
  type = ANIE_QUESTION_RECEIVED;
  constructor(public question: AnieQuestion) {}
}

export class AnieResetToAction implements Action {
  type = ANIE_RESET_TO;
  constructor(public question: AnieQuestion) {}
}

export class AnieResetChatbotAction implements Action {
  type = ANIE_RESET_CHATBOT;
  constructor() {}
}

export class AnieEndedAction implements Action {
  type = ANIE_ENDED;
  constructor() {}
}

export class AnieLeaveAction implements Action {
  type = ANIE_LEAVE;
  constructor() {}
}

export type AnieActionType =
  | AnieInitAction
  | AnieAnswerQuestionAction
  | AnieQuestionReceivedAction
  | AnieResetToAction
  | AnieEndedAction
  | AnieLeaveAction;
