import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { PopinAdminMaintenanceService } from "./popin-admin-maintenance.service";
import { filter, first, tap } from "rxjs/operators";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";

@Component({
  selector: 'anie-popin-admin-maintenance',
  templateUrl: './popin-admin-maintenance.component.html',
  styleUrls: ['./popin-admin-maintenance.component.scss'],
})
export class PopinAdminMaintenanceComponent implements OnInit {

  @ViewChild('maintenanceDialog', { static: true }) maintenanceDialog: TemplateRef<any>;

  listPopin$ = this.popinAdminMaintenanceService.displayedPopins$;

  constructor(
    private popinAdminMaintenanceService: PopinAdminMaintenanceService,
    private router: Router,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.popinAdminMaintenanceService.showMaintenance$.pipe(
      filter(b => b),
      first(),
      tap(() => this.router.navigate(['/maintenance'])),
    ).subscribe();

    this.listPopin$.subscribe((popins)=>{
      if(Array.isArray(popins) && popins.length){
        this.open();
      }
    });
  }

  open(){
    this.dialog.open(this.maintenanceDialog);
  }
}
