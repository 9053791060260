import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { publishLast, refCount } from "rxjs/operators";
import { environment } from "../../../../environments/environment";

@Injectable()
export class AvatarService {

  private cachedAvatarUrls$: Observable<string[]>;

  constructor(private http: HttpClient) { }

  getAvatarUrls(): Observable<string[]> {
    if (!this.cachedAvatarUrls$) {
      this.cachedAvatarUrls$ = this.http
        .get<string[]>(environment.apiEntryPoint + '/avatars')
        .pipe(publishLast(), refCount()); // cache the result
    }
    return this.cachedAvatarUrls$;
  }
}
