import { Injectable } from "@angular/core";
import { asyncScheduler, BehaviorSubject, Observable, of, throwError } from "rxjs";
import { CandidateType, User, UserType } from "./user";
import { HttpClient } from "@angular/common/http";
import { catchError, filter, map, observeOn, share, tap } from "rxjs/operators";
import { AuthService } from "../security/auth.service";
import { mergeDeep } from "../shared/utils/object-utils";
import { environment } from "../../environments/environment";
import { Platform } from "@ionic/angular";

@Injectable()
export class UserService {

  // undefined until someone check the user
  // null otherwise
  private currentUser$ = new BehaviorSubject<User>(undefined);
  private refreshConnected$ = this.http.get<User>(environment.apiEntryPoint + '/users/refreshConnected').pipe(
    catchError(() => of(null)),
    tap(user => this.currentUser$.next(user)),
    share(),
  );

  readonly isRecruiter$ = this.currentUser$.pipe(map(u => u && u.profile === UserType.RECRUITER));
  readonly isCandidate$ = this.currentUser$.pipe(map(u => u && u.profile === UserType.CANDIDATE));

  constructor(private http: HttpClient,
              private authService: AuthService,
              private platform: Platform) { }

  signIn$(email: string, password: string): Observable<User> {
    return this.http.post<User>(environment.apiEntryPoint + '/users/login', {email, password})
      .pipe(
        tap(user => {
          if (!!user && (user.profile === UserType.CANDIDATE || user.profile === UserType.RECRUITER)) {
            this.currentUser$.next(user);
          } else {
            this.currentUser$.next(null);
            throw new Error('access-admin');
          }
        })
      );
  }

  isEmailAvailable$(email: string): Observable<boolean> {
    return this.http.post(environment.apiEntryPoint + '/users/emailAvailable', {email}).pipe(
      map(() => true),
      catchError(err => {
        if (err.status !== 409) {
          console.error(err);
        }
        return of(false);
      })
    );
  }

  confirmUserEmail$(emailToken: string): Observable<User> {
    return this.http.post<User>(environment.apiEntryPoint + '/users/confirmEmail', { emailToken }).pipe(
      tap(user => this.currentUser$.next(user))
    );
  }

  confirmUserFromSkillgym$(token: string): Observable<User> {
    return this.http.get<User>(`${environment.apiEntryPoint}/users/ssosg/${token}`).pipe(
      tap(user => this.currentUser$.next(user))
    );
  }

  checkParentToken$(token: string): Observable<User> {
    return this.http.post<User>(environment.apiEntryPoint + '/users/checkParentToken', { token });
  }

  validateParentPermission$(token: string): Observable<any> {
    return this.http.post<User>(environment.apiEntryPoint + '/users/validateParentPermission', { token });
  }

  resetPassword$(newPassword: string, token: string): Observable<User> {
    return this.http.post<User>(environment.apiEntryPoint + '/users/resetPassword', { password: newPassword, token }).pipe(
      tap(user => this.currentUser$.next(user))
    );
  }

  deleteUser$(): Observable<void> {
    const userId = this.currentUser$.getValue().id;
    return this.http.delete<void>(`${environment.apiEntryPoint}/users/${userId}`).pipe(
      tap(() => this.authService.removeToken()),
      tap(() => this.currentUser$.next(null))
    );
  }

  toggleEnableUser$(toEnable: boolean) {
    const route = toEnable ? 'enable' : 'disable';
    return this.http.get<User>(`${environment.apiEntryPoint}/users/${route}`).pipe(
      tap(user => this.currentUser$.next(user))
    );
  }

  updateLocalUser(fieldToUpdate: User, erasePrevious = false): void {
    if (erasePrevious) {
      this.currentUser$.next(fieldToUpdate);
    } else {
      const oldUser = this.currentUser$.getValue();
      const newUser = mergeDeep({}, oldUser, fieldToUpdate);
      this.currentUser$.next(newUser);
    }
  }

  createCurrentUser$(): Observable<User> {
    return this.http.post(environment.apiEntryPoint + '/users', this.currentUser$.getValue())
      // todo add a flag to know that that user is just created
      .pipe(tap(user => this.currentUser$.next(user)));
  }

  /**
   * that method save the current user profile => the current user profil has already been updated
   * If the HTTP call has been rejected then client and server will hold different state
   * @returns {Observable<User>}
   * @deprecated
   */
  updateUserProfile$(): Observable<User> {
    const user = this.currentUser$.getValue();
    return this.http.put(`${environment.apiEntryPoint}/users/${user.id}`, user)
      .pipe(tap(u => this.currentUser$.next(u)));
  }

  /**
   * That method update the password of the user
   * @returns {Observable<User>}
   */
  updatePassword$(password: string): Observable<void> {
    const user = this.currentUser$.getValue();
    return this.http.post<void>(`${environment.apiEntryPoint}/users/updatePassword/${user.id}`, {password: password});
  }

  /**
   * try first to save the new user on the server ther , if it succeed, update the local user
   * @param {User} fieldToUpdate
   * @returns {Observable<User>}
   */
  safeUpdateUserProfile$(fieldToUpdate: User): Observable<User> {
    const oldUser = this.currentUser$.getValue();
    if (!fieldToUpdate) {
      console.error('User cannot be updated to undefined or null');
      return;
    } else if (!oldUser.id) {
      console.error('Can not update undefined user');
      return;
    }
    const newUser = <User>mergeDeep({}, oldUser, fieldToUpdate);
    return this.http.put(`${environment.apiEntryPoint}/users/${newUser.id}`, newUser)
      .pipe(tap(user => this.currentUser$.next(user)));
  }

  updateUserAvatar$(file: File): Observable<User> {
    const user = this.currentUser$.getValue();
    const formData: FormData = new FormData();
    formData.append('avatar', file, file.name);
    return this.http.post(`${environment.apiEntryPoint}/users/${user.id}/updateAvatar`, formData)
      .pipe(tap(newUser => this.currentUser$.next(newUser)));
  }

  updateUserAvatarNative$(b64Str: string): Observable<User> {
    const user = this.currentUser$.getValue();
    return this.http.post(`${environment.apiEntryPoint}/users/${user.id}/updateAvatarNative`, {avatar: b64Str})
      .pipe(tap(newUser => this.currentUser$.next(newUser)));
  }

  getUser$(): Observable<User> {
    // initializes user if still pristine (-> undefined)
    if (this.currentUser$.getValue() === undefined && this.authService.isTokenNotExpired()) {
      this.refreshConnected$.subscribe();
    } else if (this.currentUser$.getValue() === undefined) {
      this.currentUser$.next(null);
    }

    // other piece of code should nether receive a user as undefined (reserved value for the service)
    return this.currentUser$.asObservable().pipe(
      observeOn(asyncScheduler), // avoid ExpressionChangedAfterItHasBeenCheckedError (by default behavior subject are sync)
      filter(user => user !== undefined),
    );
  }

  getUserType$(): Observable<UserType> {
    return this.getUser$().pipe(
      map((u) => {
        return u && u.profile;
      })
    );
  }

  getCandidateType$(): Observable<CandidateType> {
    return this.getUser$().pipe(
      map((u) => {
        if(u && u.profile == UserType.CANDIDATE){
          return u && u.candidate.type;
        }
        else{
          return null;
        }
      })
    );
  }

  refreshConnectedUser$(): Observable<User> {
    return this.refreshConnected$;
  }

  sendForgottenEmail$(email: string): Observable<any> {
    return this.http.post(`${environment.apiEntryPoint}/users/forgottenEmail`, { email });
  }

  logout(): Observable<void> {
    return this.http.get<void>(`${environment.apiEntryPoint}/users/logout`).pipe(
      tap(() => this.authService.removeToken()),
      tap(() => this.currentUser$.next(null))
    );
  }

  isLoggedIn$(): Observable<boolean> {
    return this.getUser$().pipe(
      map(u => !!u && this.authService.isTokenNotExpired())
    );
  }

  getSkillgymMat$(): Observable<string> {
    return this.http.get<string>(environment.apiEntryPoint + '/users/skillgym');
  }

}
