import moment from "moment";
import { FormControl, ValidationErrors } from "@angular/forms";

/**
 * @param {string} d date ISOstring
 * @param {number} year
 * @returns {boolean}
 */
export function isYoungerThan(d: string, year: number): boolean {
  const mDate = moment(d);
  const yearsAgo = moment().subtract(year, 'years');
  return mDate.isAfter(yearsAgo);
}

/**
 * @param {string} d date format ISOstring
 * @param {number} year
 * @returns {boolean}
 */
export function isOlderThan(d: string, year: number): boolean {
  const mDate = moment(d);
  const yearsAgo = moment().subtract(year, 'years');
  return mDate.isBefore(yearsAgo);
}

export function extractLocalTimeFromISODate(d: string): string {
  if (!d) {
    return '';
  }
  return new Date(d).toLocaleTimeString();
}

export function extractLocalDateFromISODate(d: string): string {
  if (!d) {
    return '';
  }
  return new Date(d).toDateString();
}

/**
 * create an UTC Date from strings of input type date and time
 * @param {string} date `yyyy-MM-dd`
 * @param {string} time `HH:mm`
 */
export function parseDateAndTime(date: string, time: string): Date {
  const parsedDate = new Date(date);
  const splittedTime = time.split(':').map(str => +str);
  const msTime = (splittedTime[0] * 60 + splittedTime[1]) * 60000;
  return new Date(parsedDate.getTime() + msTime);
}

export const DATE_MASK_YEAR = [/\d/, /\d/, /\d/, /\d/];
export const TIME_MASK = [/\d/, /\d/, ':', /\d/, /\d/];
const regExpDate = /\d{2}\/\d{2}\/\d{4}/;
const regExpTime = /\d{2}:\d{2}/;

export function isDateValid(date: Date): boolean {
  return !isNaN(date.getTime());
}

/**
 * @param t {string} format hh:mm:ss
 * */
export function isTimeValid(t: string): boolean {
  const splitTime = t.split(':');
  const hour = parseInt(splitTime[0]);
  const min = parseInt(splitTime[1]);
  return !(hour >= 24 || hour < 0 || min >= 60 || min < 0);
}

export function dateValidator() {
  return (control: FormControl): ValidationErrors | null => {
    const strDate = control.value;
    let date = new Date(control.value);
    if (!strDate || Date.parse(date.toISOString()) == NaN) {
      return {
        date: {
          invalidFormat: true
        }
      };
    }
    return null;
  };
}

export function timeValidator() {
  return (control: FormControl): ValidationErrors | null => {
    const strTime = control.value;
    if (!strTime || !regExpTime.test(strTime) || !isTimeValid(strTime)) {
      return {
        time: {
          invalidFormat: true
        }
      };
    }
    return null;
  };
}

export function dateBetweenValidator({ from, to }: { from?: moment.Moment; to?: moment.Moment }) {
  return (control: FormControl): ValidationErrors | null => {
    const strDate = control.value;
    if (!strDate || Date.parse(new Date(strDate).toISOString()) == NaN) {
      return { date: { invalidFormat: true } };
    }
    // the format is valid check if it is in the range
    const date = moment(new Date(strDate));
    if (from && date.isBefore(from)) {
      return { date: { invalidRange: true } };
    }
    if (to && date.isAfter(to)) {
      return { date: { invalidRange: true } };
    }
    return null;
  };
}

export function nowBetween(start: Date, end?: Date): boolean {
  const nowBeforeStart = start && new Date().getTime() < start.getTime();
  const nowAfterEnd = end && new Date().getTime() > end.getTime();
  return !(nowBeforeStart || nowAfterEnd);
}

export function isFuture(date: Date): boolean {
  return new Date().getTime() < date.getTime();
}

/**
 * @param d {string} date format isoDate
 * Calcule différence d'âge avec entrée d et date actuelle
 * */
export function userAge(d: string): number {
  const today = moment();
  const date = moment(d);
  return today.diff(date, 'years');

}
