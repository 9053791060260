import { environment } from "../../../environments/environment";
import { Browser} from "@capacitor/core";

export async function goTo(link: string): Promise<void> {

  const strLink = /^http/.test(link) ? link : environment.domain + link;
  if (!environment.domain) {
    // no domain => web app
    window.location.href = strLink;
  } else {
    // domain => native app
    await Browser.open({ url: strLink, toolbarColor: '#c20012' });
  }
}
