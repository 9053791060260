import { Action, ActionReducer, MetaReducer } from "@ngrx/store";

import { environment } from "../../../environments/environment";
import { GlobalState } from "./global-reducer";

// log actions and state
export function logger(reducer: ActionReducer<GlobalState>): ActionReducer<GlobalState> {
  return function(state: GlobalState, action: Action): GlobalState {
    const newState = reducer(state, action);
    //console.log(`%c${action.type}`, 'color: #B34A00;', 'New State: ', newState);
    return newState;
  };
}

export const metaReducers: MetaReducer<GlobalState>[] = environment.production
  ? []
  : [logger];
