import { createEntityAdapter, EntityAdapter, EntityState } from "@ngrx/entity";
import { AnieQuestion } from "./anie.model";

export interface State extends EntityState<AnieQuestion> {
  ids: number[];
  // additional entity state properties
}

export const adapter: EntityAdapter<AnieQuestion> = createEntityAdapter<AnieQuestion>({
  selectId: (question: AnieQuestion) => question.id,
});

export const initialState: State = adapter.getInitialState({
  ids: [],
  // additional entity state properties
});

