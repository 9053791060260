import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable } from "rxjs";
import { mergeDeep } from "../shared/utils/object-utils";
import { Offer, OfferState, PeOffer, ShortOffer } from "./offer";
import { filter, map, tap } from "rxjs/operators";
import { Params, Router } from "@angular/router";
import { AnieType } from "../anie/store/anie.model";
import { environment } from "../../environments/environment";
import { PaginateHttpService, Paginator } from "../shared/utils/http/paginate-http.service";
import { CandidateType } from '../user/user';

export class WizardStepModel {
  segments: string[];
  step: WizardStep;
  title: string;
  isValid?: boolean;
  isCurrent?: boolean;
  isDisabled?: boolean;
}

export enum WizardStep {
  DEFINE_OFFER,
  DEFINE_CANDIDATE,
  PERFECT_CANDIDATE,
  PERFECT_CANDIDATE_PRO,
  SUMMARY
}

export const INITIAL_WIZARD_STEPS: WizardStepModel[] = [
  {
    segments: ['/offers', 'recruiter', 'edit-offer', null, 'create-offer'],
    step: WizardStep.DEFINE_OFFER,
    title: 'Votre offre',
    isCurrent: true
  },
  {
    segments: ['/offers', 'recruiter', 'edit-offer', null, 'perfect-candidate'],
    step: WizardStep.DEFINE_CANDIDATE,
    title: 'Le candidat recherché'
  },
  {
    segments: ['/anie', AnieType.PERFECT_CANDIDATE, null],
    step: WizardStep.PERFECT_CANDIDATE,
    title: 'Le candidat idéal',
    isDisabled: true // it's always disabled as it's done in a complete different module of the app
  },
  {
    segments: ['/offers', 'recruiter', 'edit-offer', null, 'summary'],
    step: WizardStep.SUMMARY,
    title: 'Votre offre'
  }
];

@Injectable()
export class OfferService {
  private currentOffer$ = new BehaviorSubject<Offer>(undefined);
  private wizard$ = new BehaviorSubject<WizardStepModel[]>(INITIAL_WIZARD_STEPS);

  constructor(private http: HttpClient, private router: Router, private paginateHttpService: PaginateHttpService) {
    // update wizard !!!!
    // manage URL segments with id in segments
    this.currentOffer$
      .pipe(
        filter(o => o !== undefined),
        tap(o => this.updateValidStateOnWizard(o)),
        map(o => o && o.id),
        tap(id => this.setIdOfferInSegments(id))
      )
      .subscribe();
  }

  private updateValidStateOnWizard(offer: Offer): void {
    const currentWizard = this.wizard$.getValue();
    const withModifiedSegments: WizardStepModel[] = currentWizard.map(step => {
      return { ...step, isValid: isStepValid(step.step, offer) };
    });
    this.wizard$.next(withModifiedSegments);
  }

  private setIdOfferInSegments(id: number): void {
    const currentWizard = this.wizard$.getValue();
    const withModifiedSegments: WizardStepModel[] = currentWizard.map((step: WizardStepModel, index: number) => {
      const newSegments = INITIAL_WIZARD_STEPS[index].segments.map(segment => (segment !== null ? segment : '' + id));
      return { ...step, segments: newSegments };
    });
    this.wizard$.next(withModifiedSegments);
  }

  getWizard$(): Observable<WizardStepModel[]> {
    return this.wizard$.asObservable();
  }

  /**
   * Si la recherche de l'offre concerne une formation pro, on affiche le chatbot correspondant (perfectCandidatePro)
   * Sinon on affiche le chatbot par défaut (perfectCandidate)
   */
  updateChatbotStepper(tyepOffer: CandidateType): void {
    if (tyepOffer === CandidateType.FORM_PRO) {
      INITIAL_WIZARD_STEPS[2].segments[1] = AnieType.PERFECT_CANDIDATE_PRO;
      INITIAL_WIZARD_STEPS[2].step = WizardStep.PERFECT_CANDIDATE_PRO;
    } else {
      INITIAL_WIZARD_STEPS[2].segments[1] = AnieType.PERFECT_CANDIDATE;
      INITIAL_WIZARD_STEPS[2].step = WizardStep.PERFECT_CANDIDATE;
    }
  }

  saveCurrentOffer$(fieldToUpdate: Offer): Observable<Offer> {
    const oldOffer = this.currentOffer$.getValue();
    return this.updateOffer$(oldOffer, fieldToUpdate).pipe(
      tap(newOffer => this.currentOffer$.next(newOffer)),
      tap(newOffer => {
        if (newOffer.state === OfferState.DRAFT) {
          this.goToNextUncompleted();
        } else {
          this.router.navigate(['/offers', 'recruiter']);
        }
      })
    );
  }

  /**
   * Upsert the offer (create it if no ID else update the corresponding)
   * doesn't update currentOffer
   * USE ONLY WHEN WORKING ON OFFERS LIST
   * @param {Offer} offer
   * @param {Offer} fieldToUpdate
   * @returns {Observable<Offer>}
   */
  updateOffer$(offer: Offer, fieldToUpdate: Offer): Observable<Offer> {
    const newOffer = mergeDeep({}, offer, fieldToUpdate);
    return this.saveOffer$(newOffer);
  }

  private saveOffer$(offer: Offer): Observable<Offer> {
    return this.http.post(environment.apiEntryPoint + '/offers', offer);
  }

  getOffer$(offerId: number): Observable<Offer> {
    return this.http.get(`${environment.apiEntryPoint}/offers/${offerId}`).pipe(tap(o => this.currentOffer$.next(o)));
  }

  startNewOffer(): void {
    this.currentOffer$.next(null);
  }

  refreshCurrentOffer(): Observable<Offer> {
    const currentOffer = this.currentOffer$.getValue();
    if (currentOffer && currentOffer.id) {
      return this.getOffer$(currentOffer.id);
    } else {
      console.error("couldn't refreshed not persisted offer");
    }
  }

  archiveOffer$(offer: Offer): Observable<Offer> {
    return this.http.delete(`${environment.apiEntryPoint}/offers/${offer.id}`);
  }

  /**
   * might return nothing the retrieval from server has to be made manually (see new-offer.component)
   * @returns {Observable<Offer>}
   */
  getCurrentOffer$(): Observable<Offer> {
    return this.currentOffer$.asObservable().pipe(filter(o => o !== undefined));
  }

  setCurrentOffer(offer: Offer) {
    this.currentOffer$.next(offer);
  }

  goToNextUncompleted(): void {
    const offer = this.currentOffer$.getValue();
    if (!offer || !offer.id) {
      this.goToCreation();
      return;
    }

    const canGoOnSummary = this.redirectIfForbidden(WizardStep.SUMMARY);
    if (canGoOnSummary) {
      this.goToWizardStep(WizardStep.SUMMARY);
    }
  }

  goToFirstUncompleted(): void {
    const offer = this.currentOffer$.getValue();
    if (!offer || !offer.id) {
      this.goToCreation();
    } else if (offer.attitudes && offer.attitudes.conclusion) {
      this.goToWizardStep(WizardStep.SUMMARY);
    } else if (!offer.has_degree) {
      this.goToWizardStep(WizardStep.DEFINE_CANDIDATE);
    } else if (offer.label) {
      this.goToWizardStep(WizardStep.DEFINE_OFFER);
    } else {
      console.error('should have gone somewhere !');
    }
  }

  duplicateOffer(offer: Offer): Observable<Offer> {
    const clonedOffer = mergeDeep<Offer>({}, offer);
    delete clonedOffer.id;
    delete clonedOffer.created;
    delete clonedOffer.modified;
    return this.saveOffer$(clonedOffer).pipe(
      tap(o => this.currentOffer$.next(o)),
      tap(() => this.goToFirstUncompleted())
    );
  }

  /**o
   * Redirect to the correct screen if not allowed
   * @param {WizardStep} step
   * @returns {boolean}
   */
  redirectIfForbidden(step: WizardStep): boolean {
    const currentOffer = this.currentOffer$.getValue();
    if (step >= WizardStep.DEFINE_OFFER && (!currentOffer || !currentOffer.id)) {
      this.goToCreation();
      return false;
    } else if (step >= WizardStep.DEFINE_CANDIDATE && !currentOffer.label) {
      this.goToWizardStep(WizardStep.DEFINE_OFFER);
      return false;
    } else if (step >= WizardStep.PERFECT_CANDIDATE && !currentOffer.has_degree) {
      this.goToWizardStep(WizardStep.DEFINE_CANDIDATE);
      return false;
    } else if (step >= WizardStep.SUMMARY && (!currentOffer.attitudes || !currentOffer.attitudes.conclusion)) {
      this.goToWizardStep(WizardStep.PERFECT_CANDIDATE);
      return false;
    }
    return true;
  }

  goToCreation(): void {
    this.router.navigate(['/offers', 'recruiter', 'new-offer', 'create-offer']);
  }

  goToWizardStep(step: WizardStep): void {
    const currentWizard = this.wizard$.getValue();
    const wizardStep = currentWizard.find(w => w.step === step);
    if (wizardStep && wizardStep.segments) {
      this.router.navigate(wizardStep.segments);
    } else {
      console.error('should found a step to redirect to');
    }
  }

  /**
   * change the current step displayed in the wizard, done in each constructor of each steps
   * (except for the chatbot which is special)
   * @param {WizardStep} step
   */
  setCurrentStep(step: WizardStep): void {
    const currentWizard = this.wizard$.getValue();
    const modifiedSteps = currentWizard.map(w => ({
      ...w,
      isCurrent: w.step === step
    }));
    this.wizard$.next(modifiedSteps);
  }

  findAll$(): Observable<Offer[]> {
    return this.http.get<Offer[]>(environment.apiEntryPoint + '/offers');
  }

  /**
   * Just list available offers for current user (retrieved objects are striped to the minimum)
   * @returns {Observable<Offer[]>}
   */
  listAll$(): Observable<ShortOffer[]> {
    return this.http.get<ShortOffer[]>(environment.apiEntryPoint + '/offers/enumerate');
  }

  getOffersCatalog(params?: Params): Paginator<Offer> {
    return this.paginateHttpService.paginatorFactory(environment.apiEntryPoint + '/offersCatalog', params);
  }

  getSinglePublicOffer(id: number): Observable<Offer> {
    return this.http.get<Offer>(`${environment.apiEntryPoint}/offersCatalog/${id}`);
  }

  getPeOffers$(): Observable<PeOffer[]> {
    return this.http.get<PeOffer[]>(`${environment.apiEntryPoint}/offers/poleEmploi`);
  }

  setPeOffer$(offer: PeOffer): Observable<PeOffer> {
    return this.http.post<PeOffer>(`${environment.apiEntryPoint}/offers/poleEmploi`, offer);
  }
}

function isStepValid(step: WizardStep, offer: Offer): boolean {
  if (step >= WizardStep.DEFINE_OFFER && (!offer || !offer.id)) {
    return false;
  } else if (step >= WizardStep.DEFINE_CANDIDATE && !offer.label) {
    return false;
  } else if (step >= WizardStep.PERFECT_CANDIDATE && !offer.has_degree) {
    return false;
  }  else if (step >= WizardStep.PERFECT_CANDIDATE_PRO && !offer.has_degree) {
    return false;
  } else if (step >= WizardStep.SUMMARY && (!offer.attitudes || !offer.attitudes.results)) {
    return false;
  }
  return true;
}
