import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AnieState } from "./anie.reducers";
import * as anieEntity from "./anie.entity";
import { AnieType } from "./anie.model";

export const getAnieState = createFeatureSelector<AnieState>('anie');

export const getPending = createSelector(getAnieState, state => state.pending);
export const getFirstAnieOfSession = createSelector(getAnieState, state => state.firstAnieOfSession);
export const getAnieType = createSelector(getAnieState, state => state.anieType);
export const getContextId = createSelector(getAnieState, state => state.contextId);
export const userNeedToBeUpdate = createSelector(getAnieState,
  ({anieType, hasAnswerToQuestion}) => (anieType === AnieType.COMPLETE_PROFILE || anieType === AnieType.EDIT_PROFILE) && hasAnswerToQuestion
);

export const  getAllQuestions = anieEntity.adapter.getSelectors(
  createSelector(getAnieState, state => state.questions)
).selectAll;

export const getQuestionById = (id: number) => {
  return createSelector(getAllQuestions, questions => questions.find(q => q.id === id));
};
export const getLastQuestion = createSelector(getAllQuestions,
  questions => (questions && questions.length) ? questions[questions.length - 1] : null);

/**
 * Get the last question choice (the one selected by the user),
 * if there is no questions or if the last is still not answered return null
 * @type {MemoizedSelector<object, any>}
 */
export const getLastQuestionChoice = createSelector(getAllQuestions, questions => {
  if (!questions || !questions.length) {
    return null;
  }
  const question = questions[questions.length - 1];
  if (!question.choices || !question.choices.length || !question.answer) {
    return null;
  }
  return question.choices.find(c => c.slug === question.answer);
});
