import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subscription } from "rxjs";
import { UserService } from "../../../../user/user.service";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: 'anie-forgotten-password',
  templateUrl: './forgotten-password.component.html',
  styleUrls: ['./forgotten-password.component.scss']
})
export class ForgottenPasswordComponent implements OnInit {
  forgottenForm: FormGroup;
  requestSent = false;
  requestSub: Subscription;

  constructor(private route: ActivatedRoute, private userService: UserService, private fb: FormBuilder, private snackBar: MatSnackBar) {}

  ngOnInit() {
    const queryParams = this.route.snapshot.queryParams;

    this.forgottenForm = this.fb.group({
      email: [queryParams.email || '', [Validators.required, Validators.email]]
    });
  }

  sendEmail(): void {
    if (this.forgottenForm.valid && (!this.requestSub || this.requestSub.closed)) {
      this.requestSub = this.userService.sendForgottenEmail$(this.forgottenForm.value.email).subscribe(
        () => (this.requestSent = true),
        () =>
          this.snackBar.open('Impossible de vous envoyer un email de changement de mot de passe, veuillez réessayer plus tard.', '', {
            duration: 2000,
            panelClass: ['snackbar', 'warning']
          })
      );
    }
  }
}
