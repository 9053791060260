import * as anieEntity from "./anie.entity";
import { AnieType } from "./anie.model";
import * as anieActions from "./anie.actions";

export interface AnieState {
  questions: anieEntity.State;
  anieType: AnieType;
  contextId: string;
  pending: boolean;
  hasAnswerToQuestion: boolean;
  firstAnieOfSession?: boolean;
}

const initialState: AnieState = {
  questions: anieEntity.initialState,
  anieType: null,
  contextId: null,
  hasAnswerToQuestion: false,
  pending: false,
};

export function anieReducer(state: AnieState = initialState, action: anieActions.AnieActionType): AnieState {
  switch (action.type) {
    case anieActions.ANIE_INIT: {
      const castedAction = <anieActions.AnieInitAction> action;
      const firstAnieOfSession = state.firstAnieOfSession === undefined;
      return {
        ...state,
        questions: anieEntity.adapter.removeAll(state.questions),
        anieType: castedAction.idForm,
        contextId: castedAction.contextId,
        pending: true,
        hasAnswerToQuestion: false,
        firstAnieOfSession,
      };
    }
    case anieActions.ANIE_ANSWER_QUESTION: {
      const castedAction = <anieActions.AnieAnswerQuestionAction> action;
      return {
        ...state,
        questions: anieEntity.adapter.updateOne({ id: castedAction.id, changes: { answer: castedAction.valueChoice }}, state.questions),
        hasAnswerToQuestion: true,
        pending: true,
      };
    }
    case anieActions.ANIE_QUESTION_RECEIVED: {
      const castedAction = <anieActions.AnieQuestionReceivedAction> action;
      return {
        ...state,
        questions: anieEntity.adapter.addOne(castedAction.question, state.questions),
        pending: false
      };
    }
    case anieActions.ANIE_RESET_TO: {
      const castedAction = <anieActions.AnieResetToAction> action;
      const indexToReset = state.questions.ids.indexOf(castedAction.question.id);
      return {
        ...state,
        questions: anieEntity.adapter.removeMany(state.questions.ids.slice(indexToReset), state.questions),
        pending: false
      };
    }
    case anieActions.ANIE_ENDED: {
      return {
        ...state,
        hasAnswerToQuestion: false, // effects will update the user it-self no need to reupdate it on leaving
        pending: false,
      };
    }

    default: {
      return state;
    }
  }
}
