import { Component, forwardRef, OnInit } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: 'anie-rate-control',
  templateUrl: './rate-control.component.html',
  styleUrls: ['./rate-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RateControlComponent),
      multi: true
    }
  ]
})
export class RateControlComponent implements OnInit, ControlValueAccessor {

  rate: number;
  valueList = [1, 2, 3, 4, 5]; // having an array simplifies GUI with ngFor

  // custom form control
  propagateChange = (_: any) => {};

  constructor() { }

  ngOnInit() {
  }

  setRate(val: number) {
    this.rate = val;
    this.propagateChange(val);
  }

  writeValue(val: number): void {
    this.rate = val;
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    // nothing to do unused touch property
  }



}
