import { FirstConnectionStep } from './profile-complete.guard';
import { DurationUnit } from '../shared/referentials/basic/duration-unit.pipe';
import { Referential } from '../shared/referentials/basic/referentials.service';
import { JobCategory } from '../shared/referentials/jobs-category/jobs-category.service';
import { Attitudes } from '../shared/models/attitude';
import { Permis } from '../offers/offer';
import { School } from '../troisiemes/school';

export enum UserType {
  CANDIDATE = 'CANDIDATE',
  RECRUITER = 'RECRUITER'
}

// TODO créer une const pour candidateType & ContratTypeLabel... Fait à l'arrache dû à mauvais référenciel
export enum CandidateType {
  APPRENTI = 'APPRENTI',
  FORM_PRO = 'FORM_PRO',
  TROISIEME = 'TROISIEME',
  ALL = 'ALL'
}

export enum ContratTypeLabel {
  APPRENTI = 'Apprenti',
  FORM_PRO = 'Stagiaire en formation professionnelle'
}

export const FormProType = [
  {
    slug: '0',
    label: 'Présentiel'
  },
  {
    slug: '1',
    label: 'A distance'
  }
];

export enum GenderType {
  MALE = 'm',
  FEMALE = 'f'
}

export class User {
  id?: number;

  profile?: UserType;

  email?: string;
  password?: string;
  infoOnTrainings?: boolean;
  newsOnTrainings?: boolean;
  rgpdData?: boolean; // Politique de confidentialité
  rgpdCondition?: boolean; // CGU

  firstname?: string;
  lastname?: string;
  gender?: GenderType;
  phone?: string;

  // by default it's falsy
  emailConfirmed?: boolean;
  notificationEmail?: boolean;
  notificationPush?: boolean;

  address?: string;
  postalCode?: string;
  city?: string;
  latitude?: number;
  longitude?: number;
  avatarUrl?: string;

  candidate?: Candidate;
  recruiter?: Recruiter;

  disabled?: boolean;
  moderate_by_admin?: boolean;

  params?: UserParams;
}

export enum WaitingSkillGymState {
  QUIZ = 'QUIZ',
  WAITING = 'WAITING',
  DONE = 'DONE'
}

export class Candidate {
  // in matches user is added to the candidate
  id?: number;
  user?: User;

  birthDate?: string; // ISOString
  handicap?: boolean;
  degrees?: Referential[];
  experiences?: Experience[];
  languages?: LanguageSpoken[];
  motivation?: string;
  visibility?: boolean;
  parentEmail?: string;
  parentFirstname?: string;
  parentLastname?: string;
  parentEmail2?: string;
  parentFirstname2?: string;
  parentLastname2?: string;
  parentValidatedInscription?: boolean;
  ageConstraintAccepted?: boolean;

  attitudes?: Attitudes;
  matchable?: boolean;
  missingFields?: string[];
  waitingSkillGym?: WaitingSkillGymState;

  type?: CandidateType;
  type_params?: any; // LOL du back; 0 ou 1 en string par ci, en number par là ...
  candidates_habilitations?: HabilitationCandidate[];
  candidates_certifications?: CertificationCandidate[];
  permis?: Permis[];
  vehicule?: boolean;

  // formulaire apprenti + form pro
  degreeLevel?: Referential;
  degreeCenter?: Referential;
  degreeType?: Referential;
  degreeName?: Referential;
  degreeProLocation?: Referential;
  jobCategoryDesired?: JobCategory;
  jobCategoryDesiredSlug?: Referential;
  jobDesired?: JobCategory;
  jobDesiredSlug?: Referential;
  durationDesired?: Referential;
  durationDesiredSlug?: string;
  companySizeDesired?: Referential;
  postalCodeDesired?: string;
  cityDesired?: string;
  companySizeDesiredSlug?: string;
  perimeterDesiredSlug?: string;
  date_dispo?: string;
  latitudeDesired?: number;
  longitudeDesired?: number;
  perimeterDesired?: Referential;

  // troisième
  schoolId?: number; //?
  school?: School;
  dispoStartTr?: string; // ISOstring
  dispoEndTr?: string; // ISOstring
  classroom?: string;
}

// Update MARS 2020 : Recruiter devient ENTREPRISE
export class Recruiter {
  id?: number;
  // in interviews user is added to the candidate
  user?: User;

  companyName?: string;

  description?: string;
  siren?: string;
  handicap?: boolean; // accueille un handicapé
  troisieme?: boolean; // accueille un troisieme
  webSite?: string;
  companySize?: Referential;
  yearOfCompanyCreation?: number;
  jobCategory?: JobCategory;
  jobSubcategories?: JobCategory[];

  authorizePhoneUsage?: boolean;

  nbNewPEOffers?: number; // N'existe pas dans le back...
}

export class UserParams {
  /**
   * Recruiter must accept rules in order to create job offer
   */
  hasAcceptedRules?: boolean;

  /**
   * store the last step validated by the user in order to make him start from it on next connection
   * shouldn't be used once first connection process is complete
   */
  firstConnectionStepDone?: FirstConnectionStep;
}

export class Experience {
  companyName: string;
  title?: string;
  duration: number; // varchar en base de donnée... 😐
  durationUnit: DurationUnit;
}

export class DegreeCandidate {
  slug?: string;
  obtention?: string; // isostring
  title?: string;
}

export class CertificationCandidate {
  title?: string;
  obtention?: string; // isostring
}

export class HabilitationCandidate {
  title?: string;
  obtention?: string; // isostring
}

export class LanguageSpoken {
  language: Referential;
  skill: Referential;
}
export const SIREN_MASK = { mask: [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/] };
