import { Component, OnInit } from "@angular/core";
import { combineLatest, Observable } from "rxjs";
import { FormBuilder, FormGroup } from "@angular/forms";
import { debounceTime, distinctUntilChanged, map, startWith } from "rxjs/operators";
import { accentFolding } from "../../shared/utils/string-utils";
import { Arml, ArmlService } from "./arml.service";

/*
*
* ARML = Association régionale des missions locales
*
* */

@Component({
  selector: 'anie-arml',
  templateUrl: './arml.component.html',
  styleUrls: ['./arml.component.scss']
})
export class ArmlComponent implements OnInit {

  arml$: Observable<Arml[]>;

  armlForm: FormGroup;

  constructor(
    private armlService: ArmlService,
    private fb: FormBuilder,
  ) { }

  ngOnInit() {
    this.armlForm = this.fb.group({ search: '' });
    const searchValue$ = this.armlForm.get('search').valueChanges.pipe(
      startWith(''),
      debounceTime(300),
      distinctUntilChanged(),
      map(val => accentFolding(val)),
    );

    const armlList$ = this.armlService.arml$.pipe(
      map(armlList => armlList.map(arml => {
        const cloned = {
          territory: arml.territory.map(str => accentFolding(str)),
          address: arml.address.map(str => accentFolding(str)),
          contact: arml.contact.map(str => accentFolding(str)),
          nbAntennas: accentFolding(arml.nbAntennas),
        };
        return {original: arml, folded: cloned};
      }))
    );

    this.arml$ = combineLatest(searchValue$, armlList$).pipe(
      map(([searchValue, armlList]) => {
        if (!searchValue || !armlList) {
          return armlList;
        }
        return armlList.filter(arml => {
          const matchTerritory = !!arml.folded.territory.find(t => t.includes(searchValue));
          const matchAddress = !!arml.folded.address.find(n => n.includes(searchValue));
          return matchTerritory || matchAddress;
        });
      }),
      map(armlList => armlList.map(dev => dev.original)),
    );
  }

}
